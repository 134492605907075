import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import './pageTitle.scss'
import { constants } from '../../store/constants'

const PageTitleComp = ({ authStore, title, breadcrumbs }) => (
  <div className="page-title">
    <div className="breadcrumbs">
      {breadcrumbs.map((bc, idx) => (
        <Link key={idx} to={`/${bc.url ? bc.url : bc.toLowerCase()}`}>
          {bc.label ? bc.label : bc}
        </Link>
      ))}
    </div>
    <div className="title">{title}</div>
    <div>Logged in as: {authStore.profile.email}</div>
  </div>
)

PageTitleComp.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        url: PropTypes.string,
        label: PropTypes.string
      })
    ])
  ),
  authStore: PropTypes.shape({
    profile: PropTypes.shape({ email: PropTypes.string.isRequired })
  }).isRequired
}

PageTitleComp.defaultProps = {
  breadcrumbs: [],
}

export default inject(constants.authStore)(observer(PageTitleComp))
