import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  faPlus, faEdit, faTrashAlt, faTimes, faCheck,
} from '@fortawesome/free-solid-svg-icons'
import { inject, observer } from 'mobx-react'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactTooltip from 'react-tooltip'
import { constants } from '../../../store/constants'
import Button from '../../../components/Button/Button'
import FloatingButton from '../../../components/FloatingButton/FloatingButton'
import Table from '../../../components/Table/Table'
import Modal from '../../../components/Modal/Modal'
import Form from '../../../components/Form/Form'
import FormInput from '../../../components/Form/FormInput'
import Checkbox from '../../../components/Form/Checkbox'
import PageTitle from '../../../components/PageTitle/PageTitle'
import FormDropdown from '../../../components/Form/FormDropdown'

const NEW_VOICE = {
  name: '',
  address: '',
  location: '',
  type: '',
  isActive: false,
}

const tableHeaders = [{
  label: '#',
  key: 'id',
  styles: {
    width: '50px',
    justifyContent: 'center',
  },
}, {
  label: 'Name',
  key: 'name',
  styles: {
    flex: 1,
  },
}, {
  label: 'Address',
  key: 'address',
  styles: {
    flex: 1,
  },
},
{
  label: 'Location',
  key: 'location',
  styles: {
    flex: 1,
  },
},
{
  label: 'Type',
  key: 'type',
  styles: {
    flex: 1,
  },
},
{
  label: 'Is Active',
  styles: {
    width: '19%',
    wordBreak: 'initial',
    justifyContent: 'center',
    textAlign: 'center',
  },
  render: (item) => <FontAwesomeIcon
    icon={item.isActive ? faCheck : faTimes}
    className={item.isActive ? 'icon-success' : 'icon-error'}
    />,
}]

const locationOptions = [
  { value: 'IRV', label: 'IRV' },
  { value: 'LV', label: 'LV' }]

const typeOptions = [
  { value: 'Legacy', label: 'Legacy' },
  { value: 'FreeSwitch', label: 'FreeSwitch' }]

const Voices = ({ voicesStore }) => {
  useEffect(() => {
    voicesStore.getVoices()

    return function cleanup() {
      voicesStore.clear()
    }
  }, [voicesStore])

  const defaultStateValues = {
    originalValue: {},
    selectedVoice: { ...NEW_VOICE },
    manageVoiceModalVisible: false,

    manageVoiceModalDisabled: false,
    removeVoiceModalVisible: false,
  }

  const [state, setState] = useState(defaultStateValues)

  const openEditVoiceModal = (voice) => {
    setState({
      ...state,
      originalValue: voice,
      selectedVoice: voice,
      manageVoiceModalVisible: true,
    })
  }

  const openCreateVoicesModal = () => {
    setState({
      ...state,
      originalValue: { ...NEW_VOICE },
      selectedVoice: { ...NEW_VOICE },
      manageVoiceModalVisible: true,
    })
  }

  const openRemoveVoiceModal = (voice) => {
    setState({
      ...state,
      selectedVoice: voice,
      removeVoiceModalVisible: true,
    })
  }

  const dismissManageVoiceModal = () => {
    if (!_.isEqual(state.originalValue, state.selectedVoice)) {
      // eslint-disable-next-line
      if (confirm('All unsaved changes will be discarded')) {
        setState({ ...state, manageVoiceModalVisible: false })
      }
    } else {
      setState({ ...state, manageVoiceModalVisible: false })
    }
  }

  const dismissRemoveVoiceModal = () => {
    setState({ ...state, removeVoiceModalVisible: false })
  }

  const convertNumberToBoolean = (value) => {
    if (typeof value === 'number') {
      return !!(value)
    }

    return value
  }

  const submitVoice = async () => {
    const configData = {
      ...state.selectedVoice,
    }
    if (configData.id) {
      const {
        id, name, address, isActive, location, type
      } = configData

      const isActiveValue = convertNumberToBoolean(isActive)
      await voicesStore.updateVoice({
        id, name, address, location, type, isActive: isActiveValue,
      })
    } else {
      await voicesStore.createVoice(configData)
    }

    const { limit, offset } = voicesStore.voicesPage
    await voicesStore.getVoices({ limit, offset })
    setState({ ...state, selectedVoice: { ...NEW_VOICE }, manageVoiceModalVisible: false })
  }

  const deleteVoices = async () => {
    await voicesStore.deleteVoice({ id: state.selectedVoice.id, address: state.selectedVoice.address })

    const { limit, offset } = voicesStore.voicesPage
    await voicesStore.getVoices({ limit, offset })
    setState({ ...state, removeVoiceModalVisible: false })
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    const voice = {
      ...state.selectedVoice,
      [name]: value,
    }
    setState({ ...state, selectedVoice: voice })
  }

  const handlePagination = (query) => {
    voicesStore.getVoices(query)
  }

  return (
    <>
      <PageTitle title="Voices" />
      <div className="page-content">
        <FloatingButton icon={faPlus} onClick={openCreateVoicesModal} dataTip="Add Voice" />
        <Table
          isLoading={voicesStore.inProgress}
          headers={tableHeaders}
          page={voicesStore.voicesPage}
          onPageChange={handlePagination}
        >
          <Button icon={faEdit} variant="primary" onClick={openEditVoiceModal} />
          <Button icon={faTrashAlt} variant="error" onClick={openRemoveVoiceModal} />
        </Table>
        <ReactTooltip />
      </div>

      <Modal
        show={state.manageVoiceModalVisible}
        header={state.selectedVoice.id ? 'Edit Voice' : 'Add Voice'}
        submitLabel={state.selectedVoice.id ? 'Save' : 'Create'}
        onSubmit={submitVoice}
        onDismiss={dismissManageVoiceModal}
        isLoading={voicesStore.inProgress}
        isDisabled={state.manageVoiceModalDisabled}
        width="60%"
      >
        <Form>
          <div className="two-column">
            <FormInput
              name="name"
              label="Name"
              value={state.selectedVoice.name}
              onChange={handleChange}
            />
            <FormInput
              name="address"
              label="Address"
              value={state.selectedVoice.address}
              onChange={handleChange}
            />
          </div>
          <div className="two-column">
            <FormDropdown
              name="location"
              label="Location"
              options={locationOptions}
              value={state.selectedVoice.location}
              onChange={handleChange}
            />
            <FormDropdown
              name="type"
              label="Type"
              options={typeOptions}
              value={state.selectedVoice.type}
              onChange={handleChange}
            />
          </div>
          <div className="two-column">
            <Checkbox
              name="isActive"
              label="Is Active"
              value={state.selectedVoice.isActive}
              onChange={handleChange}
            />
          </div>
        </Form>
      </Modal>

      <Modal
        show={state.removeVoiceModalVisible}
        onDismiss={dismissRemoveVoiceModal}
        onSubmit={deleteVoices}
        isLoading={voicesStore.inProgress}
        submitButtonVariant="error"
        submitLabel="Remove"
        header="Confirm removal"
        width="60%"
      >
        <div>
          Are you sure you want to remove the selected Voice?
        </div>
      </Modal>
    </>
  )
}

Voices.propTypes = {
  voicesStore: PropTypes.shape({
    voicesPage: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape()),
      limit: PropTypes.number,
      offset: PropTypes.number,
      total: PropTypes.number,
    }),
    inProgress: PropTypes.bool,
    getVoices: PropTypes.func,
    deleteVoice: PropTypes.func,
    createVoice: PropTypes.func,
    updateVoice: PropTypes.func,
    clear: PropTypes.func,
  }).isRequired,
}

export default inject(constants.voicesStore)(observer(Voices))
