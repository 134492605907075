import queryString from 'query-string'
import api from './api'

export async function getProjectTokens(query) {
  return api.get(`/api/v1/tokens?${queryString.stringify(query)}`)
}

export async function deleteToken(tokenId) {
  return api.delete(`/api/v1/tokens/${tokenId}`)
}

export async function createToken(tokenData) {
  return api.post('/api/v1/tokens', tokenData)
}

export async function updateToken(token) {
  const { id, ...tokenData } = token
  return api.patch(`/api/v1/tokens/${id}`, tokenData)
}
