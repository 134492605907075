import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons'
import './dropzone.scss'

/**
 * A file Dropzone component (using react-dropzone)
 * TODO: Need refactoring to complete the component. Currently usable.
 * @param {*} param0
 */
const DropZone = ({ onSelect }) => {
  const onDrop = useCallback((acceptedFile) => {
    onSelect(acceptedFile)
  }, [onSelect])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <div
      className="dropZone"
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <div className="dropBox">
        {isDragActive
          ? (
            <div className="dropActive">
              <p>Drop file here</p>
            </div>
          ) : (
            <div className="dropIcon">
              <FontAwesomeIcon className="uploadIcon" icon={faCloudUploadAlt} />
            </div>
          )}
      </div>
    </div>
  )
}

export default DropZone
