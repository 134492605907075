import React, { memo, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import Spinner from '../Spinner/Spinner'
import { PermissionContext } from '../../PermissionContext'
import './ExportCsvButton.scss'

const ExportCsvButtonComp = ({
    icon, label, isLoading, variant, classNames, disabled, requiredPermission, headers, data,
    fileTitle
}) => {
    const convertToCSV = (objArray) => {
        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray
        let str = ''

        for (let i = 0; i < array.length; i++) {
            let line = ''
            for (let index in array[i]) {
                if (line != '') line += ','
                line += array[i][index]
            }
            str += line + '\r\n'
        }
        return str
    }
    const exportToCsv = (event) => {
        if (data && data.length) {
            if (headers) {
                data.unshift(headers)
            }
            var jsonObject = JSON.stringify(data)
            var csv = convertToCSV(jsonObject)
            var exportedFilenmae = fileTitle + '.csv' || 'export.csv'

            var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
            if (navigator.msSaveBlob) { // IE 10+
                navigator.msSaveBlob(blob, exportedFilenmae)
            } else {
                var link = document.createElement("a")
                if (link.download !== undefined) { // feature detection
                    // Browsers that support HTML5 download attribute
                    var url = URL.createObjectURL(blob)
                    link.setAttribute("href", url)
                    link.setAttribute("download", exportedFilenmae)
                    link.style.visibility = 'hidden'
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                }
            }
        }
    }

    const buttonContent = icon
        ? (
            <>
                <FontAwesomeIcon icon={icon} />
                {' '}
                <span className="label">{label}</span>
            </>
        )
        : <span className="label">{label}</span>

    const cssClasses = ['btn', ...classNames]
    if (icon && label) {
        cssClasses.push('btn-with-icon')
        cssClasses.push(`btn-${variant}`)
    } else {
        cssClasses.push(`btn-${icon ? 'icon-' : ''}${variant}`)
    }

    const permissions = useContext(PermissionContext)
    return permissions.isAllowed(requiredPermission)
        ? (
            <button
                type="button"
                className={cssClasses.join(' ')}
                onClick={exportToCsv}
                disabled={disabled || isLoading}
            >
                {isLoading ? <Spinner /> : buttonContent}
            </button>
        )
        : null
}

ExportCsvButtonComp.propTypes = {
    icon: PropTypes.shape({}),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isLoading: PropTypes.bool,
    variant: PropTypes.oneOf(['default', 'white', 'primary', 'error']),
    classNames: PropTypes.arrayOf(PropTypes.string),
    disabled: PropTypes.bool,
    requiredPermission: PropTypes.string,
    headers: PropTypes.array,
    data: PropTypes.arrayOf(PropTypes.object)
}

ExportCsvButtonComp.defaultProps = {
    isLoading: false,
    variant: 'default',
    classNames: [],
    disabled: false,
    label: 'Export CSV',
    icon: null,
    requiredPermission: null,
    data: []
}

export default memo(ExportCsvButtonComp)
