import React, { memo, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import { PermissionContext } from '../../PermissionContext'
import './floatingButton.scss'

const FloatingButtonComp = ({
  icon, isLoading, disabled, onClick, customStyles, requiredPermission, dataTip,
}) => {
  const permissions = useContext(PermissionContext)

  return permissions.isAllowed(requiredPermission)
    ? (
      <button
        style={customStyles}
        type="button"
        className="floating-button"
        onClick={onClick}
        disabled={disabled || isLoading}
        data-tip={dataTip}
      >
        {icon ? <FontAwesomeIcon icon={icon} /> : ''}
      </button>
    ) : ''
}

FloatingButtonComp.propTypes = {
  icon: PropTypes.shape({}),
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  customStyles: PropTypes.shape({}),
  requiredPermission: PropTypes.string,
}

FloatingButtonComp.defaultProps = {
  isLoading: false,
  disabled: false,
  icon: null,
  customStyles: null,
  requiredPermission: null,
}

export default memo(FloatingButtonComp)
