import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import {
  faUserPlus, faEdit, faTrashAlt, faEnvelope, faRedoAlt,
} from '@fortawesome/free-solid-svg-icons'
import { inject, observer } from 'mobx-react'
import _ from 'lodash'
import { constants } from '../../../store/constants'
import Button from '../../../components/Button/Button'
import FloatingButton from '../../../components/FloatingButton/FloatingButton'
import Table from '../../../components/Table/Table'
import Modal from '../../../components/Modal/Modal'
import Form from '../../../components/Form/Form'
import Input from '../../../components/Form/FormInput'
import Dropdown from '../../../components/Form/FormDropdown'
import PageTitle from '../../../components/PageTitle/PageTitle'
import Checkbox from '../../../components/Form/Checkbox'
import FormGroup from '../../../components/Form/FormGroup'
import * as validators from '../../../validators'
import { PermissionContext } from '../../../PermissionContext'
import './users.scss'
import { DropdownWithSearch } from '../../../components/Form/DropdownWithSearch'

const NEW_USER = {
  firstName: '',
  lastName: '',
  email: '',
  divisionIds: [],
  roles: {},
  globalRoles: [],
}

const tableHeaders = [{
  label: '#',
  key: 'id',
  styles: {
    width: '50px',
    justifyContent: 'center',
  },
}, {
  label: 'First name',
  key: 'firstName',
  styles: {
    flex: 2,
  },
}, {
  label: 'Last name',
  key: 'lastName',
  styles: {
    flex: 2,
  },
}, {
  label: 'Email',
  key: 'email',
  styles: {
    flex: 3,
  },
}, {
  label: 'Role',
  key: 'role',
  styles: {
    flex: 3,
  },
}]

const Users = ({
  usersStore, companiesStore, divisionsStore, rolesStore, history,
}) => {
  const permissions = useContext(PermissionContext)

  useEffect(() => {
    if (!permissions.isAllowed('users.list')) {
      history.push('/home')
    }

    usersStore.getUsers()

    if (permissions.isAllowed('users.update')) {
      // by default the user-service backend has a page schema default limit of 20
      companiesStore.getCompanies({ limit: 50 })
      rolesStore.getScopedRoles()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const defaultStateValues = {
    search: '',
    originalValue: {},
    selectedUser: { ...NEW_USER },
    formErrors: {},
    manageUserModalVisible: false,
    removeUserModalVisible: false,
    selectedCompanyId: '',
    selectedCompanyRoles: [],
    deletedUserVisible: true,
  }

  const [state, setState] = useState(defaultStateValues)

  const getCompanyScopedRoles = (companyId) => {
    const actualCompany = companiesStore.companiesPage.data.find((company) => company.id === parseInt(companyId, 10))
    const scopedRoles = rolesStore?.scopedRoles?.filter((role) => actualCompany?.roleIds.includes(role.id))
    return scopedRoles || []
  }

  const validate = ({
    firstName, lastName, email, divisionIds, roles,
  }) => ({
    firstName: validators.validate({
      value: firstName,
      label: 'first name',
      validators: [validators.minLength(2)],
    }),
    lastName: validators.validate({
      value: lastName,
      label: 'last name',
      validators: [validators.minLength(2)],
    }),
    email: validators.validate({
      value: email,
      label: 'email address',
      validators: [validators.email],
    }),
    divisionIds: validators.validate({
      validators: [
        () => (divisionIds.length ? '' : 'User must belong to at least one division'),
      ],
    }),
    roles: validators.validate({
      validators: [
        () => {
          if (!divisionIds.length) {
            return ''
          }

          return divisionIds.every((id) => !!roles[id]) ? '' : 'Must assign a role to each selected division'
        },
      ],
    }),
  })

  const openEditUserModal = (user) => {
    divisionsStore.getDivisions({ companyId: user.companyId })
    const scopedRoles = getCompanyScopedRoles(user.companyId)
    const selectedUser = {
      ...user,
      roles: user.roles || {},
      globalRoles: user.globalRoles || [],
    }
    setState({
      ...state,
      originalValue: selectedUser,
      selectedUser,
      selectedCompanyId: user.companyId,
      selectedCompanyRoles: scopedRoles,
      manageUserModalVisible: true,
      formErrors: { init: ['Initialized'] },
    })
  }

  const openCreateUserModal = () => {
    setState({
      ...state,
      originalValue: { ...NEW_USER },
      selectedUser: { ...NEW_USER },
      manageUserModalVisible: true,
      selectedCompanyId: '-',
      selectedCompanyRoles: [],
    })
  }

  const openRemoveUserModal = (user) => {
    setState({
      ...state,
      selectedUser: user,
      removeUserModalVisible: true,
    })
  }

  const openReactivateUserModal = (user) => {
    setState({
      ...state,
      selectedUser: user,
      reactivateDeletedModalVisible: true,
    })
  }

  const dismissManageUserModal = () => {
    if (!_.isEqual(state.originalValue, state.selectedUser)) {
      // eslint-disable-next-line
      if (confirm('All unsaved changes will be discarded')) {
        setState({ ...state, manageUserModalVisible: false, formErrors: {} })
      }
    } else {
      setState({ ...state, manageUserModalVisible: false, formErrors: {} })
    }
  }

  const dismissRemoveUserModal = () => {
    setState({ ...state, removeUserModalVisible: false })
  }

  const dismissReactivateUserModal = () => {
    setState({ ...state, reactivateDeletedModalVisible: false })
  }

  const initiatePasswordReset = async (user) => {
    if (user.isActive) {
      await usersStore.forgotPassword(user.id, user.email)
    } else {
      await usersStore.resendUserActivation(user.id)
    }
  }

  const reactivateUser = async () => {
    await usersStore.reactivateDeletedUser(state.selectedUser)

    const { limit, offset } = usersStore.usersPage
    await usersStore.getUsers({ limit, offset })
    setState({ ...state, reactivateDeletedModalVisible: false })
  }

  const submitUser = async () => {
    const formErrors = validate(state.selectedUser)

    if (Object.values(formErrors).some((v) => v.length) || state.selectedCompanyId === '-') {
      setState({
        ...state,
        formErrors,
        selectedCompanyId: state.selectedCompanyId === '-' ? '' : state.selectedCompanyId,
      })
    } else {
      if (state.selectedUser.id) {
        const data = _.pick(state.selectedUser,
          ['id', 'lastName', 'firstName', 'email', 'divisionIds', 'roles', 'globalRoles'])
        await usersStore.updateUser(data)
      } else {
        await usersStore.createUser(state.selectedUser)
      }

      const { limit, offset } = usersStore.usersPage
      await usersStore.getUsers({ limit, offset })
      setState({ ...state, manageUserModalVisible: false })
    }
  }

  const deleteUser = async () => {
    await usersStore.deleteUser(state.selectedUser.id)

    const { limit, offset } = usersStore.usersPage
    await usersStore.getUsers({ limit, offset })
    setState({ ...state, removeUserModalVisible: false })
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    const user = {
      ...state.selectedUser,
      [name]: value,
    }

    const formErrors = validate(user)
    setState({ ...state, selectedUser: user, formErrors: { ...state.formErrors, [name]: formErrors[name] } })
  }

  const handleSearchChange = (event) => {
    setState({
      ...state,
      search: event.target.value,
    })
  }

  const submitSearch = () => {
    usersStore.getUsers({
      firstName: state.search,
      lastName: state.search,
      email: state.search,
    })
  }

  const resetSearch = () => {
    setState({ ...state, search: '' })
    usersStore.getUsers({})
  }

  const handleDivisionChange = (divisionId) => (ev) => {
    let divisionIds = _.cloneDeep(state.selectedUser.divisionIds)
    const roles = _.cloneDeep(state.selectedUser.roles)

    if (ev.target.value) {
      divisionIds.push(divisionId)
    } else {
      divisionIds = state.selectedUser.divisionIds.filter((d) => d !== divisionId)
      delete roles[divisionId]
    }

    const user = {
      ...state.selectedUser,
      divisionIds,
      roles,
    }

    const formErrors = validate(user)
    setState({
      ...state,
      selectedUser: user,
      formErrors: {
        divisionIds: formErrors.divisionIds,
      },
    })
  }

  const handleRolechange = (divisionId) => (ev) => {
    const roles = _.cloneDeep(state.selectedUser.roles)
    roles[divisionId] = parseInt(ev.target.value, 10) || undefined

    // remove undefined
    Object.keys(roles).forEach((key) => roles[key] === undefined && delete roles[key])
    const user = {
      ...state.selectedUser,
      roles,
    }

    setState({
      ...state,
      selectedUser: user,
      formErrors: validate(user),
    })
  }

  const handleGlobalRoleChange = (roleId) => (ev) => {
    let globalRoles = _.cloneDeep(state.selectedUser.globalRoles)

    if (ev.target.value) {
      globalRoles.push(roleId)
    } else {
      globalRoles = globalRoles.filter((d) => d !== roleId)
    }

    const user = {
      ...state.selectedUser,
      globalRoles,
    }

    setState({
      ...state,
      selectedUser: user,
      formErrors: validate(user),
    })
  }

  const handleCompanySelect = (companyId) => {
    let scopedRoles = []

    if (companyId !== '') {
      // Did not select company
      divisionsStore.getDivisions({ companyId })
      scopedRoles = getCompanyScopedRoles(companyId)
    }

    const selectedUser = {
      ...state.selectedUser,
      divisionIds: [],
      roles: {},
      globalRoles: [],
    }

    setState({
      ...state,
      selectedUser,
      selectedCompanyId: parseInt(companyId, 10),
      selectedCompanyRoles: scopedRoles,
    })
  }

  const onCompanySearch = (inputValue) => {
    if (inputValue) {
      companiesStore.getCompanies({ search: inputValue })
    }
  }

  const showDeletedUser = () => {
    setState({ ...state, deletedUserVisible: !state.deletedUserVisible })
  }

  const handlePagination = (query) => {
    usersStore.getUsers(query)
  }

  return (
    <>
      <PageTitle title="Users" />
      <div className="page-content">
        <FloatingButton requiredPermission="users.create" icon={faUserPlus} onClick={openCreateUserModal} />
        <Form>
          <div className="inline-form">
            <Input
              name="search"
              label=""
              value={state.search}
              onChange={handleSearchChange}
              ignoreErrors
            />
            <Button
              label="Search"
              variant="primary"
              onClick={submitSearch}
              disabled={state.search.length < 3}
            />
            <Button
              label="Clear"
              variant="primary"
              onClick={resetSearch}
              disabled={state.search.length < 3}
            />
            <Checkbox name="email" label="Deleted Users" value={state.deletedUserVisible} onChange={showDeletedUser} />
          </div>
        </Form>
        <Table
          isLoading={usersStore.inProgress}
          headers={tableHeaders}
          page={{
            ...usersStore.usersPage,
            data: usersStore.usersPage.data
              ? usersStore.usersPage.data.filter((user) => !(user.isDeleted) || state.deletedUserVisible) : null,
          }}
          onPageChange={handlePagination}
        >
          <Button
            requiredPermission="users.update"
            hidden={(self) => (self.isDeleted)}
            icon={faEnvelope}
            variant="primary"
            onClick={initiatePasswordReset}
          />
          <Button
            requiredPermission="users.update"
            hidden={(self) => (self.isDeleted)}
            icon={faEdit}
            variant="primary"
            onClick={openEditUserModal}
          />
          <Button
            requiredPermission="users.delete"
            hidden={(self) => (self.isDeleted)}
            icon={faTrashAlt}
            variant="error"
            onClick={openRemoveUserModal}
          />
          <Button
            hidden={(self) => (!self.isDeleted)}
            requiredPermission="users.update"
            icon={faRedoAlt}
            variant="primary"
            onClick={openReactivateUserModal}
          />
        </Table>
      </div>

      <Modal
        show={state.manageUserModalVisible}
        header={state.selectedUser.id ? 'Edit user' : 'Add user'}
        submitLabel={state.selectedUser.id ? 'Save' : 'Create'}
        onSubmit={submitUser}
        onDismiss={dismissManageUserModal}
        isLoading={usersStore.inProgress}
        // isDisabled={Object.keys(state.formErrors).some((k) => state.formErrors[k].length)}
        //  - Individual validation should happen on submit (except for email input)
        width="60%"
      >
        <Form>
          <div className="two-column">
            <Input
              name="firstName"
              label="First name"
              value={state.selectedUser.firstName}
              errors={state.formErrors.firstName}
              onChange={handleChange}
            />
            <Input
              name="lastName"
              label="Last name"
              value={state.selectedUser.lastName}
              errors={state.formErrors.lastName}
              onChange={handleChange}
            />

            <Input
              name="email"
              type="email"
              label="Email"
              value={state.selectedUser.email}
              errors={state.formErrors.email}
              onChange={handleChange}
              required
            />
          </div>

          <DropdownWithSearch
            options={companiesStore.companiesPage.data
              ? companiesStore.companiesPage.data.filter((company) => !company.deleted)
                .map((c) => ({ label: c.name, value: c.id }))
              : []}
            value={state.selectedCompanyId}
            onChange={handleCompanySelect}
            label="Company"
            onInputChange={onCompanySearch}
            errorMessage="Please select a company"
            required={true}
          />

          {(state.selectedCompanyId && state.selectedCompanyId !== '-' && divisionsStore.divisionsPage.data)
            ? (
              <>
                <FormGroup label="Divisions *">
                  {(state.formErrors.divisionIds && state.formErrors.divisionIds.length > 0 ? (
                    <div className="text-small text-error errors">
                      {state.formErrors.divisionIds[0]}
                    </div>
                  ) : null)}

                  {divisionsStore.divisionsPage.data.map((division, idx) => (
                    <div className="division-row" key={idx}>
                      <Checkbox
                        label={division.name}
                        name={division.name}
                        value={state.selectedUser.divisionIds.indexOf(division.id) > -1}
                        onChange={handleDivisionChange(division.id)}
                      />
                      <Dropdown
                        label=""
                        name=""
                        onChange={handleRolechange(division.id)}
                        value={state.selectedUser.roles[division.id] || 0}
                        placeholder="Select a role..."
                        disabled={state.selectedUser.divisionIds.indexOf(division.id) === -1}
                        options={state.selectedCompanyRoles.length
                          ? state.selectedCompanyRoles.map((r) => ({ label: r.name, value: r.id }))
                          : []}
                        required={state.selectedUser.divisionIds.indexOf(division.id) > -1}
                      />
                    </div>
                  ))}
                </FormGroup>
                {/* TODO: Evaluate handleGlobalRoleChange use */}
                {state.selectedCompanyId === 1
                  ? (
                    <div className="global-roles-wrapper">
                      <FormGroup label="Global Roles - Applies to all companies/division in the system.">
                        <div className="global-roles">
                          {rolesStore.scopedRoles.map((role) => (
                            <Checkbox
                              label={role.name}
                              key={role.id}
                              name={role.name}
                              value={state.selectedUser.globalRoles.indexOf(role.id) > -1}
                              onChange={handleGlobalRoleChange(role.id)}
                            />
                          ))}
                        </div>
                      </FormGroup>
                    </div>
                  )
                  : ''}
              </>
            )
            : ''}
        </Form>
      </Modal>
      <Modal
        show={state.removeUserModalVisible}
        onDismiss={dismissRemoveUserModal}
        onSubmit={deleteUser}
        isLoading={usersStore.inProgress}
        submitButtonVariant="error"
        submitLabel="Remove"
        header="Confirm removal"
        width="60%"
      >
        <div>
            Are you sure you want to remove the selected user?
        </div>
      </Modal>
      <Modal
        show={state.reactivateDeletedModalVisible}
        onDismiss={dismissReactivateUserModal}
        onSubmit={reactivateUser}
        isLoading={usersStore.inProgress}
        submitLabel="Reactivate"
        header="Confirm Reactivate User"
        width="60%"
      >
        <div>
          Are you sure you want to reactivate the deleted user?
        </div>
      </Modal>
    </>
  )
}

Users.propTypes = {
  companiesStore: PropTypes.shape({
    companiesPage: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape()),
    }),
    getCompanies: PropTypes.func,
  }).isRequired,
  divisionsStore: PropTypes.shape({
    getDivisions: PropTypes.func,
    divisionsPage: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape()),
    }),
  }).isRequired,
  usersStore: PropTypes.shape({
    usersPage: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape()),
      limit: PropTypes.number,
      offset: PropTypes.number,
      tota: PropTypes.number,
    }),
    inProgress: PropTypes.bool,
    getUsers: PropTypes.func,
    deleteUser: PropTypes.func,
    createUser: PropTypes.func,
    updateUser: PropTypes.func,
    resendUserActivation: PropTypes.func,
    forgotPassword: PropTypes.func,
    clear: PropTypes.func,
  }).isRequired,
  rolesStore: PropTypes.shape({
    roles: PropTypes.arrayOf(PropTypes.shape({})),
    scopedRoles: PropTypes.arrayOf(PropTypes.shape({})),
    getRoles: PropTypes.func,
    getScopedRoles: PropTypes.func,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
}

export default inject(
  constants.usersStore,
  constants.companiesStore,
  constants.divisionsStore,
  constants.rolesStore,
)(observer(Users))
