import queryString from 'query-string'
import api from './api'

export async function getVendors(query) {
  const { data: page } = await api.get(`/api/v1/vendors?${queryString.stringify(query)}`)

  page.data = page.data.map((vendor) => {
    const data = { ...vendor }
    data.configTemplate = JSON.parse(vendor.configTemplate)

    return data
  })

  return page
}

export async function deleteVendor(vendorId) {
  return api.delete(`/api/v1/vendors/${vendorId}`)
}

export async function createVendor(vendorsData) {
  return api.post('/api/v1/vendors', vendorsData)
}

export async function updateVendor(vendor) {
  const { id, ...vendorsData } = vendor
  return api.patch(`/api/v1/vendors/${id}`, vendorsData)
}
