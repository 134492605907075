/* eslint-disable no-console */
import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import {
  faTrashAlt,
  faEdit,
  faKey,
  faBroadcastTower,
  faEnvelope,
  faComment,
  faPhone,
  faHome,
  faFileAudio,
  faDollarSign,
  faBook,
  faBoxes,
  faLink,
  faMailBulk,
  faBoxOpen,
  faExternalLinkSquareAlt,
} from '@fortawesome/free-solid-svg-icons'
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import _ from 'lodash'
import MultiSelect from '@khanacademy/react-multi-select'
import ReactTooltip from 'react-tooltip'
import DateRange from '../../../components/DateRange/DateRange'
import { PermissionContext } from '../../../PermissionContext'
import PageTitle from '../../../components/PageTitle/PageTitle'
import Button from '../../../components/Button/Button'
import FloatingButton from '../../../components/FloatingButton/FloatingButton'
import FloatingButtonContainer from '../../../components/FloatingButton/FloatingButtonContainer'
import Table from '../../../components/Table/Table'
import { constants, PROTOCOL_MAPPING, VOICE_VENDOR } from '../../../store/constants'
import { replaceProjectVoices, findProjectVoiceById } from '../../../api/projectVoiceApi'
import Modal from '../../../components/Modal/Modal'
import Form from '../../../components/Form/Form'
import FormInput from '../../../components/Form/FormInput'
import FormDropdown from '../../../components/Form/FormDropdown'
import Checkbox from '../../../components/Form/Checkbox'
import './projectDetails.scss'
import JsonEditor, { jsonValidator } from '../../../components/Form/JsonEditor'
import TextArea from '../../../components/Form/TextArea'
import VoiceAssets from './VoiceAssets/VoiceAsset'
import { updateSchedule } from '../../../api/voicesApi'
import ExportCsvButton from '../../../components/ExportCsvButton/ExportCsvButton'
import UnsubscribeAssets from './UnsubscribeAssets/UnsubscribeAssets'
import WhiteLabelAssets from './WhiteLableAssets/WhiteLableAssets'

const NEW_TOKEN = {
  name: '',
  projectAppId: 0,
}

const NEW_PROJECT_APP = {
  applicationId: '',
}
const billingHeaders = [{
  label: 'Protocol',
  key: '_id',
  styles: {
    width: '50%',
    justifyContent: 'center',
  },
}, {
  label: 'Count',
  key: 'counter',
  styles: {
    width: '50%',
    justifyContent: 'center',
  },
}]
const csvHeaders = ['Protocol', 'Count']

const ProjectDetails = ({
  tokensStore, applicationsStore, vendorsStore, voicesStore, match, history,
  companiesStore, divisionsStore, projectsStore, notificationStore, voiceAssetsStore,
  billingsStore,
}) => {
  const permissions = useContext(PermissionContext)

  const [state, setState] = useState({
    originalValue: null,
    companyDisplayName: '',
    selectedApplication: { ...NEW_PROJECT_APP },
    removeApplicationModalVisible: false,
    addApplicationModalVisible: false,

    selectedMultiselect: [],
    voiceOptions: [],
    appVoiceModalVisible: false,
    projectVendor: [],

    selectedToken: { ...NEW_TOKEN },
    removeTokenModalVisible: false,
    manageTokenModalVisible: false,

    selectedVendor: {
      vendorId: -1,
      vendorName: '',
    },
    selectedVendorErrors: {},
    selectedVendorValidationErrors: {},
    selectedMessageType: '',
    selectedVendorTemplate: '',
    vendorConfigurationModalVisible: false,
    billingModalVisible: false,

    permissions: {},

    breadcrumbs: [
      'Companies', {
        label: 'Divisions',
        url: `companies/${match.params.companyId}/divisions`,
      },
      {
        label: 'Projects',
        url: `companies/${match.params.companyId}/divisions/${match.params.divisionId}/projects`,
      },
    ],

    unSubscribeModalVisible: false,
    unsubMessageError: [],
  })

  const billingModalBtns = (
    <>
      <ExportCsvButton
        fileTitle="Billing"
        data={billingsStore.billing}
        headers={csvHeaders}
        label="Export CSV"
      />
    </>
  )

  useEffect(() => {
    const init = async () => {
      const isTokensListingAllowed = permissions.isAllowed('tokens.list')
      const isProjectApplicationsListingAllowed = permissions.isAllowed('projectApplications.list')

      if (!isTokensListingAllowed && !isProjectApplicationsListingAllowed) {
        history.push('/home')
      }

      const fetchData = async () => {
        await projectsStore.getProjectApps({ projectId: match.params.projectId })
        const projectAppIds = _.map(projectsStore.projectApps, 'id')
        await tokensStore.getProjectTokens({ projectAppIds })
        await applicationsStore.getApplications({ limit: 100, deleted: false })

        const { name: companyName } = await companiesStore.getCurrentCompany(match.params.companyId)
        const { name: divisionName } = await divisionsStore.getCurrentDivision(match.params.divisionId)
        const { name: projectsName } = await projectsStore.getCurrentProject(match.params.projectId)
        const breadcrumbs = [{
          label: companyName,
          url: 'companies',
        }, {
          label: divisionName,
          url: `companies/${match.params.companyId}/divisions`,
        }, {
          label: projectsName,
          url: `companies/${match.params.companyId}/divisions/${match.params.divisionId}/projects`,
        }]

        setState({
          ...state,
          companyDisplayName: companyName,
          breadcrumbs,
        })
      }

      fetchData()
    }
    init()
    ReactTooltip.rebuild()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePagination = async (query) => {
    const projectAppIds = _.map(projectsStore.projectApps, 'id')
    await tokensStore.getProjectTokens({ projectAppIds, ...query })
  }

  const openBillingAppModal = (app) => {
    setState({
      ...state,
      selectedApplication: { ...state.selectedApplication, ...app },
      billingModalVisible: true,
    })
  }

  const dismissBillingAppModal = () => {
    billingsStore.clear()
    setState({
      ...state,
      billingModalVisible: false,
    })
  }

  const selectBillingDate = (dateRange) => {
    const { startDate, endDate } = dateRange[0]
    if (startDate && endDate) {
      billingsStore.getAppBillingCount({ startDate, endDate, paid: state.selectedApplication.id })
    }
  }

  /**
   * ADD NEW APPLICATION TO PROJECT
   */
  const openAddApplicationModal = () => {
    setState({
      ...state,
      addApplicationModalVisible: true,
      selectedApplication: { ...NEW_PROJECT_APP },
      originalValue: { ...NEW_PROJECT_APP },
    })
  }

  const dismissAddApplicationModal = () => {
    if (!_.isEqual(state.originalValue, state.selectedApplication)) {
      // eslint-disable-next-line
      if (confirm('All unsaved changes will be discarded')) {
        setState({ ...state, addApplicationModalVisible: false })
      }
    } else {
      setState({ ...state, addApplicationModalVisible: false })
    }
  }

  const handleApplicationChange = (event) => {
    const { name, value } = event.target
    const application = {
      ...state.selectedApplication,
      [name]: value,
    }
    setState({ ...state, selectedApplication: application })
  }

  const submitApplication = async () => {
    const data = {
      applicationId: state.selectedApplication.applicationId,
      projectId: match.params.projectId,
    }

    await projectsStore.createProjectApp(data)
    await projectsStore.getProjectApps({ projectId: match.params.projectId })

    setState({
      ...state,
      addApplicationModalVisible: false,
    })
  }

  /**
  * REMOVE APPLICATION FROM PROJECT
  */
  const openRemoveApplicationModal = (application) => {
    setState({
      ...state,
      removeApplicationModalVisible: true,
      selectedApplication: application,
    })
  }

  const dismissRemoveApplicationModal = () => {
    setState({
      ...state,
      removeApplicationModalVisible: false,
    })
  }

  const removeApplication = async () => {
    await projectsStore.deleteProjectApp(state.selectedApplication.id)

    await projectsStore.getProjectApps({ projectId: match.params.projectId })
    const projectAppIds = _.map(projectsStore.projectApps, 'id')

    await tokensStore.getProjectTokens({ projectAppIds })

    setState({
      ...state,
      removeApplicationModalVisible: false,
    })
  }

  /**
   * CREATE NEW TOKEN OR EDIT EXISTING ONE
   */
  const openCreateTokenModal = () => {
    setState({
      ...state,
      manageTokenModalVisible: true,
      selectedToken: { ...NEW_TOKEN },
    })
  }

  const openEditTokenModal = (token) => {
    setState({
      ...state,
      manageTokenModalVisible: true,
      selectedToken: token,
    })
  }

  const dismissManageTokenModal = () => {
    setState({
      ...state,
      manageTokenModalVisible: false,
    })
  }

  const handleTokenChange = (event) => {
    const { name, value } = event.target
    const token = {
      ...state.selectedToken,
      [name]: value,
    }
    setState({ ...state, selectedToken: token })
  }

  const submitToken = async () => {
    if (state.selectedToken.id) {
      await tokensStore.updateToken({ id: state.selectedToken.id, name: state.selectedToken.name })
    } else {
      await tokensStore.createToken(state.selectedToken)
    }

    const projectAppIds = _.map(projectsStore.projectApps, 'id')
    const pageInfo = _.pick(tokensStore.tokensPage, ['limit', 'offset', 'sort'])
    await tokensStore.getProjectTokens({ projectAppIds, ...pageInfo })

    dismissManageTokenModal()
  }

  /**
   * REMOVE EXISTING TOKEN
   */
  const openRemoveTokenModal = (token) => {
    setState({
      ...state,
      selectedToken: token,
      removeTokenModalVisible: true,
    })
  }

  const dismissRemoveTokenModal = () => {
    setState({
      ...state,
      removeTokenModalVisible: false,
    })
  }

  const removeToken = async () => {
    await tokensStore.deleteToken(state.selectedToken.id)

    const projectAppIds = _.map(projectsStore.projectApps, 'id')
    const pageInfo = _.pick(tokensStore.tokensPage, ['limit', 'offset', 'sort'])
    await tokensStore.getProjectTokens({ projectAppIds, ...pageInfo })

    dismissRemoveTokenModal()
  }

  /**
   * VENDOR CONFIGURATION
   */
  const openVendorConfigurationModal = async (messageType, item) => {
    await vendorsStore.getVendors({ limit: 100, messageType })
    // console.log(toJS(item))
    let vendorConfigTemplate = []
    let vendorConfig = { vendorId: '', vendorConfig: {}, logoImage: '' }

    const existingVendorConfigForMessageType = _.find(item.vendorConfigs, { messageType })
    if (existingVendorConfigForMessageType) {
      vendorConfig = { ...existingVendorConfigForMessageType }

      const vendor = _.find(
        vendorsStore.vendorsPage.data,
        { id: parseInt(existingVendorConfigForMessageType.vendorId, 10) },
      )
      vendorConfigTemplate = vendor.configTemplate
    }

    setState({
      ...state,
      selectedApplication: { ...state.selectedApplication, ...item },
      selectedVendor: vendorConfig,
      originalValue: vendorConfig,
      selectedVendorTemplate: vendorConfigTemplate,
      selectedMessageType: messageType,
      selectedVendorErrors: {},
      vendorConfigurationModalVisible: true,
    })
  }

  const dismissVendorConfigurationModal = () => {
    // Only close modal iff (has been changed) AND (Not Confirm unsaved changes)
    // Applied DeMorgan law on below if statement
    // eslint-disable-next-line
    if (_.isEqual(state.originalValue, state.selectedVendor) || confirm('All unsaved changes will be discarded')) {
      setState({ ...state, vendorConfigurationModalVisible: false, selectedVendorTemplate: [] })
    }
  }

  const saveVendorConfiguration = async () => {
    const {
      vendorId, vendorConfig, projectVendorId, logoImage = '',
    } = state.selectedVendor
    const data = {
      vendorId,
      logoImage,
      vendorConfig,
      projectAppId: state.selectedApplication.id,
    }

    // validate the vendor before saving
    const errors = state.selectedVendorTemplate.reduce((error, template) => {
      if (template.schema && vendorConfig[template.key]) {
        jsonValidator.validate(template.schema, vendorConfig[template.key])
        if (jsonValidator.errors) {
          return { ...error, [template.key]: jsonValidator.errors.map((err) => err.message) }
        }
        return error
      }
      return error
    }, {})

    if (Object.keys(errors).length) {
      setState({
        ...state,
        selectedVendorErrors: errors,
      })
      notificationStore.error({ message: 'Invalid application vendor configuration, please correct it before saving.' })
      return
    }

    if (projectVendorId) {
      await projectsStore.updateProjectAppVendorConfig(projectVendorId, data)
    } else {
      await projectsStore.createProjectAppVendorConfig(data)
    }

    await projectsStore.getProjectApps({ projectId: match.params.projectId })

    setState({
      ...state,
      vendorConfigurationModalVisible: false,
    })
  }

  const vendorConfigDefaultValue = (template) => {
    if ('defaultValue' in template) {
      return template.defaultValue
    }

    switch (template.type) {
      case 'array': return []
      case 'boolean': return false
      case 'json': return {}
      default: return ''
    }
  }

  const vendorChange = async (event) => {
    const { value: vendorId } = event.target
    if (!vendorId) {
      return
    }

    const vendor = _.find(vendorsStore.vendorsPage.data, { id: parseInt(vendorId, 10) })
    const { vendorConfigs } = _.find(projectsStore.projectApps, { id: state.selectedApplication.id })

    let projectVendor = {}
    // eslint-disable-next-line no-restricted-syntax
    for await (const item of vendorConfigs) {
      if (item.vendorId == vendorId) {
        projectVendor = { ...item }
      }
    }

    // const vendorConfig = vendor.configTemplate.reduce((result, item) => {
    //   // eslint-disable-next-line no-param-reassign
    //   result[item.key] = vendorConfigDefaultValue(item)
    //   return result
    // }, {})

    let voiceOptions = []
    if (voicesStore.voicesPage.data) {
      const allVoiceOptions = voicesStore.voicesPage.data
        .map((voice) => ({ label: voice.name, value: voice.id, type: voice.type }))

      if (vendor.name === VOICE_VENDOR.FreeSwitch) {
        voiceOptions = allVoiceOptions.filter((voice) => voice.type == VOICE_VENDOR.FreeSwitch)
      } else {
        voiceOptions = allVoiceOptions.filter((voice) => voice.type !== VOICE_VENDOR.FreeSwitch)
      }
    }

    setState({
      ...state,
      selectedVendorTemplate: vendor.configTemplate,
      selectedVendor: {
        ...state.selectedVendor,
        vendorId,
        vendorName: vendor.name,
        vendorConfig: {
          ...state.selectedVendor.vendorConfig,
          ...projectVendor.vendorConfig,
        },
      },
      voiceOptions,
    })
  }

  const vendorConfigInputComp = (template) => {
    switch (template.type) {
      case 'boolean': return Checkbox
      case 'json': return JsonEditor
      case 'separator': return ({ label }) => <div className="config-separator">{label}</div>
      case 'textarea': return TextArea
      default: return FormInput
    }
  }

  const vendorConfigLabel = (template) => {
    switch (template.type) {
      case 'array': return `${template.label} (comma separated list)`
      default: return template.label
    }
  }

  const vendorConfigClass = (template) => {
    if (template.fullWidth) {
      return 'full-width'
    }
    return ''
  }

  const vendorConfigValueToDisplay = (template, value) => {
    switch (template.type) {
      case 'array':
        // eslint-disable-next-line no-case-declarations
        const returnS = (value || []).join(', ').trim()
        return returnS
      default: return value
    }
  }

  const vendorConfigDisplayToValue = (template, value) => {
    switch (template.type) {
      // filter out empty array items if they are not the last one
      // last items should never be filtered out to let the user add new items to the array
      case 'array': return value
        .split(',')
        .map((item) => item.trim())
        .filter((item, idx, array) => item || idx === array.length - 1)
      default: return value
    }
  }

  const onVendorConfigChange = (ev, template) => {
    // console.log(ev) // Gives the modified schema
    // console.log(template.key) // recipientHeaders
    const name = template.key
    const value = template.type === 'json' ? ev : ev.target.value
    const realValue = vendorConfigDisplayToValue(template, value)

    const updatedVendorConfig = {
      ...state.selectedVendor.vendorConfig,
      [name]: realValue,
    }

    // uri Validation
    let updatedVendorValidationErrors = { ...state.selectedVendorValidationErrors }

    if (template.schema) {
      // Question on validating? when failed, it behave like a bug instead of showing error
      // jsonValidator.validate(template.schema, typeof realValue === 'string' ? realValue.trim() : realValue)
      updatedVendorValidationErrors = jsonValidator.errors ? {
        ...updatedVendorValidationErrors,
        [`${name}Error`]: jsonValidator.errors.map((err) => (err.message)),
      } : _.omit(updatedVendorValidationErrors, `${name}Error`)

      // console.log(updatedVendorValidationErrors)
      // {recipientHeadersError: ['should be equal to constant']}
    }

    setState({
      ...state,
      selectedVendor: {
        ...state.selectedVendor,
        vendorConfig: updatedVendorConfig,
      },
      selectedVendorErrors: {},
      selectedVendorValidationErrors: updatedVendorValidationErrors,
    })
  }

  const handleInputChange = (event) => {
    const { value, name } = event.target

    let unsubMessageError = []
    if (name === 'unsub_message' && (value === '' || value === undefined)) {
      unsubMessageError = ['This is Required']
    }

    setState({
      ...state,
      unsubMessageError,
      selectedVendor: {
        ...state.selectedVendor,
        vendorConfig: {
          ...state.selectedVendor.vendorConfig,
          [name]: value,
        },
      },
    })
  }

  const handleInputVoiceAsset = (event) => {
    const { name, value } = event.target
    const { batchId, dse } = state.selectedVendor.vendorConfig
    let batchIdError = []
    if (name === 'batchId' && (value === '' || value === undefined)) {
      batchIdError = ['Required']
    }

    // This if-statement derived from an if-statement using de-morgan law.
    // This if-statement is more readable if apply de-morgan law again.
    /* eslint-disable no-restricted-globals, no-alert */
    if ((name !== 'dse' && name !== 'batchId')
      || (voiceAssetsStore.voiceAssets[`${batchId}-${dse}`] || []).length < 1
      || confirm('All non-TRANSFERRED files will be discarded')) {
      setState({
        ...state,
        selectedVendor: {
          ...state.selectedVendor,
          vendorConfig: {
            ...state.selectedVendor.vendorConfig,
            [name]: value,
            batchIdError,
          },
        },
      })
    }
    /* eslint-enable no-restricted-globals, no-alert */
  }

  const handleCheckboxChange = (value) => {
    state.selectedVendor.vendorConfig[value.target.name] = !state.selectedVendor.vendorConfig[value.target.name]

    setState({
      ...state,
    })
  }

  /**
   * UNSUBSCRIBE CONFIGURATION
   */

  const openUnsubscribeConfigurationModal = async (messageType, item) => {
    await vendorsStore.getVendors({ limit: 100, messageType })

    let vendorConfigTemplate = []
    let vendorConfig = {
      vendorId: '',
      logoImage: '',
      vendorConfig: {
        title: '',
        unsub_message: '',
        css: '',
        companyDisplayName: state.companyDisplayName,
      },
    }

    const existingVendorConfigForMessageType = _.find(item.vendorConfigs, { messageType })
    if (existingVendorConfigForMessageType) {
      const vendor = _.find(vendorsStore.vendorsPage.data,
        { id: parseInt(existingVendorConfigForMessageType.vendorId, 10) }) // Getting vendor specific template
      vendorConfigTemplate = vendor.configTemplate

      const existingVendorConfig = JSON.parse(JSON.stringify(existingVendorConfigForMessageType))
      vendorConfig = {
        ...existingVendorConfig,
        vendorConfig: { ...existingVendorConfig.vendorConfig, companyDisplayName: state.companyDisplayName }
      }
    }

    setState({
      ...state,
      selectedApplication: { ...state.selectedApplication, ...item },
      selectedVendor: vendorConfig,
      originalValue: vendorConfig,
      selectedVendorTemplate: vendorConfigTemplate,
      selectedMessageType: messageType,
      unSubscribeModalVisible: true,
    })
  }

  const dismissUnsubscribeConfigurationModal = () => {
    // eslint-disable-next-line
    if (_.isEqual(state.originalValue, state.selectedVendor) || confirm('All unsaved changes will be discarded')) {
      setState({ ...state, unSubscribeModalVisible: false, selectedVendorTemplate: [] })
    }
  }

  const saveUnsubscribeConfigurationModal = async () => {
    const {
      projectVendorId, vendorName, vendorId, logoImage, messageType, ...vendorConfig
    } = state.selectedVendor

    if (state.unsubMessageError.length) {
      notificationStore.error({ message: 'Successfull Unscubscribe Message cannot be empty!' })
    } else {
      const data = {
        vendorId,
        logoImage,
        ...vendorConfig,
        projectAppId: state.selectedApplication.id,
      }

      if (projectVendorId) {
        await projectsStore.updateProjectAppVendorConfig(projectVendorId, data)
      } else {
        await projectsStore.createProjectAppVendorConfig(data)
      }

      await projectsStore.getProjectApps({ projectId: match.params.projectId })

      setState({
        ...state,
        unsubMessageError: [],
        unSubscribeModalVisible: false,
      })
    }
  }

  /**
   * VOICE CONFIGURATION
   */
  const openVoiceConfigurationModal = async (messageType, item) => {
    await vendorsStore.getVendors({ limit: 100, messageType })
    await voicesStore.getVoices()
    const serversForPaid = await findProjectVoiceById(item.id)

    let selectedVendor = {
      vendorConfig: {
        batchId: null,
        batchIdError: ['Required'],
        dse: '',
        callbackUrl: '',
        di: '',
        keepAliveBatchId: false,
        sunday: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        startHour: '9',
        endHour: '16',
        isLoadingBatchId: false,
      },
    }
    const existingVendorConfigForMessageType = _.find(item.vendorConfigs, { messageType })
    let voiceOptions = []
    if (existingVendorConfigForMessageType) {
      const vendor = vendorsStore.vendorsPage.data.find((v) => v.id === existingVendorConfigForMessageType.vendorId)
      if (vendor.name === VOICE_VENDOR.FreeSwitch) {
        voiceOptions = voicesStore.voicesPage.data
          .filter((voice) => voice.type === VOICE_VENDOR.FreeSwitch)
          .map((voice) => ({ label: voice.name, value: voice.id, type: voice.type }))

        selectedVendor = {
          vendorConfig: {
            callbackUrl: '',
            campaignId: '',
          },
        }
      } else {
        voiceOptions = voicesStore.voicesPage.data
          .filter((voice) => voice.type !== VOICE_VENDOR.FreeSwitch)
          .map((voice) => ({ label: voice.name, value: voice.id, type: voice.type }))
      }

      const existingVendorConfig = JSON.parse(JSON.stringify(existingVendorConfigForMessageType))
      selectedVendor = {
        ...selectedVendor,
        ...existingVendorConfig,
        vendorConfig: { ...selectedVendor.vendorConfig, ...existingVendorConfig.vendorConfig },
        vendorId: vendor.id,
        vendorName: vendor.name,
      }
    }
    const selectedVoices = serversForPaid.data
      .filter((server) => server.projectAppId === item.id
        && voiceOptions.findIndex((v) => v.value == server.voiceServersId) > -1)
      .map((server) => server.voiceServersId)

    setState({
      ...state,
      selectedApplication: { ...state.selectedApplication, ...item },
      selectedVendor,
      originalValue: selectedVendor,
      voiceOptions,
      selectedMessageType: messageType,
      selectedMultiselect: selectedVoices,
      appVoiceModalVisible: true,
    })
  }

  const dismissVoiceConfigurationModal = () => {
    // eslint-disable-next-line
    if (_.isEqual(state.originalValue, state.selectedVendor) || confirm('All unsaved changes will be discarded')) {
      setState({ ...state, appVoiceModalVisible: false })
    }
  }

  const handleMultiselectChange = (changes) => {
    setState({
      ...state,
      selectedMultiselect: changes.selected,
    })
  }

  const formatBatchId = (bId) => {
    if (!bId) {
      throw new Error('bId cannot be Null or undefiend')
    }
    if (typeof bId === 'number') {
      return bId.toString()
    }

    return bId
  }

  const createScheduleOptionPayload = (config, voiceIps) => {
    const payload = {
      batchId: formatBatchId(config.batchId),
      scheduleOptions: {
        schedule: {
          sunday: config.sunday,
          monday: config.monday,
          tuesday: config.tuesday,
          wednesday: config.wednesday,
          thursday: config.thursday,
          friday: config.friday,
          saturday: config.saturday,
        },
        startHour: config.startHour ? parseInt(config.startHour, 10) : 9,
        endHour: config.endHour ? parseInt(config.endHour, 10) : 16,
      },
      dialers: voiceIps,
      companyId: match.params.companyId,
      description: 'Centralized Services UI',
    }
    return payload
  }

  const saveVoiceConfigurationModal = async () => {
    const bodyToSend = state.selectedMultiselect.map((voiceServerId) => ({
      voiceServersId: voiceServerId,
      projectAppId: state.selectedApplication.id,
    }))

    const voiceIps = state.selectedMultiselect.map((voiceServerId) => {
      const voice = state.voiceOptions.find((v) => (v.value === voiceServerId))
      return voice.label
    })

    if (state.selectedVendor.vendorName === VOICE_VENDOR.FreeSwitch) {
      const { campaignId } = state.selectedVendor.vendorConfig
      const { companyId } = match.params
      await voiceAssetsStore.uploadFreeSwitchAssets({ query: { campaignId, companyId } })
    } else {
      const origBatchId = state.originalValue.vendorConfig.batchId !== ''
        && state.originalValue.vendorConfig.batchId !== null
        ? parseInt(state.originalValue.vendorConfig.batchId, 10) : -1
      const currBatchId = state.selectedVendor.vendorConfig.batchId !== ''
        && state.selectedVendor.vendorConfig.batchId !== null
        ? parseInt(state.selectedVendor.vendorConfig.batchId, 10) : -1

      /* eslint-disable no-restricted-globals, no-alert */
      if (currBatchId < 0) {
        return
      }
      // De-morgan law to simplified the boolean expression
      if (!isNaN(currBatchId)
        && origBatchId
        !== currBatchId
        && (origBatchId === currBatchId
          || !confirm(`
        BatchId has been changed from '${origBatchId < 0 ? ''
              : origBatchId}' to '${currBatchId < 0 ? '' : currBatchId}'. Are you sure you want to proceed?`))) {
        setState({
          ...state,
          appVoiceModalVisible: true,
        })

        return
      }
      /* eslint-enable no-restricted-globals, no-alert */
    }
    try {
      await replaceProjectVoices({
        projectVoicesData: bodyToSend,
        id: state.selectedApplication.id,
      })
    } catch (error) {
      console.error(error)
    }

    const {
      projectVendorId, vendorName, vendorId, ...vendorConfig
    } = state.selectedVendor
    const data = {
      vendorId,
      projectAppId: state.selectedApplication.id,
    }

    if (vendorName !== VOICE_VENDOR.FreeSwitch) {
      const voiceServicePayload = createScheduleOptionPayload(vendorConfig.vendorConfig, voiceIps)

      try {
        await updateSchedule({ ...voiceServicePayload, batchOptions: {} })
      } catch (error) {
        console.error(error)
      }
      // Upload/process voice files that got uploaded
      const { batchId, dse } = state.selectedVendor.vendorConfig
      await voiceAssetsStore.uploadAssets({ query: { batchId, dse } })

      delete vendorConfig.vendorConfig.campaignId
      data.vendorConfig = vendorConfig.vendorConfig
    } else {
      data.vendorConfig = {
        callbackUrl: vendorConfig.vendorConfig.callbackUrl,
        campaignId: vendorConfig.vendorConfig.campaignId,
        voiceFiles: vendorConfig.vendorConfig.voiceFiles,
        vendorId: vendorConfig.vendorConfig.vendorId,
      }
    }
    if (projectVendorId) {
      await projectsStore.updateProjectAppVendorConfig(projectVendorId, data)
    } else {
      await projectsStore.createProjectAppVendorConfig(data)
    }

    await projectsStore.getProjectApps({ projectId: match.params.projectId })
    setState({
      ...state,
      appVoiceModalVisible: false,
    })
  }

  /**
   * Generate BatchId by calling voice service
   */
  const generateBatchID = async () => {
    let { batchId } = state.selectedVendor.vendorConfig
    const { dse } = state.selectedVendor.vendorConfig

    setState({
      ...state,
      selectedVendor: {
        ...state.selectedVendor,
        vendorConfig: {
          ...state.selectedVendor.vendorConfig,
          isLoadingBatchId: true,
        },
      },
    })

    const voiceFileChanges = (voiceAssetsStore.voiceAssets[`${batchId}-${dse}`] || []).length >= 1

    try {
      /* eslint-disable no-restricted-globals, no-alert */
      if ((voiceFileChanges
        && confirm('All non-TRANSFERRED files will be discarded, are you sure you want to generate batchId ?'))
        || (!voiceFileChanges && confirm('Are you sure you want to generate batchId ?'))) {
        // Call Voice Service
        // const companyId = (await companiesStore.getCurrentCompany()).id
        const { companyId } = match.params
        await voiceAssetsStore.generateBatchID({ companyId, description: 'Originated from centralized service UI' })
      }
      /* eslint-enable no-restricted-globals, no-alert */
    } catch (err) {
      console.error(err)
    }

    batchId = voiceAssetsStore.generateBatchId || batchId

    setState({
      ...state,
      selectedVendor: {
        ...state.selectedVendor,
        vendorConfig: {
          ...state.selectedVendor.vendorConfig,
          batchId,
          batchIdError: [],
          isLoadingBatchId: false,
        },
      },
    })
  }

  /**
   * Handling Click to redirect to Asset Management Page
   */
  const handleRowClick = async (application) => {
    const paId = application.id
    // eslint-disable-next-line max-len
    history.push(`/companies/${match.params.companyId}/divisions/${match.params.divisionId}/projects/${match.params.projectId}/paId/${paId}/assets`)
  }

  const appTableHeaders = [{
    label: '#',
    key: 'id',
    styles: {
      width: '50px',
      justifyContent: 'center',
    },
  },
  {
    label: 'Name',
    key: 'name',
    styles: {
      flex: 1,
    },
  },
  // {
  //   label: 'Address',
  //   key: 'address',
  //   styles: {
  //     flex: 2,
  //   },
  // },
  {
    label: 'Configuration',
    styles: {
      flex: 6,
    },
    render: (item) => ((

      <div className="config-buttons">
        <Button
          // label="email"
          dataTip="Email"
          icon={faEnvelope}
          disabled={!item.email || !permissions.isAllowed('projectApplications.update')}
          variant={item.email ? 'primary' : 'default'}
          onClick={() => { openVendorConfigurationModal('EMAIL', item) }}
          customStyle={{ marginRight: 50 }}
        />
        <Button
          // label="sms"
          dataTip="SMS"
          icon={faComment}
          disabled={!item.sms || !permissions.isAllowed('projectApplications.update')}
          variant={item.sms ? 'primary' : 'default'}
          onClick={() => { openVendorConfigurationModal('SMS', item) }}
          customStyle={{ marginRight: 50 }}
        />
        <Button
          // label="voice"
          dataTip="Voice"
          icon={faPhone}
          disabled={!item.voice || !permissions.isAllowed('projectApplications.update')}
          variant={item.voice ? 'primary' : 'default'}
          onClick={() => { openVoiceConfigurationModal('VOICE', item) }}
          customStyle={{ marginRight: 50 }}
        />
        <Button
          // label="eons"
          dataTip="Eons"
          icon={faHome}
          disabled={!item.eons || !permissions.isAllowed('projectApplications.update')}
          variant={item.eons ? 'primary' : 'default'}
          onClick={() => { openVendorConfigurationModal('EONS', item) }}
          customStyle={{ marginRight: 50 }}
        />
        <Button
          // label="cpp"
          dataTip="CPP"
          icon={faBook}
          disabled={!item.cpp || !permissions.isAllowed('projectApplications.update')}
          variant={item.cpp ? 'primary' : 'default'}
          onClick={() => { openVendorConfigurationModal('CPP', item) }}
          customStyle={{ marginRight: 50 }}
        />
        <Button
          // label="logic"
          dataTip="Logic"
          icon={faBoxes}
          disabled={!item.logic || !permissions.isAllowed('projectApplications.update')}
          variant={item.logic ? 'primary' : 'default'}
          onClick={() => { openVendorConfigurationModal('Logic', item) }}
          customStyle={{ marginRight: 50 }}
        />
        <Button
          // label="url"
          dataTip="URL"
          icon={faLink}
          disabled={!item.url || !permissions.isAllowed('projectApplications.update')}
          variant={item.url ? 'primary' : 'default'}
          onClick={() => { openVendorConfigurationModal('URL', item) }}
          customStyle={{ marginRight: 50 }}
        />
        <Button
          // label="unsub"
          dataTip="Unsubscribe Settings"
          icon={faMailBulk}
          disabled={!item.unsubscribe || !permissions.isAllowed('projectApplications.update')}
          variant={item.unsubscribe ? 'primary' : 'default'}
          onClick={() => { openUnsubscribeConfigurationModal('UNSUBSCRIBE', item) }}
          customStyle={{ marginRight: 50 }}
        />
        <Button
          // label="custom"
          dataTip="Custom Channel"
          icon={faBoxOpen}
          disabled={!item.custom || !permissions.isAllowed('projectApplications.update')}
          variant={item.custom ? 'primary' : 'default'}
          onClick={() => { openVendorConfigurationModal('CUSTOM', item) }}
          customStyle={{ marginRight: 50 }}
        />
        <ReactTooltip />
      </div>
    )),
  }]

  const tokenTableHeaders = [{
    label: '#',
    key: 'id',
    styles: {
      width: '50px',
      justifyContent: 'center',
    },
  }, {
    label: 'Name',
    key: 'name',
    styles: {
      flex: 1,
    },
  }, {
    label: 'Token',
    key: 'value',
    styles: {
      flex: 1,
    },
  }, {
    label: 'Application name',
    key: 'applicationName',
    styles: {
      flex: 1,
    },
  }]

  return (
    <>
      <PageTitle title="Details" breadcrumbs={state.breadcrumbs} />
      <FloatingButtonContainer>
        <FloatingButton
          requiredPermission="projectApplications.create"
          icon={faBroadcastTower}
          onClick={openAddApplicationModal}
          dataTip="Add Application"
        />
        <FloatingButton
          requiredPermission="tokens.create"
          icon={faKey}
          onClick={openCreateTokenModal}
          dataTip="Add Token"
        />
      </FloatingButtonContainer>
      <div className="page-content project-details-container">
        <div className="page-section">
          Applications
        </div>
        <Table
          headers={appTableHeaders}
          page={{ data: projectsStore.projectApps }}
          hidePaginator
        >
          <Button
            dataTip="View Assets"
            icon={faExternalLinkSquareAlt}
            variant="primary"
            onClick={handleRowClick}
          />
          <Button
            dataTip="Billing Details"
            requiredPermission="billing.get"
            icon={faDollarSign}
            variant="primary"
            onClick={openBillingAppModal}
          />
          <Button
            // dataTip="Remove"
            requiredPermission="projectApplications.delete"
            icon={faTrashAlt}
            variant="error"
            onClick={openRemoveApplicationModal}
          />
        </Table>

        <div className="page-section">
          Tokens
        </div>
        <Table
          isLoading={tokensStore.inProgress}
          headers={tokenTableHeaders}
          page={tokensStore.tokensPage}
          onPageChange={handlePagination}
        >
          <Button requiredPermission="tokens.update" icon={faEdit} variant="primary" onClick={openEditTokenModal} />
          <Button requiredPermission="tokens.delete" icon={faTrashAlt} variant="error" onClick={openRemoveTokenModal} />
        </Table>
      </div>

      <Modal
        show={state.billingModalVisible}
        header="Billing"
        onDismiss={dismissBillingAppModal}
        isLoading={billingsStore.inProgress}
        onSubmit={() => { }}
        isDisabled
        buttons={billingsStore.billing.length ? billingModalBtns : ''}
        width="450px"
      >
        <div style={{}}>
          {state.billingModalVisible ? (
            <DateRange
              onSelect={selectBillingDate}
              debounceTime={800}
            />
          ) : ''}
          <div>
            {billingsStore.billing && billingsStore.billing.length
              ? (
                <>
                  <Table
                    headers={billingHeaders}
                    page={{
                      data: billingsStore.billing.map((bill) => ({
                        _id: PROTOCOL_MAPPING[bill._id] ? PROTOCOL_MAPPING[bill._id] : bill._id,
                        counter: bill.counter,
                      })),
                    }}
                  />
                </>
              ) : ''}
          </div>
        </div>
      </Modal>
      <Modal
        show={state.addApplicationModalVisible}
        header="Select application"
        submitLabel="Submit"
        onSubmit={submitApplication}
        onDismiss={dismissAddApplicationModal}
        width="60%"
      >
        <Form>
          <FormDropdown
            name="applicationId"
            label="Application"
            options={(applicationsStore.applicationsPage.data || []).map((app) => ({
              value: app.id, label: app.name,
            }))}
            value={state.selectedApplication.applicationId}
            onChange={handleApplicationChange}
            errorMessage="Please select an application"
            required
          />
        </Form>
      </Modal>

      <Modal
        show={state.removeApplicationModalVisible}
        onDismiss={dismissRemoveApplicationModal}
        onSubmit={removeApplication}
        submitButtonVariant="error"
        submitLabel="Remove"
        header="Confirm removal"
        width="60%"
      >
        <div>
          Are you sure you want to remove the selected application?
        </div>
      </Modal>

      <Modal
        show={state.manageTokenModalVisible}
        header={state.selectedToken.id ? 'Edit token' : 'Add token'}
        submitLabel={state.selectedToken.id ? 'Save' : 'Create'}
        onSubmit={submitToken}
        onDismiss={dismissManageTokenModal}
        isLoading={tokensStore.inProgress}
        width="60%"
      >
        <Form>
          <FormInput
            name="name"
            label="Name"
            value={state.selectedToken.name}
            onChange={handleTokenChange}
            required
          />
          <FormDropdown
            name="projectAppId"
            label="Project application"
            options={projectsStore.projectApps.map((app) => ({ value: app.id, label: app.name }))}
            value={state.selectedToken.projectAppId}
            onChange={handleTokenChange}
            errorMessage="Please select an application"
            disabled={Boolean(state.selectedToken.id)}
            required
          />
        </Form>
      </Modal>

      <Modal
        show={state.removeTokenModalVisible}
        onDismiss={dismissRemoveTokenModal}
        onSubmit={removeToken}
        isLoading={tokensStore.inProgress}
        submitButtonVariant="error"
        submitLabel="Remove"
        header="Confirm removal"
        width="60%"
      >
        <div>
          Are you sure you want to remove the selected token?
        </div>
      </Modal>
      <Modal
        show={state.appVoiceModalVisible}
        onDismiss={dismissVoiceConfigurationModal}
        onSubmit={saveVoiceConfigurationModal}
        height={state.selectedVendor.vendorName !== VOICE_VENDOR.FreeSwitch ? '600px' : ''}
        submitLabel="Save"
        header={`Edit ${state.selectedApplication.name}'s ${state.selectedMessageType} settings`}
        width="60%"
      >
        {
          (state.appVoiceModalVisible)
            ? (
              <Form>
                <FormDropdown
                  name="vendorId"
                  label="Application vendor"
                  options={(vendorsStore.vendorsPage.data || []).map((vendor) => ({
                    value: vendor.id, label: vendor.name,
                  }))}
                  value={state.selectedVendor.vendorId}
                  onChange={vendorChange}
                  errorMessage="Please select a vendor"
                  required
                />
                <span className="server">Select Voice Server(s)</span>
                <MultiSelect
                  options={state.voiceOptions}
                  selected={state.selectedMultiselect}
                  onSelectedChanged={(selected) => handleMultiselectChange({ selected })}
                />
                <div className="singleRow">
                  <FormInput
                    name="callbackUrl"
                    label="Callback Url"
                    type="text"
                    value={state.selectedVendor.vendorConfig.callbackUrl}
                    onChange={handleInputChange}
                    required
                  />
                  {state.selectedVendor.vendorName === VOICE_VENDOR.FreeSwitch && (
                    <FormInput
                      name="campaignId"
                      label="Campaign Id"
                      type="text"
                      value={state.selectedVendor.vendorConfig.campaignId}
                      onChange={handleInputChange}
                      required
                    />
                  )}
                </div>
                {
                  (state.selectedVendor.vendorConfig && state.selectedVendor.vendorName !== VOICE_VENDOR.FreeSwitch)
                    ? (
                      <div>
                        {/** Voice File Asset Management */}
                        <div className="voiceFiles">
                          <div style={{ marginTop: '1vh' }}>
                            <VoiceAssets
                              batchId={state.selectedVendor.vendorConfig.batchId}
                              dse={state.selectedVendor.vendorConfig.dse}
                              voiceIDS={(
                                <div className="singleRow">
                                  <FormInput
                                    name="batchId"
                                    label="Batch ID"
                                    value={state.selectedVendor.vendorConfig.batchId}
                                    type="number"
                                    onChange={(event) => { handleInputVoiceAsset(event) }}
                                    errors={state.selectedVendor.vendorConfig.batchIdError}
                                    required
                                  />
                                  <Button
                                    {...{ name: 'btn-add-file' }}
                                    label="Generate"
                                    isLoading={state.selectedVendor.vendorConfig.isLoadingBatchId}
                                    onClick={generateBatchID}
                                    variant="primary"
                                    classNames={['btn-add-batchID']}
                                  />
                                  <FormInput
                                    name="dse"
                                    label="DSE"
                                    value={state.selectedVendor.vendorConfig.dse}
                                    type="number"
                                    onChange={handleInputVoiceAsset}
                                    required
                                  />
                                </div>
                              )}
                              di={state.selectedVendor.vendorConfig.di}
                              dndStatus={state.selectedVendor.vendorConfig.dropFileActive}
                              onSelectedFile={(file) => {
                                setState({
                                  ...state,
                                  selectedVendor: {
                                    ...state.selectedVendor,
                                    vendorConfig: {
                                      ...state.selectedVendor.vendorConfig,
                                      dropFileActive: false,
                                      voiceFiles: [...(state.selectedVendor.vendorConfig.voiceFiles
                                        ? state.selectedVendor.vendorConfig.voiceFiles : []), file.name],
                                    },
                                  },
                                })
                              }}
                              // Injecting Input & button from parent as component
                              dropFileComponent={!state.selectedVendor.vendorConfig.dropFileActive ? (
                                <div className="singleRow">
                                  <FormInput
                                    name="di"
                                    label="DI"
                                    value={state.selectedVendor.vendorConfig.di}
                                    type="number"
                                    onChange={handleInputChange}
                                    required
                                  />
                                  <Button
                                    {...{ name: 'btn-add-file' }}
                                    label="Add Voice File"
                                    icon={faFileAudio}
                                    disabled={
                                      !state.selectedVendor.vendorConfig.di
                                      || !state.selectedVendor.vendorConfig.batchId
                                      || !state.selectedVendor.vendorConfig.dse
                                    }
                                    variant="primary"
                                    onClick={() => {
                                      setState({
                                        ...state,
                                        selectedVendor: {
                                          ...state.selectedVendor,
                                          vendorConfig: {
                                            ...state.selectedVendor.vendorConfig,
                                            dropFileActive: true,
                                          },
                                        },
                                      })
                                    }}
                                  />
                                </div>
                              ) : ''}
                            />
                          </div>
                        </div>
                        <Checkbox
                          value={state.selectedVendor.vendorConfig.keepAliveBatchId}
                          name="keepAliveBatchId"
                          label="Keep BatchId Alive?"
                          onChange={handleCheckboxChange}
                        />
                        <div className="schedules">
                          <h2>Schedules</h2>

                          <div className="checkboxs">
                            <Checkbox
                              key="Sunday"
                              value={state.selectedVendor.vendorConfig.sunday}
                              name="sunday"
                              label="Sunday"
                              onChange={handleCheckboxChange}
                            />
                            <Checkbox
                              key="Monday"
                              value={state.selectedVendor.vendorConfig.monday}
                              name="monday"
                              label="Monday"
                              onChange={handleCheckboxChange}
                            />
                            <Checkbox
                              key="Tuesday"
                              value={state.selectedVendor.vendorConfig.tuesday}
                              name="tuesday"
                              label="Tuesday"
                              onChange={handleCheckboxChange}
                            />
                            <Checkbox
                              key="Wednesday"
                              value={state.selectedVendor.vendorConfig.wednesday}
                              name="wednesday"
                              label="Wednesday"
                              onChange={handleCheckboxChange}
                            />
                            <Checkbox
                              key="Thursday"
                              value={state.selectedVendor.vendorConfig.thursday}
                              name="thursday"
                              label="Thursday"
                              onChange={handleCheckboxChange}
                            />
                            <Checkbox
                              key="Friday"
                              value={state.selectedVendor.vendorConfig.friday}
                              name="friday"
                              label="Friday"
                              onChange={handleCheckboxChange}
                            />
                            <Checkbox
                              key="Saturday"
                              value={state.selectedVendor.vendorConfig.saturday}
                              name="saturday"
                              label="Saturday"
                              onChange={handleCheckboxChange}
                            />
                          </div>
                          <div className="singleRow">
                            <FormInput
                              name="startHour"
                              label="Start Hour"
                              value={state.selectedVendor.vendorConfig.startHour}
                              type="number"
                              onChange={handleInputChange}
                              required
                            />
                            <FormInput
                              name="endHour"
                              label="End Hour"
                              value={state.selectedVendor.vendorConfig.endHour}
                              type="number"
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                        </div>
                      </div>
                    )
                    : ''
                }
                {(state.selectedVendor.vendorConfig && state.selectedVendor.vendorName === VOICE_VENDOR.FreeSwitch && (
                  <>
                    <div className="voiceFiles">
                      <div style={{ marginTop: '1vh' }}>
                        <VoiceAssets
                          vendorName={state.selectedVendor.vendorName}
                          dndStatus={state.selectedVendor.vendorConfig.dropFileActive}
                          campaignId={state.selectedVendor.vendorConfig.campaignId}
                          companyId={match.params.companyId}
                          onSelectedFile={(file) => {
                            setState({
                              ...state,
                              selectedVendor: {
                                ...state.selectedVendor,
                                vendorConfig: {
                                  ...state.selectedVendor.vendorConfig,
                                  dropFileActive: false,
                                  voiceFiles: [...(state.selectedVendor.vendorConfig.voiceFiles
                                    ? state.selectedVendor.vendorConfig.voiceFiles : []), file.name],
                                },
                              },
                            })
                          }}
                          // Injecting Input & button from parent as component
                          dropFileComponent={!state.selectedVendor.vendorConfig.dropFileActive ? (
                            <div className="singleRow">
                              <Button
                                {...{ name: 'btn-add-file' }}
                                label="Add Voice File"
                                icon={faFileAudio}
                                variant="primary"
                                onClick={() => {
                                  setState({
                                    ...state,
                                    selectedVendor: {
                                      ...state.selectedVendor,
                                      vendorConfig: {
                                        ...state.selectedVendor.vendorConfig,
                                        dropFileActive: true,
                                      },
                                    },
                                  })
                                }}
                              />
                            </div>
                          ) : ''}
                        />
                      </div>
                    </div>
                  </>
                ))}
              </Form>
            )
            : ''
        }
      </Modal>
      <Modal
        show={state.vendorConfigurationModalVisible}
        onDismiss={dismissVendorConfigurationModal}
        onSubmit={saveVendorConfiguration}
        submitLabel="Save"
        header={`Edit ${state.selectedApplication.name}'s ${state.selectedMessageType} settings`}
        width="60%"
        isDisabled={Object.keys(state.selectedVendorErrors).length > 0}
      >
        <Form>
          <FormDropdown
            name="vendorId"
            label="Application vendor"
            options={(vendorsStore.vendorsPage.data || []).map((vendor) => ({
              value: vendor.id, label: vendor.name,
            }))}
            value={state.selectedVendor.vendorId}
            onChange={vendorChange}
            errorMessage="Please select a vendor"
            required
          />
          {state.selectedVendorTemplate
            && state.selectedVendorTemplate.length ? (
            <>
              <div className="two-column">
                {state.selectedVendorTemplate.map((template) => {
                  const ConfigInput = vendorConfigInputComp(template)
                  return (
                    <ConfigInput
                      key={template.key}
                      name={template.key}
                      className={vendorConfigClass(template)}
                      label={vendorConfigLabel(template)}
                      value={vendorConfigValueToDisplay(template, state.selectedVendor.vendorConfig[template.key])}
                      onChange={(ev) => onVendorConfigChange(ev, template)}
                      errors={state.selectedVendorValidationErrors[`${template.key}Error`]
                          || state.selectedVendorErrors[template.key]}
                      templates={template.templates}
                      schema={template.schema}
                      unit={template.unit}
                    />
                  )
                })}
              </div>
              <br />
              {state.selectedVendor?.vendorConfig?.whiteLabel && (
                <WhiteLabelAssets
                  presentImage={state.selectedVendor.logoImage}
                  caption="Company Logo Icon"
                  onFile={(imageString) => {
                    setState({
                      ...state,
                      selectedVendor: {
                        ...state.selectedVendor,
                        logoImage: imageString,
                      },
                    })
                  }}
                />
              )}
            </>
            )
            : ''}
        </Form>
      </Modal>

      <Modal
        show={state.unSubscribeModalVisible}
        onDismiss={dismissUnsubscribeConfigurationModal}
        onSubmit={saveUnsubscribeConfigurationModal}
        width="60%"
        height="600px"
        header="Unsubscribe settings"
        submitLabel="Save"
      >
        <Form>
          <FormDropdown
            name="vendorId"
            label="Application vendor"
            options={(vendorsStore.vendorsPage.data || []).map((vendor) => ({
              value: vendor.id, label: vendor.name,
            }))}
            value={state.selectedVendor.vendorId}
            onChange={vendorChange}
            errorMessage="Please select a vendor"
            required
          />
          {(state.unSubscribeModalVisible) ? (
            <div>
              <FormInput
                name="title"
                label="Title"
                type="text"
                value={state.selectedVendor.vendorConfig.title}
                onChange={handleInputChange}
              // required
              />
              <FormInput
                name="unsub_message"
                label="Successfull Unscubscribe Message"
                type="text"
                value={state.selectedVendor.vendorConfig.unsub_message}
                errors={state.unsubMessageError}
                onChange={handleInputChange}
                required
              />
              <FormInput
                name="css"
                label="Style (Add plain CSS)"
                type="text"
                value={state.selectedVendor.vendorConfig.css}
                onChange={handleInputChange}
              />
              <div className="singleRow">
                <UnsubscribeAssets
                  presentImage={state.selectedVendor.logoImage}
                  onFile={(imageString) => {
                    setState({
                      ...state,
                      selectedVendor: {
                        ...state.selectedVendor,
                        logoImage: imageString,
                      },
                    })
                  }}
                />
              </div>
            </div>
          ) : ''}
        </Form>
      </Modal>
    </>
  )
}

ProjectDetails.propTypes = {
  billingsStore: PropTypes.shape({
    getProjectBillingCount: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      divisionId: PropTypes.string,
      companyId: PropTypes.string,
      projectId: PropTypes.string,
      paId: PropTypes.string,
    }),
  }).isRequired,
  tokensStore: PropTypes.shape({
    tokensPage: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape()),
      limit: PropTypes.number,
      offset: PropTypes.number,
      tota: PropTypes.number,
    }),
    inProgress: PropTypes.bool,
    getProjectTokens: PropTypes.func,
    deleteToken: PropTypes.func,
    createToken: PropTypes.func,
    updateToken: PropTypes.func,
  }).isRequired,
  applicationsStore: PropTypes.shape({
    applicationsPage: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape()),
      limit: PropTypes.number,
      offset: PropTypes.number,
      total: PropTypes.number,
    }),
    getApplications: PropTypes.func,
  }).isRequired,
  vendorsStore: PropTypes.shape({
    vendorsPage: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape()),
      limit: PropTypes.number,
      offset: PropTypes.number,
      total: PropTypes.number,
    }),
    getVendors: PropTypes.func,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  voicesStore: PropTypes.shape({
    voicesPage: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape()),
      limit: PropTypes.number,
      offset: PropTypes.number,
      total: PropTypes.number,
    }),
    getVoices: PropTypes.func,
  }).isRequired,
  companiesStore: PropTypes.shape({
    getCurrentCompany: PropTypes.func,
  }).isRequired,
  divisionsStore: PropTypes.shape({
    getCurrentDivision: PropTypes.func,
  }).isRequired,
  projectsStore: PropTypes.shape({
    getCurrentProject: PropTypes.func,
    getProjectApps: PropTypes.func,
    createProjectApp: PropTypes.func,
    deleteProjectApp: PropTypes.func,
    createProjectAppVendorConfig: PropTypes.func,
    updateProjectAppVendorConfig: PropTypes.func,
    projectApps: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
}

export default inject(
  constants.divisionsStore, constants.companiesStore, constants.projectsStore,
  constants.tokensStore, constants.applicationsStore, constants.vendorsStore,
  constants.voicesStore, constants.notificationStore, constants.voiceAssetsStore,
  constants.billingsStore,
)(observer(ProjectDetails))
