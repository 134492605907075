import queryString from 'query-string'
import api from './api'

export async function getProjects(query) {
  return api.get(`/api/v1/projects?${queryString.stringify(query)}`)
}

export async function getProjectById(id) {
  return api.get(`/api/v1/projects/${id}`)
}

export async function deleteProject(projectId) {
  return api.delete(`/api/v1/projects/${projectId}`)
}

export async function createProject(projectData) {
  return api.post('/api/v1/projects', projectData)
}

export async function updateProject(project) {
  const { id, ...projectData } = project
  return api.patch(`/api/v1/projects/${id}`, projectData)
}
