import {
  decorate,
  observable,
  action,
  runInAction,
} from 'mobx'
import _ from 'lodash'
import * as api from '../api/api'

export default class AuthStore {
  constructor(rootStore) {
    this.rootStore = rootStore

    runInAction(() => {
      this.initialCheckComplete = false
      this.inProgress = false
      this.loggedIn = false
      this.profile = {}
      this.isModalOpen = false
    })
  }

  async validateToken(token) {
    this.inProgress = true
    let res
    try {
      res = await api.validateResetToken(token)
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
      throw ex
    }
    runInAction(() => {
      this.inProgress = false
    })

    return res
  }

  async resetPassword(password, code, id) {
    this.inProgress = true
    try {
      await api.resetPassword(password, code, id)
      window.location.reload()
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async initiatePasswordReset(email) {
    this.inProgress = true
    try {
      await api.initiatePasswordReset(email)
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)

      runInAction(() => {
        this.inProgress = false
      })

      throw ex
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async getProfile() {
    const res = await api.getMe()
    runInAction(() => {
      const profile = { ...res.data }
      profile.permissions = _.chain(profile.permissions)
        .values()
        .flattenDeep()
        .uniq()
        .value()
      this.profile = profile
    })
  }

  async getMe() {
    this.inProgress = true
    try {
      await this.getProfile()
      runInAction(() => {
        this.loggedIn = true
      })
    } catch (ex) {
      if (this.loggedIn) {
        this.rootStore.errorStore.handleError(ex)
      }
    }

    runInAction(() => {
      this.initialCheckComplete = true
      this.inProgress = false
    })
  }

  async login(username, password) {
    this.inProgress = true
    try {
      await api.login(username, password)
      await this.getProfile()
      runInAction(() => {
        this.loggedIn = true
      })
    } catch (ex) {
      this.rootStore.notificationStore.error({
        message: ex.message.includes('401') ? 'Invalid email or password' : ex.message,
      })
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async logout() {
    try {
      runInAction(() => {
        this.loggedIn = false
      })
      await api.logout()
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }
  }

  setModalState(isOpen) {
    try {
      runInAction(() => {
        this.isModalOpen = isOpen
      })
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }
  }
}

decorate(AuthStore, {
  initialCheckComplete: observable,
  inProgress: observable,
  loggedIn: observable,
  profile: observable,
  isModalOpen: observable,
  login: action,
  logout: action,
  getMe: action,
  getProfile: action,
  resetPassword: action,
  validateToken: action,
  initiatePasswordReset: action,
})
