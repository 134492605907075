import React, { memo } from 'react'
import PropTypes from 'prop-types'
import './spinner.scss'

const SpinnerComp = ({
  variant, width, height, children,
}) => (
  <div className="flex-column flex-center">
    <div className={`spinner ${variant}`} style={{ width, height }}>
      {[...Array(5)].map((_, n) => <div key={n} className={`rect${n + 1}`} />)}
    </div>
    <div>{children}</div>
  </div>
)

SpinnerComp.propTypes = {
  variant: PropTypes.oneOf(['light', 'dark']),
  width: PropTypes.string,
  height: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

SpinnerComp.defaultProps = {
  variant: 'light',
  width: '25px',
  height: '20px',
  children: null,
}

export default memo(SpinnerComp)
