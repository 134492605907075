import { configure } from 'mobx'
import ApplicationsStore from './applicationsStore'
import AuthStore from './authStore'
import CompaniesStore from './companiesStore'
import DivisionsStore from './divisionsStore'
import NotificationStore from './notificationStore'
import VendorsStore from './vendorsStore'
import UsersStore from './usersStore'
import ProjectsStore from './projectsStore'
import RolesStore from './rolesStore'
import TokensStore from './tokensStore'
import VoicesStore from './voicesStore'
import VoiceAssetsStore from './voiceAssetsStore'
import ErrorStore from './errorStore'
import BillingsStore from './billingsStore'
import CampaignsStore from './campaignsStore'
import TemplatesStore from './templatesStore'
import MasterTemplateStore from './masterTemplateStore'

configure({ enforceActions: 'always' })

export default class RootStore {
  constructor() {
    this.billingsStore = new BillingsStore(this)
    this.applicationsStore = new ApplicationsStore(this)
    this.authStore = new AuthStore(this)
    this.companiesStore = new CompaniesStore(this)
    this.divisionsStore = new DivisionsStore(this)
    this.notificationStore = new NotificationStore(this)
    this.vendorsStore = new VendorsStore(this)
    this.usersStore = new UsersStore(this)
    this.projectsStore = new ProjectsStore(this)
    this.rolesStore = new RolesStore(this)
    this.tokensStore = new TokensStore(this)
    this.voicesStore = new VoicesStore(this)
    this.voiceAssetsStore = new VoiceAssetsStore(this)
    this.errorStore = new ErrorStore(this)
    this.campaignsStore = new CampaignsStore(this)
    this.templatesStore = new TemplatesStore(this)
    this.masterTemplateStore = new MasterTemplateStore(this)
  }
}
