export const constants = {
  applicationsStore: "applicationsStore",
  authStore: "authStore",
  companiesStore: "companiesStore",
  divisionsStore: "divisionsStore",
  notificationStore: "notificationStore",
  vendorsStore: "vendorsStore",
  usersStore: "usersStore",
  projectsStore: "projectsStore",
  rolesStore: "rolesStore",
  tokensStore: "tokensStore",
  voicesStore: "voicesStore",
  voiceAssetsStore: "voiceAssetsStore",
  errorStore: "errorStore",
  billingsStore: "billingsStore",
  campaignsStore: "campaignsStore",
  templatesStore: "templatesStore",
  masterTemplateStore: "masterTemplateStore",
}

export const PROTOCOL_MAPPING = {
  XML: "sms-xml",
  voice: "voice",
  "email-api": "email",
  REST: "sms-rest",
}

export const VOICE_VENDOR = {
  FreeSwitch: "FreeSwitch",
}
