import queryString from 'query-string'
import api from './api'

export async function getApplications(query) {
  const { data: page } = await api.get(`/api/v1/applications?${queryString.stringify(query)}`)

  page.data = page.data.map((app) => {
    const data = { ...app }
    data.email = Boolean(data.email)
    data.sms = Boolean(data.sms)
    data.voice = Boolean(data.voice)
    data.eons = Boolean(data.eons)
    data.cpp = Boolean(data.cpp)
    return data
  })

  return page
}

export async function deleteApplication(applicationId) {
  return api.delete(`/api/v1/applications/${applicationId}`)
}

export async function createApplication(applicationData) {
  return api.post('/api/v1/applications', applicationData)
}

export async function updateApplication(application) {
  const { id, ...applicationData } = application
  return api.patch(`/api/v1/applications/${id}`, applicationData)
}
