import {
  decorate, observable, runInAction, action,
} from 'mobx'
import * as rolesApi from '../api/rolesApi'

export default class RolesStore {
  constructor(rootStore) {
    this.rootStore = rootStore

    runInAction(() => {
      this.inProgress = false
      this.roles = []
      this.scopedRoles = []
    })
  }

  clear() {
    this.roles = []
    this.scopedRoles = []
  }

  async getScopedRoles(query = {}) {
    runInAction(() => {
      this.inProgress = true
    })
    try {
      const res = await rolesApi.getScopedRoles({ ...query, userId: this.rootStore.authStore.profile.id })
      runInAction(() => {
        this.scopedRoles = res.data
      })
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async getRoles(query = {}) {
    this.inProgress = true
    try {
      const res = await rolesApi.getRoles(query)
      runInAction(() => {
        this.roles = res.data
      })
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async deleteRole(roleId) {
    this.inProgress = true
    try {
      await rolesApi.deleteRole(roleId)
      this.rootStore.notificationStore.success({ message: 'Role deleted' })
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async createRole(roleData) {
    this.inProgress = true
    try {
      await rolesApi.createRole({ ...roleData, userId: this.rootStore.authStore.profile.id })
      this.rootStore.notificationStore.success({ message: 'Role created' })
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async updateRole(roleData) {
    this.inProgress = true
    try {
      await rolesApi.updateRole(roleData)
      this.rootStore.notificationStore.success({ message: 'Role updated' })
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }
}

decorate(RolesStore, {
  inProgress: observable,
  roles: observable,
  scopedRoles: observable,
  getScopedRoles: observable,
  updateRole: action,
  createRole: action,
  deleteRole: action,
  getRoles: action,
  clear: action,
})
