import React, { memo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'
import Button from '../Button/Button'
import Panel from '../Panel/Panel'
import './modal.scss'

const ModalComp = ({
  show, header, width, height, children, onDismiss, submitLabel, onSubmit,
  submitButtonVariant, isDisabled, isLoading, buttons, noSubmit, customCanceltext
}) => {
  const modalHeader = (
    <>
      {header}
      <span className="modal-close" onClick={onDismiss}>
        <FontAwesomeIcon icon={faTimes} color="white" />
      </span>
    </>
  )

  const stopPropagation = (event) => {
    event.stopPropagation()
  }

  return (
    <div className={`modal-wrapper ${show ? 'visible' : ''}`}>
      <div className="backdrop flex-center" onClick={onDismiss}>
        <div onClick={stopPropagation} style={{ width, height }}>
          <Panel header={modalHeader} width="100%" height="100%">
            <div className="panel-content">
              {children}
            </div>

            <div className="actions">
              {buttons}
              <Button
                label={customCanceltext ? customCanceltext : 'Cancel'}
                variant="default"
                onClick={onDismiss}
              />
              {!noSubmit && (
                <Button
                  label={submitLabel}
                  onClick={onSubmit}
                  variant={submitButtonVariant}
                  disabled={isDisabled}
                  isLoading={isLoading}
                />
              )}
            </div>
          </Panel>
        </div>
      </div>
    </div>
  )
}

ModalComp.propTypes = {
  header: PropTypes.string.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  show: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  submitLabel: PropTypes.string,
  submitButtonVariant: PropTypes.oneOf(['default', 'white', 'primary', 'error']),
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  buttons: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node), PropTypes.node,
  ]),
  noSubmit: PropTypes.bool,
  customCanceltext: PropTypes.string,
}

ModalComp.defaultProps = {
  show: false,
  isDisabled: false,
  isLoading: false,
  submitButtonVariant: 'primary',
  submitLabel: 'Submit',
  width: '50%',
  height: 'auto',
  noSubmit: false,
  customCanceltext: '',
}

export default memo(ModalComp)
