import React, { memo } from 'react'
import PropTypes from 'prop-types'
import './table.scss'
import _ from 'lodash'
import Spinner from '../Spinner/Spinner'
import Paginator from './Paginator'

const TableComp = ({
  page, headers, isLoading, children, onPageChange, onRowClick, hidePaginator,
}) => {
  const { data = null } = page

  const handleRowClick = (item) => {
    if (onRowClick) {
      onRowClick(item)
    }
  }

  const actions = _.flattenDeep([children])

  return (
    <div className="table-wrapper">
      <div className="table">
        <div className="header">
          {headers.map((header, idx) => <div className="cell" key={idx} style={header.styles}>{header.label}</div>)}
          {children && <div style={{ width: `${actions.length * 50}px` }} className="cell actions">Actions</div>}
        </div>
        <div className={`body ${isLoading ? 'flex-center' : ''}`}>
          {(isLoading || !data)
            ? <Spinner variant="dark" height="60px" width="60px" />
            : ''}
          {(!isLoading && data) && (data.length
            ? data.map((item, idx) => (
              <div
                className={
                  `row 
                  ${(item.isSuspended || item.isDeleted || item.deleted) ? 'muted' : ''} 
                  ${(onRowClick && !(item.isSuspended || item.isDeleted || item.deleted)) ? 'clickable' : ''}`
                }
                key={idx}
                onClick={() => { handleRowClick(item) }}
              >
                {headers.map((header, hId) => (
                  <div className="cell" style={header.styles} key={hId}>
                    {header.render
                      ? header.render(item)
                      : item[header.key]}
                  </div>
                ))}
                {children && (
                  <div
                    style={{ width: `${actions.length * 50}px`, display: 'flex', justifyContent: 'start' }}
                    className={`cell actions ${(item.isSuspended || item.deleted) ? 'hidden' : ''}`}
                  >
                    {actions.map((child, cId) => (
                      child.props.hidden && child.props.hidden(item)
                        ? null
                        : React.cloneElement(
                          child,
                          {
                            classNames: [`${child.props.hidden && !child.props.hidden(child) ? 'visible' : ''}`],
                            key: cId,
                            onClick: (ev) => { ev.stopPropagation(); child.props.onClick(item) },
                          },
                        )
                    ))}
                  </div>
                )}
              </div>
            ))
            : 'No result found')}
        </div>
      </div>

      {hidePaginator
        ? ''
        : <Paginator page={page} onPageChange={onPageChange} />}

    </div>
  )
}

TableComp.propTypes = {
  isLoading: PropTypes.bool,
  headers: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    key: PropTypes.string,
    styles: PropTypes.shape({}),
    render: PropTypes.func,
  })).isRequired,
  page: PropTypes.shape({
    limit: PropTypes.number,
    offset: PropTypes.number,
    total: PropTypes.number,
    data: PropTypes.arrayOf(PropTypes.object),
  }),
  onPageChange: PropTypes.func,
  onRowClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  hidePaginator: PropTypes.bool,
}

TableComp.defaultProps = {
  isLoading: false,
  page: {},
  onRowClick: null,
  onPageChange: null,
  children: null,
  hidePaginator: false,
}

export default memo(TableComp)
