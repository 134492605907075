import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { faPlus, faEdit, faCog, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { inject, observer } from 'mobx-react'
import _ from 'lodash'
import ReactTooltip from 'react-tooltip'
import { constants } from '../../../store/constants'
import Button from '../../../components/Button/Button'
import FloatingButton from '../../../components/FloatingButton/FloatingButton'
import Table from '../../../components/Table/Table'
import Modal from '../../../components/Modal/Modal'
import Form from '../../../components/Form/Form'
import FormInput from '../../../components/Form/FormInput'
import PageTitle from '../../../components/PageTitle/PageTitle'
import { PermissionContext } from '../../../PermissionContext'
import * as validators from '../../../validators'
const NEW_DIVISION = {
  name: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  postalCode: '',
  contactName: '',
  alias: ''
}

const tableHeaders = [{
  label: '#',
  key: 'id',
  styles: {
    width: '50px',
    justifyContent: 'center',
  },
}, {
  label: 'Name',
  key: 'name',
  styles: {
    flex: 1,
  },
}]

const Divisions = ({
  divisionsStore, companiesStore, match, history,
}) => {
  const permissions = useContext(PermissionContext)

  const defaultStateValues = {
    originalValue: {},
    formErrors: {},
    selectedDivision: { ...NEW_DIVISION },
    manageDivisionModalVisible: false,
    removeDivisionModalVisible: false,
    breadcrumbs: ['Companies'],
  }

  const [state, setState] = useState(defaultStateValues)

  useEffect(() => {
    const isAllowed = permissions.isAllowed('divisions.list')

    if (!isAllowed) {
      history.push('/home')
    }

    const fetchData = async () => {
      await divisionsStore.getDivisions({ companyId: match.params.companyId })
      const { name: companyName } = await companiesStore.getCurrentCompany(match.params.companyId)

      setState({
        ...state,
        breadcrumbs: [{
          label: companyName,
          url: 'companies',
        }],
      })
    }

    fetchData()

    return function cleanup() {
      divisionsStore.clear()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const validate = ({ name, alias }) => ({
    name: validators.validate({
      value: name,
      label: 'name',
      validators: [
        validators.required,
      ],
    }),
    alias: validators.validate({
      value: alias,
      label: 'alias',
      validators: [
        validators.required,
      ],
    }),
  })

  const openEditDivisionModal = (division) => {
    setState({
      ...state,
      originalValue: division,
      selectedDivision: division,
      manageDivisionModalVisible: true,
    })
  }

  const openCreateDivisionModal = () => {
    setState({
      ...state,
      originalValue: { ...NEW_DIVISION },
      selectedDivision: { ...NEW_DIVISION },
      manageDivisionModalVisible: true,
      formErrors: validate(NEW_DIVISION),
    })
  }

  const openRemoveDivisionModal = (division) => {
    setState({
      ...state,
      selectedDivision: division,
      removeDivisionModalVisible: true,
    })
  }

  const dismissManageDivisionModal = () => {
    if (!_.isEqual(state.originalValue, state.selectedDivision)) {
      // eslint-disable-next-line
      if (confirm('All unsaved changes will be discarded')) {
        setState({ ...state, manageDivisionModalVisible: false })
      }
    } else {
      setState({ ...state, manageDivisionModalVisible: false })
    }
  }

  const dismissRemoveDivisionModal = () => {
    setState({ ...state, removeDivisionModalVisible: false })
  }

  const submitDivision = async () => {
    if (state.selectedDivision.id) {
      const divisionDieldsToSave = [
      'id',
      'name',
      'address1',
      'address2',
      'city',
      'state',
      'postalCode',
      'contactName',
      'alias'
    ]
      await divisionsStore.updateDivision(_.pick(state.selectedDivision, divisionDieldsToSave))
    } else {
      state.selectedDivision.companyId = match.params.companyId
      await divisionsStore.createDivision(state.selectedDivision)
    }

    const { limit, offset } = divisionsStore.divisionsPage
    await divisionsStore.getDivisions({ limit, offset, companyId: match.params.companyId })
    setState({ ...state, manageDivisionModalVisible: false })
  }

  const deleteDivision = async () => {
    await divisionsStore.deleteDivision(state.selectedDivision.id)

    const { limit, offset } = divisionsStore.divisionsPage
    await divisionsStore.getDivisions({ limit, offset, companyId: match.params.companyId })
    setState({ ...state, removeDivisionModalVisible: false })
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    const division = {
      ...state.selectedDivision,
      [name]: value,
    }
    setState({ ...state, selectedDivision: division, formErrors: validate(division) })
  }

  const handlePagination = (query) => {
    divisionsStore.getDivisions(query)
  }

  const handleRowClick = (division) => {
    divisionsStore.setCurrentDivision(division)
    history.push(`/companies/${match.params.companyId}/divisions/${division.id}/projects`)
  }

  const generateDivisionAlias = () => {
    let s = ''
    while (s.length < 10) s += Math.random().toString(36).substr(2, 10 - s.length)
    const division = {
      ...state.selectedDivision,
      "alias": s,
    }
    setState({ ...state, selectedDivision: division, formErrors: validate(division) })

  }

  return (
    <>
      <PageTitle title="Divisions" breadcrumbs={state.breadcrumbs} />
      <div className="page-content">
        <FloatingButton
          requiredPermission="divisions.create"
          icon={faPlus}
          onClick={openCreateDivisionModal}
          dataTip="Add Division"
        />
        <Table
          isLoading={divisionsStore.inProgress}
          headers={tableHeaders}
          page={divisionsStore.divisionsPage}
          onPageChange={handlePagination}
          onRowClick={permissions.isAllowed('projects.list') ? handleRowClick : null}
        >
          <Button
            requiredPermission="divisions.update"
            icon={faEdit}
            variant="primary"
            onClick={openEditDivisionModal}
          />
          <Button
            requiredPermission="divisions.delete"
            icon={faTrashAlt}
            variant="error"
            onClick={openRemoveDivisionModal}
          />
        </Table>
        <ReactTooltip />
      </div>

      <Modal
        show={state.manageDivisionModalVisible}
        header={state.selectedDivision.id ? 'Edit division' : 'Add division'}
        submitLabel={state.selectedDivision.id ? 'Save' : 'Create'}
        onSubmit={submitDivision}
        onDismiss={dismissManageDivisionModal}
        isLoading={divisionsStore.inProgress}
        isDisabled={Object.keys(state.formErrors).some((k) => state.formErrors[k].length)}
        width="60%"
      >
        <Form>
          <FormInput
            name="name"
            label="Name"
            value={state.selectedDivision.name}
            errors={state.formErrors.name}
            onChange={handleChange}
            required
          />
          <FormInput
            name="address1"
            label="Address 1"
            value={state.selectedDivision.address1}
            onChange={handleChange}
          />
          <FormInput
            name="address2"
            label="Address 2"
            value={state.selectedDivision.address2}
            onChange={handleChange}
          />
          <FormInput
            name="city"
            label="City"
            value={state.selectedDivision.city}
            onChange={handleChange}
          />
          <FormInput
            name="state"
            label="State"
            value={state.selectedDivision.state}
            onChange={handleChange}
          />
          <FormInput
            name="postalCode"
            label="Postal code"
            value={state.selectedDivision.postalCode}
            onChange={handleChange}
          />
          <FormInput
            name="contactName"
            label="Contact name"
            value={state.selectedDivision.contactName}
            onChange={handleChange}
          />
          <FormInput
            name="alias"
            label="Alias"
            value={state.selectedDivision.alias}
            unit={<Button
              icon={faCog}
              disabled={state.originalValue.id && state.originalValue.alias}
              variant="primary"
              onClick={generateDivisionAlias}
            />}
            disabled={state.originalValue.id && state.originalValue.alias}
            onChange={handleChange}
            errors={state.formErrors.alias}
            required={true}
          />
        </Form>
      </Modal>

      <Modal
        show={state.removeDivisionModalVisible}
        onDismiss={dismissRemoveDivisionModal}
        onSubmit={deleteDivision}
        isLoading={divisionsStore.inProgress}
        submitButtonVariant="error"
        submitLabel="Remove"
        header="Confirm removal"
        width="60%"
      >
        <div>
          All the Projects, Applications and Tokens under this division will get removed. are you sure?
        </div>
      </Modal>
    </>
  )
}

Divisions.propTypes = {
  companiesStore: PropTypes.shape({
    getCurrentCompany: PropTypes.func,
  }).isRequired,
  divisionsStore: PropTypes.shape({
    divisionsPage: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape()),
      limit: PropTypes.number,
      offset: PropTypes.number,
      tota: PropTypes.number,
    }),
    inProgress: PropTypes.bool,
    getDivisions: PropTypes.func,
    deleteDivision: PropTypes.func,
    createDivision: PropTypes.func,
    updateDivision: PropTypes.func,
    setCurrentDivision: PropTypes.func,
    clear: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      companyId: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
}

export default inject(constants.divisionsStore, constants.companiesStore)(observer(Divisions))
