import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import {
  faPlus, faEdit, faTrashAlt, faDollarSign,
} from '@fortawesome/free-solid-svg-icons'
import { inject, observer } from 'mobx-react'
import _ from 'lodash'
import ReactTooltip from 'react-tooltip'
import { constants, PROTOCOL_MAPPING } from '../../../store/constants'
import { PermissionContext } from '../../../PermissionContext'
import Button from '../../../components/Button/Button'
import DateRange from '../../../components/DateRange/DateRange'
import FloatingButton from '../../../components/FloatingButton/FloatingButton'
import Table from '../../../components/Table/Table'
import Modal from '../../../components/Modal/Modal'
import Form from '../../../components/Form/Form'
import FormInput from '../../../components/Form/FormInput'
import PageTitle from '../../../components/PageTitle/PageTitle'
import * as validators from '../../../validators'
import ExportCsvButton from '../../../components/ExportCsvButton/ExportCsvButton'

const NEW_PROJECT = {
  name: '',
}

const tableHeaders = [{
  label: '#',
  key: 'id',
  styles: {
    width: '50px',
    justifyContent: 'center',
  },
}, {
  label: 'Name',
  key: 'name',
  styles: {
    flex: 1,
  },
}]

const billingHeaders = [{
  label: 'Protocol',
  key: '_id',
  styles: {
    width: '50%',
    justifyContent: 'center',
  },
}, {
  label: 'Count',
  key: 'counter',
  styles: {
    width: '50%',
    justifyContent: 'center',
  },
}]

const csvHeaders = ['Protocol', 'Count']

const Projects = ({
  divisionsStore, companiesStore, projectsStore, match, history, billingsStore,
}) => {
  const permissions = useContext(PermissionContext)

  const defaultStateValues = {
    originalValue: {},
    formErrors: {},
    selectedProject: { ...NEW_PROJECT },
    manageProjectModalVisible: false,
    removeProjectModalVisible: false,
    billingModalVisible: false,
    breadcrumbs: [
      'Companies', {
        label: 'Divisions',
        url: `companies/${match.params.companyId}/divisions`,
      },
    ],
  }

  const [state, setState] = useState(defaultStateValues)

  useEffect(() => {
    const isAllowed = permissions.isAllowed('companies.list')

    if (!isAllowed) {
      history.push('/home')
    }

    const fetchData = async () => {
      await projectsStore.getProjects({ divisionId: match.params.divisionId })
      const { name: companyName } = await companiesStore.getCurrentCompany(match.params.companyId)
      const { name: divisionName } = await divisionsStore.getCurrentDivision(match.params.divisionId)

      const breadcrumbs = [{
        label: companyName,
        url: 'companies',
      }, {
        label: divisionName,
        url: `companies/${match.params.companyId}/divisions`,
      }]

      setState({
        ...state,
        breadcrumbs,
      })
    }

    fetchData()

    return function cleanup() {
      projectsStore.clear()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const validate = ({ name }) => ({
    name: validators.validate({
      value: name,
      label: 'name',
      validators: [
        validators.required,
      ],
    }),
  })

  const openEditProjectModal = (project) => {
    setState({
      ...state,
      originalValue: project,
      selectedProject: project,
      manageProjectModalVisible: true,
    })
  }

  const openCreateProjectModal = () => {
    setState({
      ...state,
      originalValue: { ...NEW_PROJECT },
      selectedProject: { ...NEW_PROJECT },
      manageProjectModalVisible: true,
      formErrors: validate(NEW_PROJECT),
    })
  }

  const openBillingProjectModal = (project) => {
    setState({
      ...state,
      selectedProject: project,
      billingModalVisible: true,
    })
  }

  const dismissBillingProjectModal = () => {
    billingsStore.clear()
    setState({
      ...state,
      billingModalVisible: false,
    })
  }

  const openRemoveProjectModal = (project) => {
    setState({
      ...state,
      selectedProject: project,
      removeProjectModalVisible: true,
    })
  }

  const dismissManageProjectModal = () => {
    if (!_.isEqual(state.originalValue, state.selectedProject)) {
      // eslint-disable-next-line
      if (confirm('All unsaved changes will be discarded')) {
        setState({ ...state, manageProjectModalVisible: false })
      }
    } else {
      setState({ ...state, manageProjectModalVisible: false })
    }
  }

  const dismissRemoveProjectModal = () => {
    setState({ ...state, removeProjectModalVisible: false })
  }

  const submitProject = async () => {
    if (state.selectedProject.id) {
      const { id, name } = state.selectedProject
      await projectsStore.updateProject({ id, name })
    } else {
      state.selectedProject.divisionId = match.params.divisionId
      await projectsStore.createProject(state.selectedProject)
    }

    const { limit, offset } = projectsStore.projectsPage
    await projectsStore.getProjects({ limit, offset, divisionId: match.params.divisionId })
    setState({ ...state, manageProjectModalVisible: false })
  }

  const deleteProject = async () => {
    await projectsStore.deleteProject(state.selectedProject.id)

    const { limit, offset } = projectsStore.projectsPage
    await projectsStore.getProjects({ limit, offset, divisionId: match.params.divisionId })
    setState({ ...state, removeProjectModalVisible: false })
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    const project = {
      ...state.selectedProject,
      [name]: value,
    }
    setState({ ...state, selectedProject: project, formErrors: validate(project) })
  }

  const handlePagination = (query) => {
    projectsStore.getProjects(query)
  }

  const handleRowClick = (project) => {
    projectsStore.setCurrentProject(project)
    history.push(
      `/companies/${match.params.companyId}/divisions/${match.params.divisionId}/projects/${project.id}/details`,
    )
  }

  const selectBillingDate = (dateRange) => {
    const { startDate, endDate } = dateRange[0]
    if (startDate && endDate) {
      billingsStore.getProjectBillingCount({ startDate, endDate, projectId: state.selectedProject.id })
    }
  }

  const billingModalBtns = (
    <>
      <ExportCsvButton
        fileTitle="Billing"
        data={billingsStore.billing}
        headers={csvHeaders}
        label="Export CSV"
      >
      </ExportCsvButton>
    </>
  )

  return (
    <>
      <PageTitle title="Projects" breadcrumbs={state.breadcrumbs} />
      <div className="page-content">
        <FloatingButton
          requiredPermission="projects.create"
          icon={faPlus}
          onClick={openCreateProjectModal}
          dataTip="Add Project"
        />
        <Table
          isLoading={projectsStore.inProgress}
          headers={tableHeaders}
          page={projectsStore.projectsPage}
          onPageChange={handlePagination}
          onRowClick={permissions.isAllowed('projects.get') ? handleRowClick : null}
        >
          <Button
            requiredPermission="billing.get"
            icon={faDollarSign}
            variant="primary"
            onClick={openBillingProjectModal}
          />
          <Button
            requiredPermission="projects.update"
            icon={faEdit}
            variant="primary"
            onClick={openEditProjectModal}
          />
          <Button
            requiredPermission="projects.delete"
            icon={faTrashAlt}
            variant="error"
            onClick={openRemoveProjectModal}
          />
          <ReactTooltip />
        </Table>
      </div>

      <Modal
        show={state.billingModalVisible}
        header="Billing"
        onDismiss={dismissBillingProjectModal}
        isLoading={billingsStore.inProgress}
        onSubmit={() => { }}
        isDisabled
        width="450px"
        buttons={billingsStore.billing.length ? billingModalBtns : ''}
      >
        <div style={{}}>
          {/* Using unary to trigger re-rendering (resetting calendar) */}
          {state.billingModalVisible
            ? (
              <DateRange
                onSelect={selectBillingDate}
                debounceTime={800}
              />
            ) : ''}

          <div>
            {billingsStore.billing && billingsStore.billing.length ?
              <>
                <Table
                  headers={billingHeaders}
                  page={{
                    data: billingsStore.billing.map((bill) =>
                    ({
                      _id: PROTOCOL_MAPPING[bill._id] ? PROTOCOL_MAPPING[bill._id] : bill._id,
                      counter: bill.counter
                    }))
                  }}
                >
                </Table>
              </> : ''}
          </div>
        </div>
      </Modal>

      <Modal
        show={state.manageProjectModalVisible}
        header={state.selectedProject.id ? 'Edit project' : 'Add project'}
        submitLabel={state.selectedProject.id ? 'Save' : 'Create'}
        onSubmit={submitProject}
        onDismiss={dismissManageProjectModal}
        isLoading={projectsStore.inProgress}
        isDisabled={Object.keys(state.formErrors).some((k) => state.formErrors[k].length)}
        width="60%"
      >
        <Form>
          <FormInput
            name="name"
            label="Name"
            value={state.selectedProject.name}
            errors={state.formErrors.name}
            onChange={handleChange}
            required
          />
        </Form>
      </Modal>

      <Modal
        show={state.removeProjectModalVisible}
        onDismiss={dismissRemoveProjectModal}
        onSubmit={deleteProject}
        isLoading={projectsStore.inProgress}
        submitButtonVariant="error"
        submitLabel="Remove"
        header="Confirm removal"
        width="60%"
      >
        <div>
          All the Application and Tokens under this project will get removed, Are you sure?
        </div>
      </Modal>
    </>
  )
}

Projects.propTypes = {
  billingsStore: PropTypes.shape({
    getProjectBillingCount: PropTypes.func,
  }).isRequired,
  companiesStore: PropTypes.shape({
    getCurrentCompany: PropTypes.func,
  }).isRequired,
  divisionsStore: PropTypes.shape({
    getCurrentDivision: PropTypes.func,
  }).isRequired,
  projectsStore: PropTypes.shape({
    projectsPage: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape()),
      limit: PropTypes.number,
      offset: PropTypes.number,
      tota: PropTypes.number,
    }),
    inProgress: PropTypes.bool,
    getProjects: PropTypes.func,
    deleteProject: PropTypes.func,
    createProject: PropTypes.func,
    updateProject: PropTypes.func,
    setCurrentProject: PropTypes.func,
    clear: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      divisionId: PropTypes.string,
      companyId: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
}

export default inject(
  constants.divisionsStore,
  constants.companiesStore,
  constants.projectsStore,
  constants.billingsStore,
)(observer(Projects))
