import {
  decorate, observable, runInAction, action,
} from 'mobx'
import * as templatesApi from '../api/templatesApi'

export default class TemplatesStore {
  constructor(rootStore) {
    this.rootStore = rootStore

    runInAction(() => {
      this.inProgress = false
      this.templatePage = {}
      this.currentTemplateScope = {}
    })
  }

  clear() {
    this.templatePage = {}
  }

  setCurrentTemplate(template) {
    this.currentTemplateScope = template
  }

  async getCurrentTemplate(templateId) {
    if (this.currentTemplateScope.id !== parseInt(templateId, 10)) {
      await this.getTemplateById(templateId)
    }

    return this.currentTemplateScope
  }

  async getTemplates(query = {}) {
    this.inProgress = true
    try {
      const res = await templatesApi.getTemplates(query)
      runInAction(() => {
        this.templatePage = res.data
      })
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  // async getTemplateById(templateId) {
  //   this.inProgress = true
  //   try {
  //     const res = await templatesApi.getTemplateById(templateId)
  //     runInAction(() => {
  //       this.currentTemplateScope = res.data
  //     })
  //   } catch (ex) {
  //     this.rootStore.errorStore.handleError(ex)
  //   }

  //   runInAction(() => {
  //     this.inProgress = false
  //   })
  // }

  // async deleteTemplate(templateData) {
  //   this.inProgress = true
  //   try {
  //     await templatesApi.deleteTemplate(templateData)
  //     this.rootStore.notificationStore.success({ message: 'Template deleted' })
  //   } catch (ex) {
  //     this.rootStore.errorStore.handleError(ex)
  //   }

  //   runInAction(() => {
  //     this.inProgress = false
  //   })
  // }

  // async createTemplate(templateData) {
  //   this.inProgress = true
  //   try {
  //     await templatesApi.createTemplate(templateData)
  //     this.rootStore.notificationStore.success({ message: 'Template created' })
  //   } catch (ex) {
  //     this.rootStore.errorStore.handleError(ex)
  //   }

  //   runInAction(() => {
  //     this.inProgress = false
  //   })
  // }

  async updateTemplate(templateData) {
    this.inProgress = true
    try {
      await templatesApi.updateTemplate(templateData)
      this.rootStore.notificationStore.success({ message: 'Template updated' })
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async lockUnlockTemplate(templateData, isNotification=true) {
    this.inProgress = true
    const { isLocked } = templateData
    try {
      await templatesApi.lockUnlockTemplate(templateData)
      
      if(isNotification) {
        this.rootStore.notificationStore.success({ message: `Template is ${isLocked ? 'locked' : 'unlocked'}` })
      }
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }
}

decorate(TemplatesStore, {
  inProgress: observable,
  templatePage: observable,
  currentTemplateScope: observable,
  updateTemplate: action,
  createTemplate: action,
  deleteTemplate: action,
  getTemplates: action,
  getCurrentTemplate: action,
  getTemplateById: action,
  setCurrentTemplate: action,
  lockUnlockTemplate: action,
  clear: action,
})
