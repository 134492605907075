import {
  faUsers,
  faRocket,
  faQuestionCircle,
  faBuilding,
  faSignOutAlt,
  faAngleLeft,
  faAngleRight,
  faBroadcastTower,
  faComment,
  faHeadphones,
  faHome,
  faPaperPlane,
} from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, memo, useContext } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { PermissionContext } from '../../PermissionContext'
import './sidemenu.scss'
import { EONS_URL } from '../../config'

const iconStyle = {
  marginRight: '0.5rem',
  marginTop: '-1px',
}

const menuItems = [
  {
    label: 'User management',
    icon: faUsers,
    path: '/users',
    requiredPermission: 'users.list',
  },
  {
    label: 'Companies',
    icon: faBuilding,
    path: '/companies',
    requiredPermission: 'companies.list',
  },
  {
    label: 'Applications',
    icon: faBroadcastTower,
    path: '/applications',
    requiredPermission: 'applications.list',
  },
  {
    label: 'Roles',
    icon: faRocket,
    path: '/roles',
    requiredPermission: 'roles.list',
  },
  {
    label: 'Vendors',
    icon: faComment,
    path: '/vendors',
    requiredPermission: 'vendors.list',
  },
  {
    label: 'Voice Servers',
    icon: faHeadphones,
    path: '/voice_servers',
    requiredPermission: 'voiceServer.list',
  },
  {
    label: 'Help',
    icon: faQuestionCircle,
    path: '/help',
    disabled: true,
  },
]

const SidemenuComp = ({ location, logoutFn, history }) => {
  const defaultStateValues = {
    collapsed: false,
  }

  const [state, setState] = useState(defaultStateValues)

  const toggleState = () => {
    setState({ collapsed: !state.collapsed })
  }

  const logout = async () => {
    await logoutFn()
    history.replace('/login')

  }

  const navigateToEONS = async () => {
    window.location = EONS_URL
  }

  const permissions = useContext(PermissionContext)
  return (
    <div className={`menu ${state.collapsed ? 'collapsed' : ''}`}>
      <div className="brand flex-center">
        <img className="logo" alt="logo" src={state.collapsed ? '/assets/logo_small.png' : '/assets/logo_white.png'} />
      </div>
      <div className="menu-items">
        {menuItems.map((item, idx) => (
          permissions.isAllowed(item.requiredPermission)
            ? (
              <Link
                to={item.path}
                key={idx}
                className={`menu-item ${item.disabled ? 'disabled' : ''} ${location.pathname.includes(item.path)
                  ? 'active' : ''
                  }`}
              >
                <FontAwesomeIcon
                  style={iconStyle}
                  icon={item.icon}
                  color="white"
                  data-tip={state.collapsed ? item.label : ''}
                />
                <span className="label">{item.label}</span>
              </Link>
            )
            : ''
        ))}
        <div className="menu-item" onClick={logout}>
          <FontAwesomeIcon
            style={iconStyle}
            icon={faSignOutAlt}
            color="white"
            data-tip={state.collapsed ? 'Logout' : ''}
          />
          <span className="label">Logout</span>
        </div>
      </div>
      <div className="menu-item" onClick={navigateToEONS}>
        <FontAwesomeIcon style={iconStyle} icon={faPaperPlane} color="white" data-tip={state.collapsed ? 'Eons' : ''} />
        <span className="label">EONS</span>
      </div>
      <div className="menu-item menu-toggle" onClick={toggleState}>
        <FontAwesomeIcon
          style={iconStyle}
          icon={state.collapsed ? faAngleRight : faAngleLeft} color="white" data-tip={state.collapsed ? 'Expand' : ''}
        />
        <span className="label">Minimize</span>
      </div>
    </div>
  )
}

SidemenuComp.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  logoutFn: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

export default memo(withRouter(SidemenuComp))
