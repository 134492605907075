import {
  decorate, observable, runInAction, action,
} from 'mobx'
import * as voiceAssetsApi from '../api/voiceFilesAPI'

export default class VoiceAssetsStore {
  constructor(rootStore) {
    this.rootStore = rootStore
    runInAction(() => {
      this.inProgress = false
      this.voiceAssets = {}
      this.generateBatchId = ''
    })
  }

  clear() {
    this.voiceAssets = {}
  }

  async getVoiceAssets({ batchId, dse }) {
    runInAction(() => {
      this.inProgress = true
    })
    try {
      const res = await voiceAssetsApi.getLegacyAssets({ batchId, dse })
      runInAction(() => {
        this.voiceAssets = {
          [`${batchId}-${dse}`]: res.data,
        }
      })
    } catch (ex) {
      // 404, empty directory, not an error
      if (ex.response.status !== 404) {
        this.rootStore.notificationStore.error({
          message: ex.message,
        })
      } else {
        runInAction(() => {
          this.voiceAssets = {
            [`${batchId}-${dse}`]: [],
          }
        })
      }
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async uploadLegacyFile({ query, file }) {
    runInAction(() => {
      this.inProgress = true
    })

    try {
      await voiceAssetsApi.createLegacyAsset({ ...query, file })
    } catch (ex) {
      this.rootStore.notificationStore.error({
        message: ex.message,
      })

      this.rootStore.notificationStore.error({
        message: ex.response.data.message,
      })
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async uploadAssets({ query }) {
    runInAction(() => {
      this.inProgress = true
    })

    try {
      await voiceAssetsApi.uploadAssets({ ...query })
    } catch (ex) {
      // 404, empty directory, not an error
      if (ex.response.status !== 404) {
        this.rootStore.notificationStore.error({
          message: ex.message,
        })
      }
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async deleteFile({ query }) {
    runInAction(() => {
      this.inProgress = true
    })
    try {
      await voiceAssetsApi.deleteAsset({ ...query })
    } catch (ex) {
      this.rootStore.notificationStore.error({
        message: ex.message,
      })

      this.rootStore.notificationStore.error({
        message: ex.response.data.message,
      })
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async generateBatchID({ companyId, description }) {
    runInAction(() => {
      this.inProgress = true
    })

    try {
      const res = await voiceAssetsApi.generateBatchId({ companyId, description })
      runInAction(() => {
        this.generateBatchId = res.data.batchId
      })
    } catch (ex) {
      this.rootStore.notificationStore.error({
        message: ex.message,
      })

      this.rootStore.notificationStore.error({
        message: ex.response.data.message,
      })
    }
  }

  async uploadFile({ query, file }) {
    runInAction(() => {
      this.inProgress = true
    })

    try {
      await voiceAssetsApi.createAsset({ query, file })
    } catch (ex) {
      this.rootStore.notificationStore.error({
        message: ex.message,
      })

      this.rootStore.notificationStore.error({
        message: ex.response.data.message,
      })
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async getFreeSwitchVoiceAssets({ companyId, campaignId }) {
    runInAction(() => {
      this.inProgress = true
    })
    try {
      const res = await voiceAssetsApi.getAssets({ companyId, campaignId })
      runInAction(() => {
        this.voiceAssets = {
          [`${companyId}-${campaignId}`]: res.data,
        }
      })
    } catch (ex) {
      // 404, empty directory, not an error
      if (ex.response.status !== 404) {
        this.rootStore.notificationStore.error({
          message: ex.message,
        })
      } else {
        runInAction(() => {
          this.voiceAssets = {
            [`${companyId}-${campaignId}`]: [],
          }
        })
      }
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async deleteFreeSwitchFile({ query }) {
    runInAction(() => {
      this.inProgress = true
    })
    try {
      await voiceAssetsApi.deleteFreeSwitchAsset({ query })
    } catch (ex) {
      this.rootStore.notificationStore.error({
        message: ex.message,
      })

      this.rootStore.notificationStore.error({
        message: ex.response.data.message,
      })
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async uploadFreeSwitchAssets({ query }) {
    runInAction(() => {
      this.inProgress = true
    })

    try {
      await voiceAssetsApi.uploadFreeSwitchAssets({ ...query })
    } catch (ex) {
      // 404, empty directory, not an error
      if (ex.response.status !== 404) {
        this.rootStore.notificationStore.error({
          message: ex.message,
        })
      }
    }

    runInAction(() => {
      this.inProgress = false
    })
  }
}

decorate(VoiceAssetsStore, {
  inProgress: observable,
  voiceAssets: observable,
  clear: action,
  getFreeSwitchVoiceAssets: action,
  uploadLegacyFile: action,
  deleteFile: action,
  uploadAssets: action,
  generateBatchID: action,
  uploadFile: action,
  getVoiceAssets: action,
  deleteFreeSwitchFile: action,
  uploadFreeSwitchAssets: action,
})
