import React, { memo } from 'react'
import PropTypes from 'prop-types'
import './formDropdown.scss'

const FormDropdown = ({
  label, name, options, onChange, value, required, errorMessage, disabled, placeholder,
}) => (
  <div className="form-group dropdown">
    <label className="form-label" htmlFor={name}>
      {`${label} ${required ? '*' : ''}`}
    </label>
    <select
      className={(required && !value) ? 'invalid' : ''}
      value={value}
      name={name}
      onChange={onChange}
      disabled={disabled}
    >
      <option value="">{placeholder}</option>
      {options.map((option, idx) => (
        <option key={idx} value={option.value}>{option.label}</option>
      ))}
    </select>
    <div className="text-small text-error errors">
      {(required && !value) ? errorMessage : ''}
    </div>
  </div>
)

FormDropdown.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  })).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
}

FormDropdown.defaultProps = {
  required: false,
  disabled: false,
  errorMessage: '',
  placeholder: 'Select...',
}

export default memo(FormDropdown)
