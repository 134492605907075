import queryString from 'query-string'
import api from './api'

export async function getVoices(query) {
  return api.get(`/api/v1/voice_servers?${queryString.stringify(query)}`)
}

export async function deleteVoice(voiceId) {
  return api.delete(`/api/v1/voice_servers/${voiceId}`)
}

export async function deleteVoiceCache(address) {
  return api.delete(`/api/v1/voice_servers/callcontrol/legacy/cache/reset/${address}`)
}

export async function createVoiceCache(voice) {
  return api.post(`/api/v1/voice_servers/callcontrol/legacy/cache/reset/${voice.address}`, voice)
}
export async function createVoice(voicesData) {
  return api.post('/api/v1/voice_servers', voicesData)
}

export async function updateVoiceCache(voice) {
  return api.patch(`/api/v1/voice_servers/callcontrol/legacy/cache/reset/${voice.address}`, voice)
}

export async function updateVoice(voice) {
  const { id, ...voicesData } = voice
  return api.patch(`/api/v1/voice_servers/${id}`, voicesData)
}

export async function updateSchedule(voice) {
  return api.patch('/api/v1/voice_service/callcontrol/legacy/batchScheduleOptions', voice)
}
