import React, { memo, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import Spinner from '../Spinner/Spinner'
import { PermissionContext } from '../../PermissionContext'
import './button.scss'


const ButtonComp = ({
  icon, label, isLoading, variant, classNames, disabled, onClick, requiredPermission, dataTip, customStyle, type
}) => {
  const buttonContent = icon
    ? (
      <>
        <FontAwesomeIcon icon={icon} />
        {' '}
        <span className="label">{label}</span>
      </>
    )
    : <span className="label">{label}</span>

  const cssClasses = ['btn', ...classNames]
  if (icon && label) {
    cssClasses.push('btn-with-icon')
    cssClasses.push(`btn-${variant}`)
  } else {
    cssClasses.push(`btn-${icon ? 'icon-' : ''}${variant}`)
  }

  const permissions = useContext(PermissionContext)
  return permissions.isAllowed(requiredPermission)
    ? (
      <button
        data-tip={dataTip}
        style={customStyle}
        type={type}
        className={cssClasses.join(' ')}
        onClick={onClick}
        disabled={disabled || isLoading}>
        {isLoading ? <Spinner /> : buttonContent}
      </button>
    )
    : null
}

ButtonComp.propTypes = {
  dataTip: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  customStyle: PropTypes.shape({}),
  icon: PropTypes.shape({}),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isLoading: PropTypes.bool,
  variant: PropTypes.oneOf(['default', 'white', 'primary', 'error']),
  classNames: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  requiredPermission: PropTypes.string,
  button: PropTypes.string,
}

ButtonComp.defaultProps = {
  dataTip: '',
  customStyle: null,
  isLoading: false,
  variant: 'default',
  classNames: [],
  disabled: false,
  label: '',
  icon: null,
  requiredPermission: null,
  type: 'button'
}

export default memo(ButtonComp)
