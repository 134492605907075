import {
  decorate, observable, runInAction, action,
} from 'mobx'
import * as billingsApi from '../api/billingsApi'

export default class BillingsStore {
  constructor(rootStore) {
    this.rootStore = rootStore
    runInAction(() => {
      this.billing = {}
      this.inProgress = false
    })
  }

  clear() {
    this.billing = {}
  }

  async getAppBillingCount({ startDate, endDate, paid }) {
    runInAction(() => {
      this.inProgress = true
    })

    try {
      const res = await billingsApi.getBillingCountByPaid({ startDate, endDate, paids: [paid] })
      runInAction(() => {
        this.billing = res.data
      })
    } catch (ex) {
      this.rootStore.notificationStore.error({
        message: ex.message,
      })
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async getProjectBillingCount({ startDate, endDate, projectId }) {
    runInAction(() => {
      this.inProgress = true
    })

    try {
      const res = await billingsApi.getProjectBillingCount({ startDate, endDate, projectId })
      runInAction(() => {
        this.billing = res.data
      })
    } catch (ex) {
      this.rootStore.notificationStore.error({
        message: ex.message,
      })
    }
    runInAction(() => {
      this.inProgress = false
    })
  }
}

decorate(BillingsStore, {
  inProgress: observable,
  getProjectBillingCount: action,
  clear: action,
})
