import queryString from 'query-string'
import api from './api'

export async function getCompanies(query) {
  const { data: page } = await api.get(`/api/v1/companies?${queryString.stringify(query)}`)

  page.data = page.data.map((company) => {
    const data = { ...company }
    data.roleIds = JSON.parse(company.roleIds)

    return data
  })

  return page
}

export async function getCompanyById(id) {
  return api.get(`/api/v1/companies/${id}`)
}

export async function deleteCompany(companyId) {
  return api.delete(`/api/v1/companies/${companyId}`)
}

export async function createCompany(companyData) {
  return api.post('/api/v1/companies', companyData)
}

export async function updateCompany(company) {
  const { id, ...companyData } = company
  return api.patch(`/api/v1/companies/${id}`, companyData)
}
