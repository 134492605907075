/* eslint-disable no-console */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import './AssetDetails.scss'
import _ from 'lodash'
import { toJS } from 'mobx'
import {
  faEye, faPen, faTrashAlt, faArrowLeft,
} from '@fortawesome/free-solid-svg-icons'
import ReactTooltip from 'react-tooltip'
import { constants } from '../../../store/constants'
import PageTitle from '../../../components/PageTitle/PageTitle'
import Table from '../../../components/Table/Table'
import Button from '../../../components/Button/Button'
import Modal from '../../../components/Modal/Modal'
import Form from '../../../components/Form/Form'
import FormInput from '../../../components/Form/FormInput'
import FormDropdown from '../../../components/Form/FormDropdown'
import TemplatesDetails from './TemplatesDetails'
import MasterTemplatesDetails from './MasterTemplateDetails'

const NEW_PROJECT_APP = {
  applicationId: '',
}

const AssetDetails = ({
  tokensStore,
  applicationsStore,
  match,
  companiesStore,
  divisionsStore,
  projectsStore,
  campaignsStore,
  notificationStore,
  templatesStore,
  masterTemplateStore,
}) => {
  const [state, setState] = useState({
    campaignsForThisPaid: {},
    showCampaignTable: false,
    campaignOverviewModalVisible: false,
    campaignOverview: {},
    showCampaignFormModal: false,
    originalCampaignValue: {},
    latestCampaignValue: {},
    campaignFormError: [],
    showCampaignDeleteConfirmModal: false,
    showDialogModal: false,
    showDialogText: '',
    selectedApplication: { ...NEW_PROJECT_APP },
    permissions: {},
    breadcrumbs: [],

    // TEMPLATE RELATED
    templateCount: null,
    showTemplatesTable: false,

    masterTemplateCount: null,
    showMasterTemplatesTable: false,
  })

  useEffect(() => {
    const init = async () => {
      const fetchData = async () => {
        await projectsStore.getProjectApps({
          projectId: match.params.projectId,
        })
        const projectAppIds = _.map(projectsStore.projectApps, 'id')

        await tokensStore.getProjectTokens({ projectAppIds })
        await applicationsStore.getApplications({ limit: 100, deleted: false })
        await campaignsStore.getCampaigns()
        // console.log(toJS(campaignsStore.campaignPage))
        const campaignsForThisPaid = _.filter(campaignsStore.campaignPage, {
          paid: parseInt(match.params.paId, 10),
        })

        await templatesStore.getTemplates({ paid: match.params.paId })
        // console.log(toJS(templatesStore.templatePage))
        const templateCount = templatesStore.templatePage?.data ? templatesStore.templatePage?.data.length : 0

        await masterTemplateStore.getMasterTemplates({ paid: match.params.paId, activeDivisionId: 1, page:0, itemsPerPage: 9999 })
        const masterTemplateCount = masterTemplateStore.masterTemplates?.data ? masterTemplateStore.masterTemplates?.data.length : 0


        const { name: companyName } = await companiesStore.getCurrentCompany(
          match.params.companyId,
        )
        const { name: divisionName } = await divisionsStore.getCurrentDivision(
          match.params.divisionId,
        )
        const { name: projectsName } = await projectsStore.getCurrentProject(
          match.params.projectId,
        )

        const breadcrumbs = [
          {
            label: companyName,
            url: 'companies',
          },
          {
            label: divisionName,
            url: `companies/${match.params.companyId}/divisions`,
          },
          {
            label: projectsName,
            url: `companies/${match.params.companyId}/divisions/${match.params.divisionId}/projects`,
          },
          {
            label: 'Details',
            // eslint-disable-next-line max-len
            url:`companies/${match.params.companyId}/divisions/${match.params.divisionId}/projects/${match.params.projectId}/details`,
          },
        ]

        setState({
          ...state,
          breadcrumbs,
          campaignsForThisPaid,
          templateCount,
          masterTemplateCount,
        })
      }

      fetchData()
    }
    init()
    ReactTooltip.rebuild()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showTemplatesTable = () => {
    setState({
      ...state,
      showTemplatesTable: true,
    })
  }

  const showMasterTemplatesTable = () => {
    setState({
      ...state,
      showMasterTemplatesTable: true,
    })
  }

  // CAMPAIGN CONTROLS
  const openCampaignOverviewModal = async (currentCampaign) => {
    const creationTime = new Date(currentCampaign.createdAt)
    const updatedTime = new Date(currentCampaign.updatedAt)
    setState({
      ...state,
      showCampaignOverviewModal: true,
      campaignOverview: {
        ...currentCampaign,
        createdAt: creationTime.toLocaleTimeString('en-US'),
        createdOn: creationTime.toLocaleDateString('en-US'),
        updatedAt: currentCampaign.updatedAt ? updatedTime.toLocaleTimeString('en-US') : '',
        updatedOn: currentCampaign.updatedAt ? updatedTime.toLocaleDateString('en-US') : '',
      },
    })
  }

  const dismissCampaignOverviewModal = () => {
    // eslint-disable-next-line
    setState({ ...state, showCampaignOverviewModal: false })
  }

  const openCampaignFormModal = async (currentCampaign) => {
    let campaign = {}
    if (!currentCampaign.id) {
      campaign = {
        name: '',
        onGoing: true,
        status: 'PENDING',
        paid: match.params.paId, // this should be dynamic
      }
    } else {
      campaign = {
        id: currentCampaign.id,
        campaignId: currentCampaign.campaignId,
        name: currentCampaign.name,
        onGoing: (currentCampaign.onGoing === 'Yes') ? true : false,
        paid: currentCampaign.paid,
        status: currentCampaign.status,
      }
    }

    setState({
      ...state,
      showCampaignFormModal: true,
      originalCampaignValue: campaign,
      latestCampaignValue: campaign,
    })
  }

  const handleInputChange = (event) => {
    const { value, name } = event.target

    let campaignFormError = []
    if (name === 'name' && (value === '' || value === undefined)) {
      campaignFormError = ['This is Required']
    }

    setState({
      ...state,
      campaignFormError,
      latestCampaignValue: {
        ...state.latestCampaignValue,
        [name]: value,
      },
    })
  }

  const dismissCampaignFormModal = () => {
    // eslint-disable-next-line
    if (_.isEqual(state.originalCampaignValue, state.latestCampaignValue) || confirm('All unsaved changes will be discarded')) {
      setState({
        ...state, showCampaignFormModal: false, latestCampaignValue: {}, originalCampaignValue: {},
      })
    }
  }

  const changeOngoingFieldDisplay = async (campaigns) => {
    const updated = await campaigns.map((item) => {
      let newItem = {}
      if (item.onGoing == 1) {
        newItem = { ...item, onGoing: 'Yes' }
      } else {
        newItem = { ...item, onGoing: 'No' }
      }
      return newItem
    })
    return updated
  }

  const saveCampaignForm = async () => {
    const { id } = state.latestCampaignValue
    if (state.campaignFormError.length) {
      notificationStore.error({
        message: 'Campaign Name is Missing!',
      })
    } else {
      if (id) {
        await campaignsStore.updateCampaign(state.latestCampaignValue)
      } else {
        await campaignsStore.createCampaign(state.latestCampaignValue)
      }
      await campaignsStore.getCampaigns()
      let campaignsForThisPaid = _.filter(campaignsStore.campaignPage, {
        paid: parseInt(match.params.paId, 10),
      })

      campaignsForThisPaid = await changeOngoingFieldDisplay(campaignsForThisPaid)
      const rverseSortedList = _.sortBy(campaignsForThisPaid, [(o) => o.id]).reverse()
      setState({
        ...state,
        campaignsForThisPaid: rverseSortedList,
        showCampaignFormModal: false,
        originalCampaignValue: {},
        latestCampaignValue: {},
      })
    }
  }

  const confirmCampaignDelete = async (currentCampaign) => {
    // if (currentCampaign.onGoing === 'Yes') {
    //   setState({
    //     ...state,
    //     showDialogModal: true,
    //     showDialogText: 'Cannot remove an ongoing campaign',
    //   })
    // } else {
    setState({
      ...state,
      showCampaignDeleteConfirmModal: true,
      latestCampaignValue: { ...currentCampaign, onGoing: currentCampaign.onGoing === 'Yes' ? true : false },
    })
    // }
  }

  const deleteCampaign = async () => {
    await campaignsStore.deleteCampaign(state.latestCampaignValue)
    await campaignsStore.getCampaigns()
    let campaignsForThisPaid = _.filter(campaignsStore.campaignPage, {
      paid: parseInt(match.params.paId, 10),
    })

    campaignsForThisPaid = await changeOngoingFieldDisplay(campaignsForThisPaid)
    setState({
      ...state,
      campaignsForThisPaid,
      showCampaignDeleteConfirmModal: false,
    })
  }

  const showCampaignTable = async () => {
    await campaignsStore.getCampaigns()
    let campaignsForThisPaid = _.filter(campaignsStore.campaignPage, {
      paid: parseInt(match.params.paId, 10),
    })

    campaignsForThisPaid = await changeOngoingFieldDisplay(campaignsForThisPaid)
    const rverseSortedList = _.sortBy(campaignsForThisPaid, [(o) => o.id]).reverse()
    setState({
      ...state,
      campaignsForThisPaid: rverseSortedList,
      showCampaignTable: true,
    })
  }

  const campaignTableHeaders = [{
    label: '#',
    key: 'id',
    styles: {
      width: '50px',
      justifyContent: 'center',
    },
  }, {
    label: 'Project App ID',
    key: 'paid',
    styles: {
      flex: 1,
    },
  }, {
    label: 'Campaign ID',
    key: 'campaignId',
    styles: {
      flex: 2,
    },
  }, {
    label: 'Name',
    key: 'name',
    styles: {
      flex: 1,
    },
  }, {
    label: 'Ongoing',
    key: 'onGoing',
    styles: {
      flex: 1,
    },
  }, {
    label: 'Status',
    key: 'status',
    styles: {
      flex: 1,
    },
  }]

  return (
    <>
      <PageTitle title={`Assets (Project App ID: ${match.params.paId})`} breadcrumbs={state.breadcrumbs} />

      {!(state.showCampaignTable || state.showTemplatesTable || state.showMasterTemplatesTable) ? (
        <div className="box-container">
          <div className="banner-box campaign">
            <div className="index-number">{state.campaignsForThisPaid.length}</div>
            <div className="index-text">Campaigns</div>
            <div className="controls">
              <span className="control-button" style={{ marginLeft: '1rem' }} onClick={openCampaignFormModal}>New</span>
              <span className="control-button" style={{ marginRight: '1rem' }} onClick={showCampaignTable}>View</span>
            </div>
          </div>

          <div className="banner-box template">
            <div className="index-number">{state.templateCount}</div>
            <div className="index-text">Templates</div>
            <div className="controls">
              <span className="control-button" style={{ marginleft: '1rem' }} />
              <span className="control-button" style={{ marginRight: '1rem' }} onClick={showTemplatesTable}>View</span>
            </div>
          </div>

          <div className="banner-box template">
            <div className="index-number">{state.masterTemplateCount}</div>
            <div className="index-text">Master Templates</div>
            <div className="controls">
              <span className="control-button" style={{ marginleft: '1rem' }} />
              <span className="control-button" style={{ marginRight: '1rem' }} onClick={showMasterTemplatesTable}>View</span>
            </div>
          </div>
        </div>
      ) : ('') }

      {state.showCampaignTable ? (
        <div className="page-content">
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <Button
              // label="email"
              dataTip="Back To Dashboard"
              icon={faArrowLeft}
              variant="primary"
              onClick={() => {
                setState({
                  ...state,
                  showCampaignTable: !showCampaignTable,
                })
              }}
              customStyle={{ marginRight: 50 }}
            />
            <div style={{ color: '#425E99' }}>
              CAMPAIGNS FOR PAID: &nbsp;
              {match.params.paId}
            </div>
          </div>
          <Table
            headers={campaignTableHeaders}
            page={{ data: state.campaignsForThisPaid }}
            hidePaginator
          >
            <Button
              dataTip="Overview"
              variant="primary"
              icon={faEye}
              onClick={openCampaignOverviewModal}
            />
            <Button
              dataTip="Edit"
              variant="primary"
              icon={faPen}
              onClick={openCampaignFormModal}
            />
            <Button
              dataTip="Delete"
              variant="error"
              icon={faTrashAlt}
              onClick={confirmCampaignDelete}
              hidden={(self) => (self.status === 'RUNNING')}
            />
            <ReactTooltip />
          </Table>
        </div>
      ) : ('') }

      {state.showTemplatesTable ? (
        // eslint-disable-next-line max-len
        <TemplatesDetails paid={match.params.paId} backButton={() => { setState({ ...state, showTemplatesTable: false }) }} />
      ) : ('') }

      {state.showMasterTemplatesTable ? (
        // eslint-disable-next-line max-len
        <MasterTemplatesDetails paid={match.params.paId} backButton={() => { setState({ ...state, showMasterTemplatesTable: false }) }} />
      ) : ('') }

      <Modal
        show={state.showCampaignOverviewModal}
        onDismiss={dismissCampaignOverviewModal}
        header="Campaign Details"
        width="30%"
        noSubmit
      >
        <div style={{ marginLeft: '1rem' }}>
          <p>
            <span style={{ fontWeight: 'bold' }}>Id :</span>
            {state.campaignOverview.id}
          </p>
          <p>
            <span style={{ fontWeight: 'bold' }}>Campaign Id :</span>
            {state.campaignOverview.campaignId}
          </p>
          <p>
            <span style={{ fontWeight: 'bold' }}>Project App ID :</span>
            {state.campaignOverview.paid}
          </p>
          <p>
            <span style={{ fontWeight: 'bold' }}> Campaign Name :</span>
            {state.campaignOverview.name}
          </p>
          <p>
            <span style={{ fontWeight: 'bold' }}> Ongoing :</span>
            {state.campaignOverview.onGoing}
          </p>
          <p>
            <span style={{ fontWeight: 'bold' }}> Status :</span>
            {state.campaignOverview.status}
          </p>
          <p>
            <span style={{ fontWeight: 'bold' }}>Created On :</span>
            {state.campaignOverview.createdOn}
          </p>
          <p>
            <span style={{ fontWeight: 'bold' }}>Created At :</span>
            {state.campaignOverview.createdAt}
          </p>
          <p>
            <span style={{ fontWeight: 'bold' }}> Report :</span>
            {state.campaignOverview.report}
          </p>
          <p>
            <span style={{ fontWeight: 'bold' }}> Latest :</span>
            {state.campaignOverview.latest}
          </p>
          <p>
            <span style={{ fontWeight: 'bold' }}>Report Last Updated :</span>
            {state.campaignOverview.reportLastUpdated}
          </p>
          <p>
            <span style={{ fontWeight: 'bold' }}>List Ids :</span>
            {state.campaignOverview.listIds}
          </p>
          <p>
            <span style={{ fontWeight: 'bold' }}>Updated By :</span>
            {state.campaignOverview.updateBy}
          </p>
          <p>
            <span style={{ fontWeight: 'bold' }}>Updated On :</span>
            {state.campaignOverview.updatedOn}
          </p>
          <p>
            <span style={{ fontWeight: 'bold' }}>Updated At :</span>
            {state.campaignOverview.updatedAt}
          </p>
        </div>
      </Modal>

      <Modal
        show={state.showCampaignFormModal}
        onDismiss={dismissCampaignFormModal}
        onSubmit={saveCampaignForm}
        header="Add/Update Campaign"
        width="30%"
      >
        <Form>
          <div>
            <FormInput
              name="paid"
              label="Project App ID"
              type="text"
              value={state.latestCampaignValue.paid}
              disabled
            />
            <FormInput
              name="name"
              label="Campaign Name"
              type="text"
              value={state.latestCampaignValue.name}
              errors={state.campaignFormError}
              onChange={handleInputChange}
              required
              maxLength={255}
            />
            <FormDropdown
              name="status"
              label="Status"
              options={['PENDING', 'IN PROGRESS', 'COMPLETE'].map((item) => ({
                value: item,
                label: item,
              }))}
              value={state.latestCampaignValue.status}
              onChange={handleInputChange}
              disabled={state.latestCampaignValue.id ? false : true}
            />
            <FormDropdown
              name="onGoing"
              label="On Going"
              options={['YES', 'NO'].map((item) => ({
                value: item === 'YES' ? true : false,
                label: item,
              }))}
              value={state.latestCampaignValue.onGoing}
              onChange={handleInputChange}
              disabled={state.latestCampaignValue.id ? false : true}
            />
          </div>
        </Form>
      </Modal>

      <Modal
        show={state.showCampaignDeleteConfirmModal}
        onDismiss={() => { setState({ ...state, showCampaignDeleteConfirmModal: false }) }}
        onSubmit={deleteCampaign}
        submitButtonVariant="error"
        submitLabel="Delete"
        header="Confirm Delete"
        width="30%"
      >
        <div>
          Are you sure you want to remove the campaign &nbsp;
          <span style={{ fontWeight: 'bold' }}>{state.latestCampaignValue.name}</span>
          ?
        </div>
      </Modal>

      <Modal
        show={state.showDialogModal}
        onDismiss={() => { setState({ ...state, showDialogModal: false }) }}
        noSubmit
        customCanceltext="OK"
        header="Alert"
        width="30%"
      >
        <div>
          {state.showDialogText}
        </div>
      </Modal>

    </>
  )
}

AssetDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      divisionId: PropTypes.string,
      companyId: PropTypes.string,
      projectId: PropTypes.string,
      paId: PropTypes.string,
    }),
  }).isRequired,
}

export default inject(
  constants.divisionsStore,
  constants.companiesStore,
  constants.projectsStore,
  constants.tokensStore,
  constants.applicationsStore,
  constants.vendorsStore,
  constants.voicesStore,
  constants.notificationStore,
  constants.voiceAssetsStore,
  constants.billingsStore,
  constants.campaignsStore,
  constants.templatesStore,
  constants.masterTemplateStore,
)(observer(AssetDetails))
