import {
  decorate, observable, runInAction, action,
} from 'mobx'
import * as companiesApi from '../api/companiesApi'

export default class CompaniesStore {
  constructor(rootStore) {
    this.rootStore = rootStore

    runInAction(() => {
      this.inProgress = false
      this.companiesPage = {}
      this.currentCompanyScope = {}
    })
  }

  clear() {
    this.companiesPage = {}
  }

  setCurrentCompany(company) {
    this.currentCompanyScope = company
  }

  async getCurrentCompany(companyId) {
    if (this.currentCompanyScope.id !== parseInt(companyId, 10)) {
      await this.getCompanyById(companyId)
    }

    return this.currentCompanyScope
  }

  async getCompanies(query = {}) {
    this.inProgress = true
    try {
      const data = await companiesApi.getCompanies(query)
      runInAction(() => {
        this.companiesPage = data
      })
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async getCompanyById(id) {
    this.inProgress = true
    try {
      const res = await companiesApi.getCompanyById(id)
      runInAction(() => {
        this.currentCompanyScope = res.data
      })
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async deleteCompany(companyId) {
    this.inProgress = true
    try {
      await companiesApi.deleteCompany(companyId)
      this.rootStore.notificationStore.success({ message: 'Company deleted' })
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async createCompany(companyData) {
    this.inProgress = true
    try {
      await companiesApi.createCompany(companyData)
      this.rootStore.notificationStore.success({ message: 'Company created' })
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async updateCompany(companyData) {
    this.inProgress = true
    try {
      await companiesApi.updateCompany(companyData)
      this.rootStore.notificationStore.success({ message: 'Company updated' })
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }
}

decorate(CompaniesStore, {
  inProgress: observable,
  companiesPage: observable,
  currentCompanyScope: observable,
  updateCompany: action,
  createCompany: action,
  deleteCompany: action,
  getCompanies: action,
  getCurrentCompany: action,
  getCompanyById: action,
  setCurrentCompany: action,
  clear: action,
})
