import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { constants } from '../../../store/constants'
import Form from '../../../components/Form/Form'
import FormInput from '../../../components/Form/FormInput'
import Panel from '../../../components/Panel/Panel'
import Button from '../../../components/Button/Button'

const PasswordReset = ({ authStore }) => {
  const defaultStateValues = {
    email: '',
    emailConfirm: '',
    passwordResetInitiated: false,
    formErrors: {
      disableOnLoad: [1],
    },
  }

  const [state, setState] = useState(defaultStateValues)

  const validateEmail = (email) => {
    const errors = []

    if (!email.length) {
      errors.push('Email is required')
    }

    return errors
  }

  const validateEmailConfirm = (email, emailConfirm) => {
    const errors = []

    if (emailConfirm !== email) {
      errors.push('Emails must match')
    }

    return errors
  }

  const validate = ({ email, emailConfirm }) => ({
    email: validateEmail(email),
    emailConfirm: validateEmailConfirm(email, emailConfirm),
  })

  const handleChange = (event) => {
    const { name, value } = event.target
    const updatedState = {
      ...state,
      [name]: value,
    }
    updatedState.formErrors = validate({
      email: updatedState.email,
      emailConfirm: updatedState.emailConfirm,
    })
    setState(updatedState)
  }

  const onSubmit = async () => {
    const { email, emailConfirm } = state
    if (email !== emailConfirm) {
      setState({ ...state, notMatchingEmails: true })
    } else {
      try {
        await authStore.initiatePasswordReset(email)
        setState({ ...state, passwordResetInitiated: true })
      } catch (err) {
        setState({ ...state, isDisabled: false, passwordResetInitiated: false })
      }
    }
  }

  const emailFormPanel = (
    <Panel header="Enter your email to reset your email!">
      <Form>
        <FormInput
          name="email"
          type="email"
          label="User Email"
          onChange={handleChange}
          errors={state.formErrors.email}
          value={state.email}
        />
        <FormInput
          name="emailConfirm"
          type="email"
          label="Confirm your user email"
          onChange={handleChange}
          errors={state.formErrors.emailConfirm}
          value={state.emailConfirm}
        />
        <Button
          label="Send Password Reset Request"
          onClick={onSubmit}
          variant="primary"
          classNames={['full-width']}
          disabled={Object.keys(state.formErrors).some((k) => state.formErrors[k].length)}
          isLoading={authStore.inProgress}
        />
      </Form>
    </Panel>
  )

  const passwordResetInitiatedPanel = (
    <Panel header="Password Reset Request Sent!">
      <p> Please check your inbox to complete your password reset workflow! </p>
    </Panel>
  )

  return (
    <div className="full-size-container flex-center flex-column">
      {state.passwordResetInitiated ? (passwordResetInitiatedPanel) : (emailFormPanel)}
      <img style={{ width: '300px', marginTop: '2rem' }} alt="logo" src="/assets/logo.png" />
    </div>
  )
}

PasswordReset.propTypes = {
  authStore: PropTypes.shape({
    inProgress: PropTypes.bool,
    initiatePasswordReset: PropTypes.func,
  }).isRequired,
}

export default inject(constants.authStore)(observer(PasswordReset))
