import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import DropZone from './Dropzone'

const FileComponent = ({ children }) => (
  <div>{children}</div>
)

const FileDropBox = memo(({
  className, onDrop, fileSize, children,
}) => {
  const styleClass = classNames(className, 'file-dropbox')
  const [file, setFile] = useState(null)

  const onFileChange = async (selectedFile) => {
    if (selectedFile[0].size > fileSize) {
      // eslint-disable-next-line no-alert
      window.alert('Limit File size to 1MB!')
    } else {
      await setFile(selectedFile[0])
      onDrop(selectedFile[0])
    }
  }

  return (
    <div className={styleClass}>
      {!file ? <DropZone onSelect={onFileChange} /> : (
        <div>
          {file.name}
          {children}
        </div>
      )}
    </div>
  )
})

FileDropBox.propTypes = {
  className: PropTypes.string,
  onDrop: PropTypes.func.isRequired,
  fileSize: PropTypes.number,
}

FileDropBox.defaultProps = {
  className: '',
  fileSize: 5242880,
}

export default FileComponent

export { FileDropBox }
