import queryString from 'query-string'
import api from './api'

export async function getUnsubscribeDetails(query) {
  const resp = await api.get(`/api/v1/email-unsubscribe?${queryString.stringify(query)}`)

  return resp.data.map((unsubscribeDetails) => {
    const data = { ...unsubscribeDetails }

    return data
  })
}

export async function unsubscribe(query) {
  const resp = await api.post(`/api/v1/email-unsubscribe?${queryString.stringify(query)}`)
  return resp.data
}
