import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import {
  faPlus, faTrashAlt, faTimes, faCheck,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { inject, observer } from 'mobx-react'
import _ from 'lodash'
import ReactTooltip from 'react-tooltip'
import { PermissionContext } from '../../../PermissionContext'
import { constants } from '../../../store/constants'
import Button from '../../../components/Button/Button'
import FloatingButton from '../../../components/FloatingButton/FloatingButton'
import Table from '../../../components/Table/Table'
import Modal from '../../../components/Modal/Modal'
import Form from '../../../components/Form/Form'
import FormInput from '../../../components/Form/FormInput'
import Checkbox from '../../../components/Form/Checkbox'
import FormGroup from '../../../components/Form/FormGroup'
import PageTitle from '../../../components/PageTitle/PageTitle'
import * as validators from '../../../validators'
import './applications.scss'

const NEW_APPLICATION = {
  name: '',
  address: '',
  email: false,
  sms: false,
  voice: false,
  eons: false,
  cpp: false,
  logic: false,
  url: false,
  unsubscribe: false,
  custom: false,
}

const Applications = ({ applicationsStore, history }) => {
  const permissions = useContext(PermissionContext)

  useEffect(() => {
    const isAllowed = permissions.isAllowed('companies.list')

    if (!isAllowed) {
      history.push('/home')
    }

    applicationsStore.getApplications()

    return function cleanup() {
      applicationsStore.clear()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const defaultStateValues = {
    originalValue: {},
    formErrors: {},
    selectedApplication: { ...NEW_APPLICATION },
    manageApplicationModalVisible: false,
    removeApplicationModalVisible: false,
  }

  const [state, setState] = useState(defaultStateValues)

  const validate = ({
    name, address, email, sms, voice, eons, cpp, logic, url, unsubscribe, custom,
  }) => ({
    name: validators.validate({
      value: name,
      label: 'name',
      validators: [
        validators.minLength(2),
      ],
    }),
    address: validators.validate({
      value: address,
      label: 'address',
      validators: [
        validators.required,
      ],
    }),
    services: validators.validate({
      validators: [
        () => !(email || sms || voice || eons || cpp || logic || url || unsubscribe || custom),
      ],
    }),
  })

  const openCreateApplicationModal = () => {
    setState({
      ...state,
      originalValue: { ...NEW_APPLICATION },
      selectedApplication: { ...NEW_APPLICATION },
      manageApplicationModalVisible: true,
      formErrors: validate(NEW_APPLICATION),
    })
  }

  const openRemoveApplicationModal = (application) => {
    setState({
      ...state,
      selectedApplication: application,
      removeApplicationModalVisible: true,
    })
  }

  const dismissManageApplicationModal = () => {
    if (!_.isEqual(state.originalValue, state.selectedApplication)) {
      // eslint-disable-next-line
      if (confirm('All unsaved changes will be discarded')) {
        setState({ ...state, manageApplicationModalVisible: false })
      }
    } else {
      setState({ ...state, manageApplicationModalVisible: false })
    }
  }

  const dismissRemoveApplicationModal = () => {
    setState({ ...state, removeApplicationModalVisible: false })
  }

  const submitApplication = async () => {
    if (state.selectedApplication.id) {
      const {
        id, name, email, sms, voice, eons, address, cpp, logic,
      } = state.selectedApplication
      await applicationsStore.updateApplication({
        id, name, email, sms, voice, eons, address, cpp, logic,
      })
    } else {
      await applicationsStore.createApplication(state.selectedApplication)
    }

    const { limit, offset } = applicationsStore.applicationsPage
    await applicationsStore.getApplications({ limit, offset })
    setState({ ...state, manageApplicationModalVisible: false })
  }

  const deleteApplication = async () => {
    await applicationsStore.deleteApplication(state.selectedApplication.id)

    const { limit, offset } = applicationsStore.applicationsPage
    await applicationsStore.getApplications({ limit, offset })
    setState({ ...state, removeApplicationModalVisible: false })
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    const application = {
      ...state.selectedApplication,
      [name]: value,
    }
    setState({ ...state, selectedApplication: application, formErrors: validate(application) })
  }

  const handlePagination = (query) => {
    applicationsStore.getApplications(query)
  }

  const tableHeaders = [{
    label: '#',
    key: 'id',
    styles: {
      width: '50px',
      justifyContent: 'center',
    },
  },
  {
    label: 'Name',
    key: 'name',
    styles: {
      flex: 1,
    },
  },
  {
    label: 'Address',
    key: 'address',
    styles: {
      flex: 1,
    },
  },
  {
    label: 'Email service',
    styles: {
      width: '8%',
      wordBreak: 'initial',
      justifyContent: 'center',
      textAlign: 'center',
    },
    render: (item) => (
      <FontAwesomeIcon icon={item.email ? faCheck : faTimes} className={item.email ? 'icon-success' : 'icon-error'} />
    ),
  },
  {
    label: 'SMS service',
    styles: {
      width: '8%',
      wordBreak: 'initial',
      justifyContent: 'center',
      textAlign: 'center',
    },
    render: (item) => (
      <FontAwesomeIcon icon={item.sms ? faCheck : faTimes} className={item.sms ? 'icon-success' : 'icon-error'} />
    ),
  },
  {
    label: 'Voice service',
    styles: {
      width: '8%',
      wordBreak: 'initial',
      justifyContent: 'center',
      textAlign: 'center',
    },
    render: (item) => (
      <FontAwesomeIcon icon={item.voice ? faCheck : faTimes} className={item.voice ? 'icon-success' : 'icon-error'} />
    ),
  },
  {
    label: 'EONS',
    styles: {
      width: '8%',
      wordBreak: 'initial',
      justifyContent: 'center',
      textAlign: 'center',
    },
    render: (item) => (
      <FontAwesomeIcon icon={item.eons ? faCheck : faTimes} className={item.eons ? 'icon-success' : 'icon-error'} />
    ),
  },
  {
    label: 'CPP',
    styles: {
      width: '8%',
      wordBreak: 'initial',
      justifyContent: 'center',
      textAlign: 'center',
    },
    render: (item) => (
      <FontAwesomeIcon icon={item.cpp ? faCheck : faTimes} className={item.cpp ? 'icon-success' : 'icon-error'} />
    ),
  }, {
    label: 'LOGIC',
    styles: {
      width: '8%',
      wordBreak: 'initial',
      justifyContent: 'center',
      textAlign: 'center',
    },
    render: (item) => (
      <FontAwesomeIcon icon={item.logic ? faCheck : faTimes} className={item.logic ? 'icon-success' : 'icon-error'} />
    ),
  }, {
    label: 'URL',
    styles: {
      width: '5%',
      wordBreak: 'initial',
      justifyContent: 'center',
      textAlign: 'center',
    },
    render: (item) => (
      <FontAwesomeIcon icon={item.url ? faCheck : faTimes} className={item.url ? 'icon-success' : 'icon-error'} />
    ),
  },
  {
    label: 'Unsubscribe',
    styles: {
      width: '8%',
      wordBreak: 'initial',
      justifyContent: 'center',
      textAlign: 'center',
    },
    render: (item) => (
      <FontAwesomeIcon
        icon={item.unsubscribe ? faCheck : faTimes}
        className={item.unsubscribe ? 'icon-success' : 'icon-error'}
      />
    ),
  },
  {
    label: 'Custom',
    styles: {
      width: '5%',
      wordBreak: 'initial',
      justifyContent: 'center',
      textAlign: 'center',
    },
    render: (item) => (
      <FontAwesomeIcon icon={item.custom ? faCheck : faTimes} className={item.custom ? 'icon-success' : 'icon-error'} />
    ),
  },
  ]

  return (
    <>
      <PageTitle title="Applications" />
      <div className="page-content">
        <FloatingButton
          requiredPermission="applications.create"
          icon={faPlus}
          onClick={openCreateApplicationModal}
          dataTip="Add Application"
        />
        <Table
          isLoading={applicationsStore.inProgress}
          headers={tableHeaders}
          page={applicationsStore.applicationsPage}
          onPageChange={handlePagination}
        >
          {/* <Button
            requiredPermission="applications.update"
            icon={faEdit}
            variant="primary"
            onClick={openEditApplicationModal}
            // disabled // disabled isntead of hide?
          /> */}
          <Button
            requiredPermission="applications.delete"
            icon={faTrashAlt}
            variant="error"
            onClick={openRemoveApplicationModal}
          />
        </Table>
        <ReactTooltip />
      </div>

      <Modal
        show={state.manageApplicationModalVisible}
        header={state.selectedApplication.id ? 'Edit application' : 'Add application'}
        submitLabel={state.selectedApplication.id ? 'Save' : 'Create'}
        onSubmit={submitApplication}
        onDismiss={dismissManageApplicationModal}
        isLoading={applicationsStore.inProgress}
        isDisabled={Object.keys(state.formErrors).some((k) => state.formErrors[k].length)}
        width="60%"
      >
        <Form>
          <FormInput
            name="name"
            label="Name"
            value={state.selectedApplication.name}
            onChange={handleChange}
            errors={state.formErrors.name}
            required
          />
          <FormInput
            name="address"
            label="Address"
            value={state.selectedApplication.address}
            errors={state.formErrors.address}
            onChange={handleChange}
            required
          />

          <FormGroup label="Services">
            <div className="flex checkboxes">
              <Checkbox name="email" label="Email" value={state.selectedApplication.email} onChange={handleChange} />
              <Checkbox name="sms" label="Sms" value={state.selectedApplication.sms} onChange={handleChange} />
              <Checkbox name="voice" label="Voice" value={state.selectedApplication.voice} onChange={handleChange} />
              <Checkbox name="eons" label="EONS" value={state.selectedApplication.eons} onChange={handleChange} />
              <Checkbox name="cpp" label="CPP" value={state.selectedApplication.cpp} onChange={handleChange} />
              <Checkbox name="logic" label="LOGIC" value={state.selectedApplication.logic} onChange={handleChange} />
              <Checkbox name="url" label="URL" value={state.selectedApplication.url} onChange={handleChange} />
              <Checkbox
                name="unsubscribe"
                label="UNSUBSCRIBE"
                value={state.selectedApplication.unsubscribe}
                onChange={handleChange}
              />
              <Checkbox name="custom" label="Custom" value={state.selectedApplication.custom} onChange={handleChange} />
            </div>
            {state.formErrors.services && state.formErrors.services.length
              ? (
                <div className="text-small text-error">
                  At least one service is required
                </div>
              )
              : ''}
          </FormGroup>
        </Form>
      </Modal>

      <Modal
        show={state.removeApplicationModalVisible}
        onDismiss={dismissRemoveApplicationModal}
        onSubmit={deleteApplication}
        isLoading={applicationsStore.inProgress}
        submitButtonVariant="error"
        submitLabel="Remove"
        header="Confirm removal"
        width="60%"
      >
        <div>Are you sure you want to remove the selected application?</div>
      </Modal>
    </>
  )
}

Applications.propTypes = {
  applicationsStore: PropTypes.shape({
    applicationsPage: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape()),
      limit: PropTypes.number,
      offset: PropTypes.number,
      total: PropTypes.number,
    }),
    inProgress: PropTypes.bool,
    getApplications: PropTypes.func,
    deleteApplication: PropTypes.func,
    createApplication: PropTypes.func,
    updateApplication: PropTypes.func,
    clear: PropTypes.func,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
}

export default inject(constants.applicationsStore)(observer(Applications))
