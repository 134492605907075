import axios from 'axios'
import * as config from '../config'
import {
  LOGIN_PATH, PASSWORD_FORGET_PATH, PASSWORD_RESET_PATH, UNSUBSCRIBE_PATH,
} from '../constants'

const isAuthorizedPath = (path) => {
  const regexStr = `${LOGIN_PATH}|${PASSWORD_RESET_PATH}|${PASSWORD_FORGET_PATH}|${UNSUBSCRIBE_PATH}`
  const result = path.search(new RegExp(regexStr, 'g'))
  return (result === -1)
}

const api = axios.create({
  baseURL: config.WEB_API_URL,
  timeout: 500000,
  withCredentials: true,
})

api.interceptors.response.use((res) => res, (err) => {
  if (err && err.response && err.response.status === 401 && isAuthorizedPath(window.location.href)) {
    setTimeout(() => {
      window.sessionStorage.setItem('url', window.location.pathname)
      window.sessionStorage.setItem('message', 'Your Session has Expired, Please Login again!')
      window.location.reload()
    }, 200)
  }
  return Promise.reject(err)
})

export default api

export async function getMe() {
  return api.get('/api/v1/users/me')
}

export async function login(email, password) {
  return api.post('/api/v1/users/login', { email, password })
}

export async function logout() {
  return api.post('/api/v1/users/logout')
}

export async function validateResetToken(token) {
  return api.post('/api/v1/users/forgot-password/verify', {
    code: token,
  })
}

export async function resetPassword(password, code, id) {
  return api.patch(`/api/v1/users/${id}/password`, { password, code })
}

export async function initiatePasswordReset(email) {
  return api.post('/api/v1/users/forgot-password', { email })
}
