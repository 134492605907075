import api from './api'

export async function createProjectVendor(projectVendorData) {
  return api.post('/api/v1/project-vendors', projectVendorData)
}

export async function updateProjectVendor(projectVendorId, projectVendorData) {
  return api.patch(`/api/v1/project-vendors/${projectVendorId}`, projectVendorData)
}

export async function getProjectVendorByTypeAndId(messageType, id) {
  return api.post(`/api/v1/project-vendors?messageType=${messageType}&projectAppId=${id}`)
}
