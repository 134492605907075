/* eslint-disable no-console */
import React, { useEffect, useState } from "react"
// import PropTypes from 'prop-types'
import { inject, observer } from "mobx-react"
import "./AssetDetails.scss"
import _ from "lodash"
import { toJS } from "mobx"
import {
  faEye,
  faPen,
  faTrashAlt,
  faArrowLeft,
  faLock,
  faLockOpen,
  faFolderPlus,
} from "@fortawesome/free-solid-svg-icons"
import ReactTooltip from "react-tooltip"
import { constants } from "../../../store/constants"
// import PageTitle from '../../../components/PageTitle/PageTitle'
import Table from "../../../components/Table/Table"
import Button from "../../../components/Button/Button"
import Modal from "../../../components/Modal/Modal"

const templatesIds = [
  "emailTemplateId",
  "smsTemplateId",
  "voiceTemplateId",
  "ttyTemplateId",
  "inboundTemplateId",
  "customTemplateId",
]

const MasterTemplateDetails = ({
  notificationStore,
  masterTemplateStore,
  templatesStore,
  paid,
  backButton,
}) => {
  const [state, setState] = useState({
    // eslint-disable-next-line react/destructuring-assignment
    paid,
    templateData: {},
    showTemplateFormModal: false,
    originalTemplateValue: {},
    latestTemplateValue: {},
    showTemplateOverviewModal: false,
    templateDetail: {},
    pageData: {},
    masterTemplateData: []
  })

  useEffect(() => {
    const init = async () => {
      const fetchData = async () => {
        await masterTemplateStore.getMasterTemplates({ paid: state.paid, activeDivisionId: 1, page:0, itemsPerPage: 999 })
        
        const limit = 20
        const offset = 0
        const data = Object.assign([], masterTemplateStore.masterTemplates.data)
        const newData = data?.splice(limit*offset, limit)

        const pageData = {
          limit,
          offset,
          paid: state.paid,
          sort: "",
          total: masterTemplateStore.masterTemplates.totalCount
        }

        setState({
          ...state,
          templateData: masterTemplateStore.masterTemplates.data,
          pageData,
          masterTemplateData: newData
        })
      }

      fetchData()
    }
    init()
    ReactTooltip.rebuild()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const changeStatus = async (item) => {
    const masterTemplateData = {
      masterTemplateId: item._id,
      status: item.status !== 'LOCKED' ? "LOCKED" : "COMPLETED",
    }

    await masterTemplateStore.lockUnlockTemplate(masterTemplateData)
    await masterTemplateStore.getMasterTemplates({ paid: state.paid, activeDivisionId: 1, page:0, itemsPerPage: 9999 })

    const {limit, offset} = state.pageData
    const data = Object.assign([], masterTemplateStore.masterTemplates.data)
    const newData = data?.splice(offset, limit)
    
    const pageData = {
      limit,
      offset,
      paid: state.paid,
      sort: "",
      total: masterTemplateStore.masterTemplates.totalCount
    }

    const templateRequests =  []

    templatesIds.map((templateId) => {
      if(item[templateId]) {
        const templateData = {
          id: item[templateId],
          paid: item.paid,
          isLocked: masterTemplateData.status === 'LOCKED' ? true : false,
        }

        const req = templatesStore.lockUnlockTemplate(templateData, false)
        templateRequests.push(req)
      }
    })

    await Promise.all(templateRequests)

    setState({
      ...state,
      originalTemplateValue: {},
      latestTemplateValue: {},
      templateData: masterTemplateStore.masterTemplates.data,
      pageData,
      masterTemplateData: newData
    })
  }

  const openTemplateOverviewModal = async (currentTemplate) => {
    setState({
      ...state,
      showTemplateOverviewModal: true,
      templateDetail: currentTemplate,
    })
  }

  const templatesTableHeaders = [
    {
      label: "_id",
      key: "_id",
      styles: {
        width: "200px",
        justifyContent: "center",
      },
    },
    {
      label: "Project App ID",
      key: "paid",
      styles: {
        flex: 1,
      },
    },
    {
      label: "Name",
      key: "name",
      styles: {
        flex: 1,
      },
    },
    {
      label: "Lock Status",
      key: "status",
      styles: {
        flex: 1,
      },
      render: (item) => (
        <div className="config-buttons">
          <Button
            // label="email"
            dataTip={
              item.status ==="LOCKED" ? "Unlock this Template" : "Lock this Template"
            } 
            icon={item.status ==="LOCKED" ? faLock : faLockOpen}
            // disabled={item.isLocked}
            variant={item.status==="LOCKED" ? "error" : "primary"}
            // onClick={() => { openTemplateFormModal(item) }}
            onClick={() => {
              changeStatus(item)
            }}
            customStyle={{ marginRight: 50 }}
          />
          <ReactTooltip />
        </div>
      ),
    },
  ]

  const goBack = () => {
    backButton()
  }

  const handlePageChange = (query) => {
    const {limit, offset} = query
    const data = Object.assign([], masterTemplateStore.masterTemplates.data)
    const newData = data?.splice(offset, limit)
    
    const pageData = {
      limit,
      offset,
      paid: state.paid,
      sort: "",
      total: masterTemplateStore.masterTemplates.totalCount
    }

    setState({
      ...state,
      pageData,
      masterTemplateData: newData
    })
  }

  return (
    <>
      <div className="page-content">
        <div style={{ display: "flex", alignItems: "baseline" }}>
          <Button
            // label="email"
            dataTip="Back To Dashboard"
            icon={faArrowLeft}
            variant="primary"
            onClick={goBack}
            customStyle={{ marginRight: 50 }}
          />
          <div style={{ color: "#425E99" }}>
            MASTER TEMPLATES FOR PAID:
            {state.paid}
          </div>
        </div>
        <Table
          headers={templatesTableHeaders}
          // page={{ data: state.templateData }}
          page={{
            ...state.pageData,
            data: state.masterTemplateData
          }}
          // hidePaginator
          onPageChange={handlePageChange}
        >
          <Button
            dataTip="Overview"
            variant="primary"
            icon={faEye}
            onClick={openTemplateOverviewModal}
          />
          {/* <Button
            dataTip="Edit"
            variant="primary"
            icon={faPen}
            onClick={openTemplateFormModal}
          /> */}
          <ReactTooltip />
        </Table>
      </div>

      <Modal
        show={state.showTemplateOverviewModal}
        onDismiss={() => {
          setState({ ...state, showTemplateOverviewModal: false })
        }}
        noSubmit
        header="Template Detail"
        width="30%"
      >
        <div style={{ wordWrap: "break-word" }}>
          <pre>{JSON.stringify(state.templateDetail, null, 4)}</pre>
        </div>
      </Modal>
    </>
  )
}

export default inject(
  constants.notificationStore,
  constants.masterTemplateStore,
  constants.templatesStore
)(observer(MasterTemplateDetails))
