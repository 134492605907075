import queryString from 'query-string'
import api from './api'

export async function getUsers(query) {
  return api.get(`/api/v1/users?${queryString.stringify(query)}`)
}

export async function deleteUser(userId) {
  return api.delete(`/api/v1/users/${userId}`)
}

export async function createUser(userData) {
  return api.post('/api/v1/users', userData)
}

export async function updateUser(user) {
  const { id, ...userData } = user
  return api.patch(`/api/v1/users/${id}`, userData)
}

export async function resendUserActivation(userId) {
  return api.post(`/api/v1/users/${userId}/resend-activation`)
}

export async function forgotPassword(userId, email) {
  return api.post(`/api/v1/users/${userId}/forgot-password`, { email })
}

export async function reactivateDeletedUser(user) {
  const { id, ...userData } = user
  return api.post(`/api/v1/users/${id}/reactivateDeletedUser`, userData)
}
