import React, { memo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import './checkbox.scss'

const CheckboxComponent = ({
  label, name, onChange, value, removeSpacing, disabled, className, ...rest
}) => {
  const handleChange = (event) => {
    onChange({
      target: {
        name,
        value: event.target.checked,
      },
    })
  }

  const formGroupClass = classNames(className, 'form-group checkbox', { 'no-spacing': removeSpacing })

  return (
    <div className={formGroupClass}>
      <input
        {...rest}
        disabled={disabled}
        type="checkbox"
        checked={value}
        id={name}
        name={name}
        onChange={handleChange}
      />
      <label className="form-label" htmlFor={name}>
        {label}
      </label>
    </div>
  )
}

CheckboxComponent.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  removeSpacing: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]).isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
}

CheckboxComponent.defaultProps = {
  removeSpacing: false,
  disabled: false,
  className: '',
}

export default memo(CheckboxComponent)
