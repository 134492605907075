import React from 'react'
import './index.scss'

const VersioningTag = () => (
  <div>
    <small className="versioning">
      v
      {process.env.REACT_APP_VERSION}
    </small>
  </div>
)

export default VersioningTag