import queryString from 'query-string'
import api from './api'

export async function getCampaigns(query) {
  return api.get(`/api/v1/reports/campaign?${queryString.stringify(query)}`)
}

export async function createCampaign(campaignData) {
  return api.post('/api/v1/reports/campaign', campaignData)
}

export async function updateCampaign(campaign) {
  const { paid } = campaign
  return api.patch(`/api/v1/reports/campaign?paid=${paid}`, campaign)
}

export async function deleteCampaign(campaign) {
  const { campaignId, onGoing } = campaign
  return api.delete(`/api/v1/reports/campaign/${campaignId}?onGoing=${onGoing}`, campaign)
}
