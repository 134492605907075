import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { faPlus, faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { inject, observer } from 'mobx-react'
import _ from 'lodash'
import ReactTooltip from 'react-tooltip'
import { PermissionContext } from '../../../PermissionContext'
import { constants } from '../../../store/constants'
import Button from '../../../components/Button/Button'
import FloatingButton from '../../../components/FloatingButton/FloatingButton'
import Table from '../../../components/Table/Table'
import Modal from '../../../components/Modal/Modal'
import Form from '../../../components/Form/Form'
import FormInput from '../../../components/Form/FormInput'
import PageTitle from '../../../components/PageTitle/PageTitle'
import * as validators from '../../../validators'
import FormGroup from '../../../components/Form/FormGroup'
import Checkbox from '../../../components/Form/Checkbox'
import './companies.scss'
const NEW_COMPANY = {
  name: '',
  roleIds: [],
}

const tableHeaders = [{
  label: '#',
  key: 'id',
  styles: {
    width: '50px',
    justifyContent: 'center',
  },
}, {
  label: 'Name',
  key: 'name',
  styles: {
    flex: 1,
  },
}]

const Companies = (
  {
    rolesStore, companiesStore, authStore, history,
  }
) => {
  const permissions = useContext(PermissionContext)

  useEffect(() => {
    const isAllowed = permissions.isAllowed('companies.list')

    if (!isAllowed) {
      history.push('/home')
    }

    companiesStore.getCompanies()

    const rolesAllowed = permissions.isAllowed('roles.list')
    if (rolesAllowed) {
      rolesStore.getRoles()
    }

    return function cleanup() {
      companiesStore.clear()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const defaultStateValues = {
    originalValue: {},
    formErrors: {},
    selectedCompany: { ...NEW_COMPANY },
    manageCompanyModalVisible: false,
    removeCompanyModalVisible: false,
  }

  const [state, setState] = useState(defaultStateValues)

  const validate = ({ name }) => ({
    name: validators.validate({
      value: name,
      label: 'company name',
      validators: [
        validators.minLength(2),
      ],
    }),
  })

  const openEditCompanyModal = (company) => {
    setState({
      ...state,
      originalValue: company,
      selectedCompany: company,
      manageCompanyModalVisible: true,
    })
  }

  const openCreateCompanyModal = () => {
    setState({
      ...state,
      originalValue: { ...NEW_COMPANY },
      selectedCompany: { ...NEW_COMPANY },
      manageCompanyModalVisible: true,
      formErrors: validate(NEW_COMPANY),
    })
  }

  const openRemoveCompanyModal = (company) => {
    setState({
      ...state,
      selectedCompany: company,
      removeCompanyModalVisible: true,
    })
  }

  const dismissManageCompanyModal = () => {
    if (!_.isEqual(state.originalValue, state.selectedCompany)) {
      // eslint-disable-next-line
      if (confirm('All unsaved changes will be discarded')) {
        setState({ ...state, manageCompanyModalVisible: false })
      }
    } else {
      setState({ ...state, manageCompanyModalVisible: false })
    }
  }

  const dismissRemoveCompanyModal = () => {
    setState({ ...state, removeCompanyModalVisible: false })
  }

  const submitCompany = async () => {
    if (state.selectedCompany.id) {
      const { id, name, roleIds } = state.selectedCompany
      const tobeUpdatedCompany = {
        id, name, roleIds, userId: authStore.profile.id,
      }
      await companiesStore.updateCompany(tobeUpdatedCompany)
    } else {
      const { name, roleIds } = state.selectedCompany
      const tobeCreatedCompany = {
        name, roleIds, userId: authStore.profile.id,
      }
      await companiesStore.createCompany(tobeCreatedCompany)
    }

    const { limit, offset } = companiesStore.companiesPage
    await companiesStore.getCompanies({ limit, offset })
    setState({ ...state, manageCompanyModalVisible: false })
  }

  const deleteCompany = async () => {
    await companiesStore.deleteCompany(state.selectedCompany.id)

    const { limit, offset } = companiesStore.companiesPage
    await companiesStore.getCompanies({ limit, offset })
    setState({ ...state, removeCompanyModalVisible: false })
  }

  const handleSelectedCompanyRoleChange = (roleId) => {
    let companyRoles = [...state.selectedCompany.roleIds]
    const foundIndexOfCompanyRole = companyRoles.findIndex((companyRole) => companyRole === roleId)
    if (foundIndexOfCompanyRole === -1) {
      companyRoles.push(roleId)
    } else {
      companyRoles = _.without(companyRoles, roleId)
    }
    setState({ ...state, selectedCompany: { ...state.selectedCompany, roleIds: companyRoles } })
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    const company = {
      ...state.selectedCompany,
      [name]: value,
    }
    setState({ ...state, selectedCompany: company, formErrors: validate(company) })
  }

  const handlePagination = (query) => {
    companiesStore.getCompanies(query)
  }

  const handleRowClick = (company) => {
    companiesStore.setCurrentCompany(company)
    history.push(`/companies/${company.id}/divisions`)
  }

  return (
    <>
      <PageTitle title="Companies" />
      <div className="page-content">
        <FloatingButton
          requiredPermission="companies.create"
          icon={faPlus}
          onClick={openCreateCompanyModal}
          dataTip="Add Company"
        />
        <Table
          isLoading={companiesStore.inProgress}
          headers={tableHeaders}
          page={companiesStore.companiesPage}
          onPageChange={handlePagination}
          onRowClick={handleRowClick}
        >
          <Button
            requiredPermission="companies.update"
            icon={faEdit}
            variant="primary"
            onClick={openEditCompanyModal}
          />
          <Button
            requiredPermission="companies.delete"
            icon={faTrashAlt}
            variant="error"
            onClick={openRemoveCompanyModal}
          />
        </Table>
        <ReactTooltip />
      </div>

      <Modal
        show={state.manageCompanyModalVisible}
        header={state.selectedCompany.id ? 'Edit company' : 'Add company'}
        submitLabel={state.selectedCompany.id ? 'Save' : 'Create'}
        onSubmit={submitCompany}
        onDismiss={dismissManageCompanyModal}
        isLoading={companiesStore.inProgress}
        isDisabled={Object.keys(state.formErrors).some((k) => state.formErrors[k].length)}
        width="60%"
      >
        <Form>
          <FormInput
            name="name"
            label="Name"
            value={state.selectedCompany.name}
            errors={state.formErrors.name}
            onChange={handleChange}
            required
          />
          <div className="company-roles-wrapper">
            <FormGroup label="Company Roles - Restrict Role Assignments to users based on company">
              <div className="company-roles">
                {rolesStore.roles.map((role) => (
                  <Checkbox
                    disabled={state.selectedCompany.id === 1}
                    key={role.id}
                    label={role.name}
                    name={role.name}
                    value={state.selectedCompany.roleIds.indexOf(role.id) > -1}
                    onChange={() => handleSelectedCompanyRoleChange(role.id)}
                  />
                ))}
              </div>
            </FormGroup>
          </div>
        </Form>
      </Modal>

      <Modal
        show={state.removeCompanyModalVisible}
        onDismiss={dismissRemoveCompanyModal}
        onSubmit={deleteCompany}
        isLoading={companiesStore.inProgress}
        submitButtonVariant="error"
        submitLabel="Remove"
        header="Confirm removal"
        width="60%"
      >
        <div>
          All the Divisions, Project, Tokens and Applications under this company will get removed. Are you sure?
        </div>
      </Modal>

    </>
  )
}

Companies.propTypes = {
  companiesStore: PropTypes.shape({
    companiesPage: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape()),
      limit: PropTypes.number,
      offset: PropTypes.number,
      total: PropTypes.number,
    }),
    inProgress: PropTypes.bool,
    getCompanies: PropTypes.func,
    deleteCompany: PropTypes.func,
    createCompany: PropTypes.func,
    updateCompany: PropTypes.func,
    setCurrentCompany: PropTypes.func,
    clear: PropTypes.func,
  }).isRequired,
  rolesStore: PropTypes.shape({
    roles: PropTypes.arrayOf(PropTypes.shape({})),
    getRoles: PropTypes.func,
  }).isRequired,
  authStore: PropTypes.shape({
    profile: PropTypes.shape({ id: PropTypes.number.isRequired }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
}

export default inject(constants.companiesStore, constants.rolesStore, constants.authStore)(observer(Companies))
