import queryString from "query-string"
import api from "./api"

export async function getMasterTemplates(query) {
  return api.get(`/api/v1/master-template?${queryString.stringify(query)}`)
}

export async function updateMasterTemplate(Template) {
  const { _id } = Template
  return api.patch(`/api/v1/master-template/${_id}`, Template)
}

export async function lockUnlockMasterTemplate(Template) {
  const { id } = Template
  return api.patch(`/api/v1/master-template/${id}/update-status`, Template)
}
