import React, { memo, useState, useCallback } from 'react'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import { DateRange } from 'react-date-range'
import PropTypes from 'prop-types'

/**
 * Debounce Logic https://github.com/facebook/react/issues/1360 @ shyam-arora
 * for delay date selection
 */

const debounce = (fn, delay) => {
  let timeoutId
  return (...args) => {
    clearInterval(timeoutId)
    timeoutId = setTimeout(() => fn.apply(this, args), delay)
  }
}

const DateRangeComp = ({ onSelect, debounceTime }) => {
  const [state, setState] = useState([{
    startDate: new Date(),
    endDate: null,
    key: 'selection',
  }])

  const debounceCallback = useCallback(
    debounce((value) => {
      onSelect(value)
    }, debounceTime), [],
  )

  const setDateRange = (item) => {
    setState([item.selection])
    debounceCallback([item.selection])
  }

  return (
    <DateRange
      editableDateInputs
      onChange={setDateRange}
      moveRangeOnFirstSelection={false}
      ranges={state}
    />
  )
}

DateRangeComp.propTypes = {
  onSelect: PropTypes.func.isRequired,
  debounceTime: PropTypes.number,
}

DateRangeComp.defaultProps = {
  debounceTime: 0,
}

export default memo(DateRangeComp)
