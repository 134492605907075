import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'mobx-react'
import AppComponent from './App'
import RootStore from './store/store'

ReactDOM.render(
  <Provider {...new RootStore()}>
    <AppComponent />
  </Provider>,
  document.getElementById('root'),
)
