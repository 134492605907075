/* eslint-disable no-underscore-dangle */

const config = {
  env: process.env.NODE_ENV,
  EONS_URL: process.env.REACT_APP_EONS_URL,
  WEB_API_URL: process.env.REACT_APP_WEB_API_URL,
}

export const { env, EONS_URL, WEB_API_URL} = config

