import queryString from 'query-string'
import api from './api'

export async function getProjectApps(query) {
  const resp = await api.get(`/api/v1/project-apps?${queryString.stringify(query)}`)

  return resp.data.map((projectApp) => {
    const data = { ...projectApp }
    const rawConfigs = JSON.parse(projectApp.vendorConfigs)

    data.vendorConfigs = rawConfigs.map((item) => {
      const rawItem = { ...item }
      rawItem.vendorConfig = JSON.parse(item.vendorConfig)
      return rawItem
    })

    return data
  })
}

export async function deleteProjectApp(projectAppId) {
  return api.delete(`/api/v1/project-apps/${projectAppId}`)
}

export async function createProjectApp(projectAppData) {
  return api.post('/api/v1/project-apps', projectAppData)
}
