import api from './api'
import * as projectAppsApi from './projectAppsApi'

export const getBillingCountByPaid = async ({ startDate, endDate, paids }) => {
  const [sYear, eYear] = [startDate.getFullYear(), endDate.getFullYear()]
  const [sMonth, eMonth] = [startDate.getMonth(), endDate.getMonth()]
  const [sDay, eDay] = [startDate.getDate(), endDate.getDate()]
  return api
    // eslint-disable-next-line max-len
    .get(`/api/v1/billing?paids=[${paids}]&&year=[${sYear}, ${eYear}]&&month=[${sMonth}, ${eMonth}]&&day=[${sDay}, ${eDay}]`)
}

export const getProjectBillingCount = async ({ startDate, endDate, projectId }) => {
  const res = await projectAppsApi.getProjectApps({ projectId })
  const paids = res.map((app) => app.id)

  return getBillingCountByPaid({ startDate, endDate, paids })
}
