/* eslint-disable no-console */
import React, { useEffect, useState } from 'react'
// import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import './AssetDetails.scss'
import _ from 'lodash'
import { toJS } from 'mobx'
import {
  faEye, faPen, faTrashAlt, faArrowLeft, faLock, faLockOpen, faFolderPlus
} from '@fortawesome/free-solid-svg-icons'
import ReactTooltip from 'react-tooltip'
import { constants } from '../../../store/constants'
// import PageTitle from '../../../components/PageTitle/PageTitle'
import Table from '../../../components/Table/Table'
import Button from '../../../components/Button/Button'
import Modal from '../../../components/Modal/Modal'
import Form from '../../../components/Form/Form'
import FormInput from '../../../components/Form/FormInput'
import FormDropdown from '../../../components/Form/FormDropdown'

const TemplateDetails = ({
  notificationStore,
  templatesStore,
  paid,
  backButton,
}) => {
  const [state, setState] = useState({
    // eslint-disable-next-line react/destructuring-assignment
    paid,
    templateData: {},
    showTemplateFormModal: false,
    originalTemplateValue: {},
    latestTemplateValue: {},
    showTemplateOverviewModal: false,
    templateDetail: {},
  })

  useEffect(() => {
    const init = async () => {
      const fetchData = async () => {
        await templatesStore.getTemplates({ paid: state.paid })
        // console.log(toJS(templatesStore.templatePage.data))

        setState({
          ...state,
          templateData: templatesStore.templatePage.data,
        })
      }

      fetchData()
    }
    init()
    ReactTooltip.rebuild()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // const handleInputChange = (event) => {
  //   const { value, name } = event.target

  //   setState({
  //     ...state,
  //     latestTemplateValue: {
  //       ...state.latestTemplateValue,
  //       [name]: value,
  //     },
  //   })
  // }

  // const openTemplateFormModal = async (currentTemplate) => {
  //   const template = {
  //     // eslint-disable-next-line no-underscore-dangle
  //     id: currentTemplate._id,
  //     name: currentTemplate.name,
  //     templateId: currentTemplate.templateId,
  //     paid: currentTemplate.paid,
  //     isLocked: currentTemplate.isLocked ? 'Locked' : 'Unlocked',
  //   }

  //   setState({
  //     ...state,
  //     showTemplateFormModal: true,
  //     originalTemplateValue: template,
  //     latestTemplateValue: template,
  //   })
  // }

  // const dismissTemplateFormModal = () => {
  //   // eslint-disable-next-line
  //   if (_.isEqual(state.originalTemplateValue, state.latestTemplateValue)
  // || confirm('All unsaved changes will be discarded')) {
  //     setState({
  //       ...state, showTemplateFormModal: false, latestTemplateValue: {}, originalTemplateValue: {},
  //     })
  //   }
  // }

  // Below function is no-longer used.. not to delete yet though
  // const lockUnlockTemplateForm = async () => {
  //   const templateData = {
  //     id: state.latestTemplateValue.id,
  //     paid: state.latestTemplateValue.paid,
  //     isLocked: (state.latestTemplateValue.isLocked === 'Locked') ? true : false,
  //   }

  //   await templatesStore.lockUnlockTemplate(templateData)
  //   await templatesStore.getTemplates({ paid: templateData.paid })

  //   setState({
  //     ...state,
  //     showTemplateFormModal: false,
  //     originalTemplateValue: {},
  //     latestTemplateValue: {},
  //     templateData: templatesStore.templatePage.data,
  //   })
  // }

  const changeStatus = async (item)=>{
    const templateData = {
      id: item._id,
      paid: item.paid,
      isLocked: !item.isLocked,
    }

    await templatesStore.lockUnlockTemplate(templateData)
    await templatesStore.getTemplates({ paid: templateData.paid })

    setState({
      ...state,
      originalTemplateValue: {},
      latestTemplateValue: {},
      templateData: templatesStore.templatePage.data,
    })
  }
  
  const openTemplateOverviewModal = async (currentTemplate) => {
    setState({
      ...state,
      showTemplateOverviewModal: true,
      templateDetail: currentTemplate,
    })
  }

  const templatesTableHeaders = [{
    label: '_id',
    key: '_id',
    styles: {
      width: '200px',
      justifyContent: 'center',
    },
  }, {
    label: 'Project App ID',
    key: 'paid',
    styles: {
      flex: 1,
    },
  }, {
    label: 'Name',
    key: 'name',
    styles: {
      flex: 1,
    },
  }, {
    label: 'Lock Status',
    key: 'isLocked',
    styles: {
      flex: 1,
    },
    render: (item) => (
      <div className="config-buttons">
        <Button
          // label="email"
          dataTip={item.isLocked ? 'Unlock this Template' : 'Lock this Template'}
          icon={item.isLocked ? faLock : faLockOpen}
          // disabled={item.isLocked}
          variant={item.isLocked ? 'error' : 'primary'}
          // onClick={() => { openTemplateFormModal(item) }}
          onClick={() => { changeStatus(item) }}
          customStyle={{ marginRight: 50 }}
        />
        <ReactTooltip />
      </div>
    ),
  }]

  const goBack = () => {
    backButton()
  }

  const handlePageChange = (query) => {
    templatesStore.getTemplates({ ...query, paid: state.paid })
  }

  return (
    <>
      <div className="page-content">
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <Button
            // label="email"
            dataTip="Back To Dashboard"
            icon={faArrowLeft}
            variant="primary"
            onClick={goBack}
            customStyle={{ marginRight: 50 }}
          />
          <div style={{ color: '#425E99' }}>
            TEMPLATES FOR PAID: &nbsp;
            {state.paid}
          </div>
        </div>
        <Table
          headers={templatesTableHeaders}
          // page={{ data: state.templateData }}
          page={{
            ...templatesStore.templatePage,
            data: templatesStore.templatePage.data,
          }}
          // hidePaginator
          onPageChange={handlePageChange}
        >
          <Button
            dataTip="Overview"
            variant="primary"
            icon={faEye}
            onClick={openTemplateOverviewModal}
          />
          {/* <Button
            dataTip="Edit"
            variant="primary"
            icon={faPen}
            onClick={openTemplateFormModal}
          /> */}
          <ReactTooltip />
        </Table>
      </div>

      {/* <Modal
        show={state.showTemplateFormModal}
        onDismiss={dismissTemplateFormModal}
        onSubmit={lockUnlockTemplateForm}
        header="Lock/Unlock Template"
        width="30%"
      >
        <Form>
          <div>
            <FormInput
              name="paid"
              label="Project App ID"
              type="text"
              value={state.latestTemplateValue.paid}
              disabled
            />
            <FormInput
              name="name"
              label="Template Name"
              type="text"
              value={state.latestTemplateValue.name}
              disabled
            />
            <FormDropdown
              name="isLocked"
              label="Lock Status"
              options={['Locked', 'Unlocked'].map((item) => ({
                value: item,
                label: item,
              }))}
              value={state.latestTemplateValue.isLocked}
              onChange={handleInputChange}
            />
          </div>
        </Form>
      </Modal> */}

      <Modal
        show={state.showTemplateOverviewModal}
        onDismiss={() => { setState({ ...state, showTemplateOverviewModal: false }) }}
        noSubmit
        header="Template Detail"
        width="30%"
      >
        <div style={{ wordWrap: 'break-word' }}>        
          <pre>{JSON.stringify(state.templateDetail, null, 4)}</pre>
        </div>
      </Modal>
    </>
  )
}

export default inject(
  constants.notificationStore,
  constants.templatesStore,
)(observer(TemplateDetails))
