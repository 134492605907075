import { decorate, action } from 'mobx'

export default class ErrorStore {
  constructor(rootStore) {
    this.rootStore = rootStore
  }

  handleError(exception) {
    if (
      exception.response && exception.response.data && exception.response.data.isCustomError
    ) {
      this.rootStore.notificationStore.error({
        message: exception.response.data.message,
      })
    } else {
      this.rootStore.notificationStore.error({ message: exception.message })
    }
  }
}

decorate(ErrorStore, {
  handleError: action,
})
