import React, { useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import ReactTooltip from 'react-tooltip'
import PropTypes from 'prop-types'
import { constants } from './store/constants'
import PublicRoutes from './pages/public/Routes'
import PrivateRoutes from './pages/private/Routes'
import Notification from './components/Notification/Notification'
import Spinner from './components/Spinner/Spinner'
import { PermissionProvider } from './PermissionContext'

import './styles/import.scss'

export const App = ({ authStore, notificationStore }) => {
  useEffect(() => {
    authStore.getMe()
    ReactTooltip.rebuild()
  }, [authStore])

  const context = {
    userPermissions: authStore.profile.permissions,
    isAllowed: (requiredPermission) => {
      if (!requiredPermission) {
        return true
      }

      return context.userPermissions.indexOf(requiredPermission) > -1
    },
  }

  return (
    <PermissionProvider value={context}>
      <Router>
        {!authStore.initialCheckComplete
          ? (
            <div className="full-size-container flex-center">
              <Spinner variant="dark" height="60px" width="60px">
                <span style={{ fontSize: '1.5rem' }}>Loading</span>
              </Spinner>
            </div>
          )
          : ''}

        {authStore.initialCheckComplete && (
          authStore.loggedIn
            ? <PrivateRoutes />
            : <PublicRoutes />
        )}
      </Router>
      <Notification notifications={notificationStore.notifications} />
      <ReactTooltip />
    </PermissionProvider>
  )
}

App.propTypes = {
  authStore: PropTypes.shape({
    getMe: PropTypes.func,
    loggedIn: PropTypes.bool,
    initialCheckComplete: PropTypes.bool,
    profile: PropTypes.shape({
      permissions: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
  }).isRequired,
  notificationStore: PropTypes.shape({
    notifications: PropTypes.arrayOf(PropTypes.shape({
      variant: PropTypes.oneOf(['error', 'info', 'success']),
      message: PropTypes.string,
      id: PropTypes.number,
      fade: PropTypes.bool,
      dismiss: PropTypes.func,
    })),
  }).isRequired,
}

export default inject(constants.authStore, constants.notificationStore)(observer(App))
