import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { faPlus, faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { inject, observer } from 'mobx-react'
import _ from 'lodash'
import ReactTooltip from 'react-tooltip'
import { PermissionContext } from '../../../PermissionContext'
import { constants } from '../../../store/constants'
import Button from '../../../components/Button/Button'
import FloatingButton from '../../../components/FloatingButton/FloatingButton'
import Table from '../../../components/Table/Table'
import Modal from '../../../components/Modal/Modal'
import Form from '../../../components/Form/Form'
import FormInput from '../../../components/Form/FormInput'
import FormDropdown from '../../../components/Form/FormDropdown'
import JsonEditor from '../../../components/Form/JsonEditor'
import PageTitle from '../../../components/PageTitle/PageTitle'

const NEW_VENDOR = {
  name: '',
  messageType: '',
  configTemplate: [
    { key: 'user', label: 'User' },
    { key: 'password', label: 'Password' },
  ],
}

const MESSAGE_TYPES_OPTIONS = [
  { value: 'SMS', label: 'sms' },
  { value: 'EMAIL', label: 'email' },
  { value: 'VOICE', label: 'voice' },
  { value: 'EONS', label: 'eons' },
  { value: 'CPP', label: 'cpp' },
  { value: 'LOGIC', label: 'logic' },
  { value: 'URL', label: 'url' },
  { value: 'UNSUBSCRIBE', label: 'unsubscribe' },
  { value: 'CUSTOM', label: 'custom' },
]

const tableHeaders = [{
  label: '#',
  key: 'id',
  styles: {
    width: '50px',
    justifyContent: 'center',
  },
}, {
  label: 'Name',
  key: 'name',
  styles: {
    flex: 1,
  },
}, {
  label: 'Message Type',
  key: 'messageType',
  styles: {
    flex: 1,
  },
}]

const Vendors = ({ vendorsStore, history }) => {
  const permissions = useContext(PermissionContext)

  useEffect(() => {
    const isAllowed = permissions.isAllowed('companies.list')

    if (!isAllowed) {
      history.push('/home')
    }

    vendorsStore.getVendors()

    return function cleanup() {
      vendorsStore.clear()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const defaultStateValues = {
    originalValue: {},
    selectedVendor: { ...NEW_VENDOR },
    manageVendorModalVisible: false,

    manageVendorModalDisabled: false,
    removeVendorModalVisible: false,
  }

  const [state, setState] = useState(defaultStateValues)

  const openEditVendorModal = (vendor) => {
    setState({
      ...state,
      originalValue: vendor,
      selectedVendor: vendor,
      manageVendorModalVisible: true,
    })
  }

  const openCreateVendorsModal = () => {
    setState({
      originalValue: { ...NEW_VENDOR },
      selectedVendor: { ...NEW_VENDOR },
      manageVendorModalVisible: true,
    })
  }

  const openRemoveVendorModal = (vendor) => {
    setState({
      selectedVendor: vendor,
      removeVendorModalVisible: true,
    })
  }

  const dismissManageVendorModal = () => {
    if (!_.isEqual(state.originalValue, state.selectedVendor)) {
      // eslint-disable-next-line
      if (confirm('All unsaved changes will be discarded')) {
        setState({ ...state, manageVendorModalVisible: false })
      }
    } else {
      setState({ ...state, manageVendorModalVisible: false })
    }
  }

  const dismissRemoveVendorModal = () => {
    setState({ ...state, removeVendorModalVisible: false })
  }

  const submitVendor = async () => {
    const configData = {
      ...state.selectedVendor,
      configTemplate: JSON.stringify(state.selectedVendor.configTemplate),
    }
    if (configData.id) {
      const {
        id, name, messageType, configTemplate,
      } = configData
      await vendorsStore.updateVendor({
        id, name, messageType, configTemplate,
      })
    } else {
      await vendorsStore.createVendor(configData)
    }

    const { limit, offset } = vendorsStore.vendorsPage
    await vendorsStore.getVendors({ limit, offset })
    setState({ ...state, manageVendorModalVisible: false })
  }

  const deleteVendors = async () => {
    await vendorsStore.deleteVendor(state.selectedVendor.id)

    const { limit, offset } = vendorsStore.vendorsPage
    await vendorsStore.getVendors({ limit, offset })
    setState({ ...state, removeVendorModalVisible: false })
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    const vendor = {
      ...state.selectedVendor,
      [name]: value,
    }
    setState({ ...state, selectedVendor: vendor })
  }

  const handleJsonChange = (jsonString) => {
    const vendor = {
      ...state.selectedVendor,
      configTemplate: jsonString,
    }
    setState({ ...state, selectedVendor: vendor })
  }

  const handlePagination = (query) => {
    vendorsStore.getVendors(query)
  }

  return (
    <>
      <PageTitle title="Vendors" />
      <div className="page-content">
        <FloatingButton
          requiredPermission="vendors.create"
          icon={faPlus}
          onClick={openCreateVendorsModal}
          dataTip="Add Vendor"
        />
        <Table
          isLoading={vendorsStore.inProgress}
          headers={tableHeaders}
          page={vendorsStore.vendorsPage}
          onPageChange={handlePagination}
        >
          <Button
            requiredPermission="vendors.update"
            icon={faEdit}
            variant="primary"
            onClick={openEditVendorModal}
          />
          <Button
            requiredPermission="vendors.delete"
            icon={faTrashAlt}
            variant="error"
            onClick={openRemoveVendorModal}
          />
        </Table>
        <ReactTooltip />
      </div>

      <Modal
        show={state.manageVendorModalVisible}
        header={state.selectedVendor.id ? 'Edit Vendor' : 'Add Vendor'}
        submitLabel={state.selectedVendor.id ? 'Save' : 'Create'}
        onSubmit={submitVendor}
        onDismiss={dismissManageVendorModal}
        isLoading={vendorsStore.inProgress}
        isDisabled={state.manageVendorModalDisabled}
        width="60%"
      >
        <Form>
          <div className="two-column">
            <FormInput
              name="name"
              label="Name"
              value={state.selectedVendor.name}
              onChange={handleChange}
            />
            <FormDropdown
              name="messageType"
              label="Message type"
              options={MESSAGE_TYPES_OPTIONS}
              value={state.selectedVendor.messageType}
              onChange={handleChange}
              errorMessage="Please select a message type"
              required
            />
          </div>
          <JsonEditor
            label="Configuration schema"
            onChange={handleJsonChange}
            value={state.selectedVendor.configTemplate}
          />
        </Form>
      </Modal>

      <Modal
        show={state.removeVendorModalVisible}
        onDismiss={dismissRemoveVendorModal}
        onSubmit={deleteVendors}
        isLoading={vendorsStore.inProgress}
        submitButtonVariant="error"
        submitLabel="Remove"
        header="Confirm removal"
        width="60%"
      >
        <div>
          Are you sure you want to remove the selected Vendor?
        </div>
      </Modal>
    </>
  )
}

Vendors.propTypes = {
  vendorsStore: PropTypes.shape({
    vendorsPage: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape()),
      limit: PropTypes.number,
      offset: PropTypes.number,
      total: PropTypes.number,
    }),
    inProgress: PropTypes.bool,
    getVendors: PropTypes.func,
    deleteVendor: PropTypes.func,
    createVendor: PropTypes.func,
    updateVendor: PropTypes.func,
    clear: PropTypes.func,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
}

export default inject(constants.vendorsStore)(observer(Vendors))
