import {
  decorate, observable, runInAction, action,
} from 'mobx'
import * as projectsApi from '../api/projectsApi'
import * as projectAppsApi from '../api/projectAppsApi'
import * as projectVendorsApi from '../api/projectVendorsApi'

export default class ProjectsStore {
  constructor(rootStore) {
    this.rootStore = rootStore

    runInAction(() => {
      this.inProgress = false

      this.projectsPage = {}
      this.currentProjectScope = {}

      this.projectApps = []
    })
  }

  clear() {
    this.projectsPage = {}
  }

  setCurrentProject(project) {
    this.currentProjectScope = project
  }

  async getCurrentProject(projectId) {
    if (this.currentProjectScope.id !== parseInt(projectId, 10)) {
      await this.getProjectById(projectId)
    }

    return this.currentProjectScope
  }

  async getProjects(query = {}) {
    this.inProgress = true
    try {
      const res = await projectsApi.getProjects(query)
      runInAction(() => {
        this.projectsPage = res.data
      })
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async getProjectById(projectId) {
    this.inProgress = true
    try {
      const res = await projectsApi.getProjectById(projectId)
      runInAction(() => {
        this.currentProjectScope = res.data
      })
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async deleteProject(projectId) {
    this.inProgress = true
    try {
      await projectsApi.deleteProject(projectId)
      this.rootStore.notificationStore.success({ message: 'Project deleted' })
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async createProject(projectData) {
    this.inProgress = true
    try {
      await projectsApi.createProject(projectData)
      this.rootStore.notificationStore.success({ message: 'Project created' })
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async updateProject(projectData) {
    this.inProgress = true
    try {
      await projectsApi.updateProject(projectData)
      this.rootStore.notificationStore.success({ message: 'Project updated' })
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async getProjectApps(query = {}) {
    this.inProgress = true
    try {
      const projectApps = await projectAppsApi.getProjectApps(query)
      runInAction(() => {
        this.projectApps = projectApps
      })
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async createProjectApp(projectAppData) {
    this.inProgress = true
    try {
      await projectAppsApi.createProjectApp(projectAppData)
      this.rootStore.notificationStore.success({ message: 'Application successfully assigned to project' })
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async deleteProjectApp(projectAppId) {
    this.inProgress = true
    try {
      await projectAppsApi.deleteProjectApp(projectAppId)
      this.rootStore.notificationStore.success({ message: 'Application successfully removed from project' })
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async createProjectAppVendorConfig(projectAppVendorConfigData) {
    this.inProgress = true
    try {
      await projectVendorsApi.createProjectVendor(projectAppVendorConfigData)
      this.rootStore.notificationStore.success(
        { message: 'Application vendor configuration successfully created' },
      )
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async updateProjectAppVendorConfig(projectVendorId, projectAppVendorConfigData) {
    this.inProgress = true
    try {
      await projectVendorsApi.updateProjectVendor(projectVendorId, projectAppVendorConfigData)
      this.rootStore.notificationStore.success(
        { message: 'Application vendor configuration successfully updated' },
      )
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }
}

decorate(ProjectsStore, {
  inProgress: observable,
  projectsPage: observable,
  currentProjectScope: observable,

  projectApps: observable,
  projectVendors: observable,

  updateProject: action,
  createProject: action,
  deleteProject: action,
  getProjects: action,
  getProjectById: action,
  setCurrentProject: action,
  getCurrentProject: action,

  getProjectApps: action,
  createProjectApp: action,
  deleteProjectApp: action,

  createProjectAppVendorConfig: action,
  updateProjectAppVendorConfig: action,

  clear: action,
})
