import {
  decorate, observable, runInAction, action,
} from 'mobx'
import * as divisionsApi from '../api/divisionsApi'

export default class DivisionsStore {
  constructor(rootStore) {
    this.rootStore = rootStore

    runInAction(() => {
      this.inProgress = false
      this.divisionsPage = {}
      this.currentDivisionScope = {}
    })
  }

  clear() {
    this.divisionsPage = {}
  }

  setCurrentDivision(division) {
    this.currentDivisionScope = division
  }

  async getCurrentDivision(divisionId) {
    if (this.currentDivisionScope.id !== parseInt(divisionId, 10)) {
      await this.getDivisionById(divisionId)
    }

    return this.currentDivisionScope
  }

  async getDivisions(query = {}) {
    this.inProgress = true
    try {
      const res = await divisionsApi.getDivisions(query)
      runInAction(() => {
        this.divisionsPage = res.data
      })
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async getDivisionById(divisionId) {
    this.inProgress = true
    try {
      const res = await divisionsApi.getDivisionById(divisionId)
      runInAction(() => {
        this.currentDivisionScope = res.data
      })
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async deleteDivision(divisionId) {
    this.inProgress = true
    try {
      await divisionsApi.deleteDivision(divisionId)
      this.rootStore.notificationStore.success({ message: 'Division deleted' })
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async createDivision(divisionData) {
    this.inProgress = true
    try {
      await divisionsApi.createDivision(divisionData)
      this.rootStore.notificationStore.success({ message: 'Division created' })
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async updateDivision(divisionData) {
    this.inProgress = true
    try {
      await divisionsApi.updateDivision(divisionData)
      this.rootStore.notificationStore.success({ message: 'Division updated' })
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }
}

decorate(DivisionsStore, {
  inProgress: observable,
  divisionsPage: observable,
  currentDivisionScope: observable,
  updateDivision: action,
  createDivision: action,
  deleteDivision: action,
  getDivisions: action,
  getCurrentDivision: action,
  getDivisionById: action,
  setCurrentDivision: action,
  clear: action,
})
