import React, { memo } from 'react'
import PropTypes from 'prop-types'
import './floatingButtonContainer.scss'

const FloatingButtonContainerComp = ({ children }) => (
  <div className="folating-button-container">
    <div className="floating-buttons">
      {children}
    </div>
  </div>
)

FloatingButtonContainerComp.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default memo(FloatingButtonContainerComp)
