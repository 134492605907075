import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { constants, VOICE_VENDOR } from '../../../../store/constants'
import { FileDropBox } from '../../../../components/File/File'
import Button from '../../../../components/Button/Button'
import './voiceAsset.scss'
import useDebounce from '../../../../api/debounceApi'

const ProgressBar = ({ children, progress }) => (
  <div className="ProgressBar">
    <div
      className="Progress"
      style={{ width: `${progress}%` }}
    />
    {children}
  </div>
)

const FilesList = ({ files = [], onDelete, vendorName }) => files.map((f) => {
  const deleteFile = () => {
    onDelete(f)
  }
  return (
    <div className="file-list">
      <div className="file-detail">
        <p>{f.sequence}</p>
        {vendorName == VOICE_VENDOR.FreeSwitch ? <span className="fileName">{f.filename}</span> : <span>{f.file}</span>}
        <span>{f.status}</span>
        <FontAwesomeIcon
          className="deleteIcon"
          icon={faTrashAlt}
          onClick={deleteFile}
        />
      </div>
      <div className="ProgressWrapper">
        <ProgressBar progress="100" />
        <FontAwesomeIcon icon={faCheckCircle} />
      </div>
    </div>
  )
})

/**
 * Voice Asset UI management
 * TODO: Need refactoring to complete (optional)
 */
const VoiceAssets = ({
  voiceAssetsStore, batchId, dse, di, dndStatus, onSelectedFile, dropFileComponent, voiceIDS, vendorName,
  campaignId, companyId
}) => {
  const [files, setFiles] = useState([])

  const [loading, setLoading] = useState(false)

  const [progressBar, setProgressBar] = useState(0)

  const deleteFile = async (file) => {
    const { directory, filename, sequence } = file
    // eslint-disable-next-line
    if (confirm(`Confirm deleting file ${sequence}`)) {
      if (vendorName === VOICE_VENDOR.FreeSwitch) {
        await voiceAssetsStore.deleteFreeSwitchFile({ query: { companyId, campaignId, filename } })
        await voiceAssetsStore.getFreeSwitchVoiceAssets({ companyId, campaignId })
        await setFiles(voiceAssetsStore.voiceAssets[`${companyId}-${campaignId}`])

      } else {
        await voiceAssetsStore.deleteFile({ query: { batchId: directory, dse: filename, di: sequence } })
        await voiceAssetsStore.getVoiceAssets({ batchId, dse })
        setFiles(voiceAssetsStore.voiceAssets[`${batchId}-${dse}`])
      }
    }
  }

  const uploadFile = async (file) => {
    setLoading(true)
    onSelectedFile(file)
    await setProgressBar(25)
    if (vendorName === VOICE_VENDOR.FreeSwitch) {
      await voiceAssetsStore.uploadFile({
        query: { companyId, filename: file.name, campaignId },
        file,
      })
    } else {
      await voiceAssetsStore.uploadLegacyFile({
        query: { batchId, dse, di },
        file,
      })
    }

    await setProgressBar(90)
    if (vendorName === VOICE_VENDOR.FreeSwitch) {
      await voiceAssetsStore.getFreeSwitchVoiceAssets({ companyId, campaignId })
    } else {
      await voiceAssetsStore.getVoiceAssets({ batchId, dse })
    }

    await setProgressBar(95)
    if (vendorName === VOICE_VENDOR.FreeSwitch) {
      await setFiles(voiceAssetsStore.voiceAssets[`${companyId}-${campaignId}`])
    } else {
      await setFiles(voiceAssetsStore.voiceAssets[`${batchId}-${dse}`])
    }
    setProgressBar(100)
  }

  // Debounce effect on batchId and dse
  const debouncedBatchId = useDebounce(batchId, 500)
  const debouncedDse = useDebounce(dse, 500)

  const debouncedCompanyId = useDebounce(companyId, 500)
  const debouncedCampaignId = useDebounce(campaignId, 500)
  const debounceVendorName = useDebounce(vendorName, 500)
  /**
   * Each render will fetch voice assets
   * On Cleanup: will delete any 'UPLOADED'(considered not processed) files
   */
  useEffect(() => {
    if (debouncedDse && debouncedBatchId) {
      const init = async () => {
        await voiceAssetsStore.getVoiceAssets({ batchId: debouncedBatchId, dse: debouncedDse })
        setFiles(voiceAssetsStore.voiceAssets[`${debouncedBatchId}-${debouncedDse}`])
      }
      init()
    }
    if (debouncedCompanyId && debouncedCampaignId) {
      const init = async () => {
        await voiceAssetsStore.getFreeSwitchVoiceAssets({
          companyId: debouncedCompanyId,
          campaignId: debouncedCampaignId
        })
        setFiles(voiceAssetsStore.voiceAssets[`${debouncedCompanyId}-${debouncedCampaignId}`])
      }
      init()
    }

    return async () => {
      // Clean up hook
      // Check if all the files have been TRANSFERRED/processed
      if (debounceVendorName === VOICE_VENDOR.FreeSwitch) {
        await voiceAssetsStore.getFreeSwitchVoiceAssets({
          companyId: debouncedCompanyId,
          campaignId: debouncedCampaignId
        }) // syncing files
        const list = voiceAssetsStore.voiceAssets[`${debouncedCompanyId}-${debouncedCampaignId}`] || []
        list.forEach((file) => {
          const { companyId, status, campaignId, name } = file
          if (status === 'UPLOADED') {
            // Remove
            voiceAssetsStore.deleteFreeSwitchFile({ query: { companyId, campaignId, filename: name } })
          }
        })
      } else {
        await voiceAssetsStore.getVoiceAssets({ batchId: debouncedBatchId, dse: debouncedDse }) // syncing files
        const list = voiceAssetsStore.voiceAssets[`${debouncedBatchId}-${debouncedDse}`] || []
        list.forEach((file) => {
          const { sequence, status } = file
          if (status === 'UPLOADED') {
            // Remove
            voiceAssetsStore.deleteFile({ query: { batchId: debouncedBatchId, dse: debouncedDse, di: sequence } })
          }
        })
      }
    }
  }, [debouncedBatchId, debouncedDse, debouncedCompanyId, debouncedCampaignId, debounceVendorName, voiceAssetsStore])

  return (
    <div className="voiceFiles">
      <h2>Voice Files</h2>
      {voiceIDS}
      {dropFileComponent}
      <FilesList files={files} onDelete={deleteFile} vendorName={vendorName} />
      {dndStatus ? (
        <div className="dropbox">
          <FileDropBox onDrop={uploadFile} />
          <div className="dropbox-detail">
            {vendorName !== VOICE_VENDOR.FreeSwitch && (
              <p>
                <span>DI: </span>
                {di}
              </p>
            )}
            <Button
              disabled={loading}
              label="Cancel"
              variant="default"
              onClick={onSelectedFile}
            />
          </div>
        </div>
      ) : ''}
      {progressBar > 0 && progressBar < 100 ? (
        <div className="file-list">
          <div className="file-detail">
            {vendorName === VOICE_VENDOR.FreeSwitch ? 'test' : <p>{di}</p>}
          </div>
          <div className="ProgressWrapper">
            <ProgressBar progress={progressBar} />
            <FontAwesomeIcon icon={faCheckCircle} />
          </div>
        </div>
      ) : ''}
    </div>
  )
}

export default inject(constants.voiceAssetsStore)(observer(VoiceAssets))
