import queryString from 'query-string'
import _ from 'lodash'
import api from './api'

export async function getScopedRoles(query) {
  return api.get(`/api/v1/roles/scoped?${queryString.stringify(query)}`)
}

export async function getRoles(query) {
  return api.get(`/api/v1/roles?${queryString.stringify(query)}`)
}

export async function deleteRole(roleId) {
  return api.delete(`/api/v1/roles/${roleId}`)
}

export async function createRole(roleData) {
  return api.post('/api/v1/roles', roleData)
}

export async function updateRole(role) {
  const { id, ...roleData } = role
  return api.patch(`/api/v1/roles/${id}`, _.pick(roleData, ['name', 'permissions']))
}
