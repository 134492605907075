import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { constants } from '../../../store/constants'
import Form from '../../../components/Form/Form'
import FormInput from '../../../components/Form/FormInput'
import Panel from '../../../components/Panel/Panel'
import Button from '../../../components/Button/Button'
import { EMAIL_REGEX } from '../../../utils'
import './login.scss'

const Login = ({ authStore, history, notificationStore }) => {
  useEffect(() => {
    if (window.sessionStorage.getItem('message')) {
      const message = window.sessionStorage.getItem('message')
      setTimeout(() => {
        notificationStore.info({ message })
      }, 1000)
      window.sessionStorage.removeItem('message')
    }
  })
  const defaultStateValues = {
    userData: {
      email: '',
      password: '',
    },
    formErrors: {
      disableOnLoad: [1],
    },
  }

  const [state, setState] = useState(defaultStateValues)

  const validateEmail = (email) => {
    const errors = []

    if (!email.length) {
      errors.push('Email is required')
    } else if (!EMAIL_REGEX.test(email)) {
      errors.push('Invalid email address.')
    }

    return errors
  }

  const validatePassword = (password) => {
    const errors = []

    if (!password.length) {
      errors.push('Password is required')
    }

    return errors
  }

  const validate = ({ email, password }) => ({
    email: validateEmail(email),
    password: validatePassword(password),
  })

  const handleChange = (event) => {
    const { name, value } = event.target
    const userData = {
      ...state.userData,
      [name]: value,
    }

    setState({ ...state, userData, formErrors: validate(userData) })
  }

  const onSubmit = async (e) => {
    if (e.preventDefault) {
      e.preventDefault()
    }
    const { email, password } = state.userData
    await authStore.login(email, password)

    if (authStore.loggedIn) {
      const path = window.sessionStorage.getItem('url') || '/home'
      history.push(path)
      window.sessionStorage.removeItem('url')
    }
  }

  return (
    <div className="full-size-container flex-center flex-column">
      <Panel header="Login">
        <Form onSubmit={onSubmit}>
          <FormInput
            name="email"
            type="email"
            label="Email"
            value={state.userData.email}
            errors={state.formErrors.email}
            onChange={handleChange}
            required
          />

          <FormInput
            name="password"
            type="password"
            label="Password"
            value={state.userData.password}
            errors={state.formErrors.password}
            onChange={handleChange}
            required
          />

          <Button
            label="Login"
            type="submit"
            variant="primary"
            classNames={['full-width']}
            isLoading={authStore.inProgress}
            disabled={Object.keys(state.formErrors).some((k) => state.formErrors[k].length)}
            onClick={onSubmit}
          />
        </Form>
        <div className="utility-links">
          <div className="text-center">
            <a href="#" className="login-help">Having trouble logging in?</a>
          </div>
          <div className="text-center">
            <a href="/forgot-password" className="login-help">Forgot your password?</a>
          </div>
        </div>
      </Panel>
      <img style={{ width: '300px', marginTop: '2rem' }} alt="logo" src="/assets/logo.png" />
    </div>
  )
}

Login.propTypes = {
  authStore: PropTypes.shape({
    inProgress: PropTypes.bool,
    loggedIn: PropTypes.bool,
    login: PropTypes.func,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  notificationStore: PropTypes.shape({
    info: PropTypes.func,
  }).isRequired,
}

export default inject(constants.authStore, constants.notificationStore)(observer(Login))
