import {
  decorate, observable, runInAction, action,
} from 'mobx'
import * as usersApi from '../api/usersApi'

export default class UsersStore {
  constructor(rootStore) {
    this.rootStore = rootStore

    runInAction(() => {
      this.inProgress = false
      this.usersPage = {}
    })
  }

  async getUsers(query = {}) {
    this.inProgress = true
    try {
      const res = await usersApi.getUsers(query)
      runInAction(() => {
        this.usersPage = res.data
      })
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async deleteUser(userId) {
    this.inProgress = true
    try {
      await usersApi.deleteUser(userId)
      this.rootStore.notificationStore.success({ message: 'User deleted' })
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async createUser(userData) {
    this.inProgress = true
    try {
      await usersApi.createUser(userData)
      this.rootStore.notificationStore.success({ message: 'User created' })
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async updateUser(userData) {
    this.inProgress = true
    try {
      await usersApi.updateUser(userData)
      this.rootStore.notificationStore.success({ message: 'User updated' })
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async resendUserActivation(userId) {
    this.inProgress = true
    try {
      await usersApi.resendUserActivation(userId)
      this.rootStore.notificationStore.success({ message: 'User activation email sent' })
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async forgotPassword(userId, email) {
    this.inProgress = true
    try {
      await usersApi.forgotPassword(userId, email)
      this.rootStore.notificationStore.success({ message: 'User Password Reset Initiated' })
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async reactivateDeletedUser(user) {
    this.inProgress = true
    try {
      await usersApi.reactivateDeletedUser(user)
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }
    runInAction(() => {
      this.inProgress = false
    })
  }
}

decorate(UsersStore, {
  inProgress: observable,
  usersPage: observable,
  updateUser: action,
  createUser: action,
  deleteUser: action,
  getUsers: action,
  resendUserActivation: action,
  reactivateDeletedUser: action,
  forgotPassword: action,
})
