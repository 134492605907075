import React from 'react'
import {
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import Login from './Login/Login'
import PasswordReset from './PasswordReset/PasswordReset'
import ForgotPassword from './ForgotPassword/ForgotPassword'
import UnsubscribeEmail from './UnsubscribeEmail/UnsubscribeEmail'
import VersioningTag from '../../components/VersioningTag/VersioningTag'

export default () => (
  <div>
    <Switch>
      <Route exact path="/">
        <Redirect to="/login" />
      </Route>
      <Route path="/login" component={Login} />
      <Route path="/password-reset" component={PasswordReset} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/unsubscribe/:key" component={UnsubscribeEmail} />
      <Redirect from="*" to="/login" />
    </Switch>
    <VersioningTag />
  </div>
)
