import {
  decorate, observable, runInAction, action,
} from 'mobx'
import * as tokensApi from '../api/tokensApi'

export default class TokensStore {
  constructor(rootStore) {
    this.rootStore = rootStore

    runInAction(() => {
      this.inProgress = false
      this.tokensPage = {}
    })
  }

  async getProjectTokens(query = {}) {
    this.inProgress = true
    try {
      const res = await tokensApi.getProjectTokens(query)
      runInAction(() => {
        this.tokensPage = res.data
      })
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async deleteToken(tokenId) {
    this.inProgress = true
    try {
      await tokensApi.deleteToken(tokenId)
      this.rootStore.notificationStore.success({ message: 'Token deleted' })
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async createToken(tokenData) {
    this.inProgress = true
    try {
      await tokensApi.createToken(tokenData)
      this.rootStore.notificationStore.success({ message: 'Token created' })
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async updateToken(tokenData) {
    this.inProgress = true
    try {
      await tokensApi.updateToken(tokenData)
      this.rootStore.notificationStore.success({ message: 'Token updated' })
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }
}

decorate(TokensStore, {
  inProgress: observable,
  tokensPage: observable,
  updateToken: action,
  createToken: action,
  deleteToken: action,
  getProjectTokens: action,
})
