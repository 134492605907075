import React, { memo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import './formGroup.scss'

const FormGroupComponent = ({
  label, children, className, ...rest
}) => {
  const formGroupClass = classNames(className, 'form-group')

  return (
    <div {...rest} className={formGroupClass}>
      <div className="form-label">
        {label}
      </div>
      <div>
        {children}
      </div>
    </div>
  )
}

FormGroupComponent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
}

FormGroupComponent.defaultProps = {
  className: '',
}

export default memo(FormGroupComponent)
