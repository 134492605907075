import {
  decorate, observable, runInAction, action,
} from 'mobx'
import * as applicationsApi from '../api/applicationsApi'

export default class ApplicationsStore {
  constructor(rootStore) {
    this.rootStore = rootStore

    runInAction(() => {
      this.inProgress = false
      this.applicationsPage = {}
    })
  }

  clear() {
    this.applicationsPage = {}
  }

  async getApplications(query = {}) {
    this.inProgress = true
    try {
      const applicationsPage = await applicationsApi.getApplications(query)
      runInAction(() => {
        this.applicationsPage = applicationsPage
      })
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async deleteApplication(applicationsId) {
    this.inProgress = true
    try {
      await applicationsApi.deleteApplication(applicationsId)
      this.rootStore.notificationStore.success({ message: 'Application deleted' })
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async createApplication(applicationsData) {
    this.inProgress = true
    try {
      await applicationsApi.createApplication(applicationsData)
      this.rootStore.notificationStore.success({ message: 'Application created' })
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async updateApplication(applicationsData) {
    this.inProgress = true
    try {
      await applicationsApi.updateApplication(applicationsData)
      this.rootStore.notificationStore.success({ message: 'Application updated' })
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }
}

decorate(ApplicationsStore, {
  inProgress: observable,
  applicationsPage: observable,
  updateApplication: action,
  createApplication: action,
  deleteApplication: action,
  getApplications: action,
  clear: action,
})
