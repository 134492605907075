import {
  decorate,
  observable,
  runInAction,
  action,
} from 'mobx'
import * as vendorsApi from '../api/vendorsApi'

export default class VendorsStore {
  constructor(rootStore) {
    this.rootStore = rootStore

    runInAction(() => {
      this.inProgress = false
      this.vendorsPage = {}
    })
  }

  clear() {
    this.vendorsPage = {}
  }

  async getVendors(query = {}) {
    this.inProgress = true
    try {
      const vendorsPage = await vendorsApi.getVendors(query)
      runInAction(() => {
        this.vendorsPage = vendorsPage
      })
    } catch (ex) {
      this.rootStore.notificationStore.error({
        message: ex.message,
      })
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async deleteVendor(vendorId) {
    runInAction(() => {
      this.inProgress = true
    })
    try {
      await vendorsApi.deleteVendor(vendorId)
      this.rootStore.notificationStore.success({ message: 'Vendor deleted' })
    } catch (ex) {
      this.rootStore.notificationStore.error({
        message: ex.message,
      })
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async createVendor(vendorData) {
    runInAction(() => {
      this.inProgress = true
    })
    try {
      await vendorsApi.createVendor(vendorData)
      this.rootStore.notificationStore.success({ message: 'Vendor created' })
    } catch (ex) {
      this.rootStore.notificationStore.error({
        message: ex.message,
      })
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async updateVendor(vendorData) {
    runInAction(() => {
      this.inProgress = true
    })
    try {
      await vendorsApi.updateVendor(vendorData)
      this.rootStore.notificationStore.success({ message: 'Vendor updated' })
    } catch (ex) {
      this.rootStore.notificationStore.error({
        message: ex.message,
      })
    }

    runInAction(() => {
      this.inProgress = false
    })
  }
}

decorate(VendorsStore, {
  inProgress: observable,
  vendorsPage: observable,
  updateVendor: action,
  createvendor: action,
  deletevendor: action,
  getVendors: action,
  clear: action,
})
