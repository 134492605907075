import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { Helmet } from 'react-helmet'
import { constants } from '../../../store/constants'
import Modal from '../../../components/Modal/Modal'
import * as api from '../../../api/emailSubscribe'
import './UnsubscribeEmail.scss'

const UnsubscribeEmail = ({authStore, match}) => {

  const [vendorData, setVendorData] = useState(undefined)
  const [isDisplaySuccessMessage, setIsDisplaySuccessMessage] = useState(false)

  useEffect(() => {
    fetchEmailUnsubscribeData()
  }, [])

  const fetchEmailUnsubscribeData = async() => {
    const key = match.params.key
    const data = await api.getUnsubscribeDetails({key})
    if(data && data.length > 0) {
      setVendorData(data[0])
    }
  }

  const confirmUnsubscribe = () => {
    authStore.setModalState(true)
  }

  const unsubscribeClientEmail = async() => {
    const key = match.params.key
    const data = await api.unsubscribe({key})
    if(data) {
      setIsDisplaySuccessMessage(true)
    }
    authStore.setModalState(false)
  }

  const getInnerHtml = (html) => {
    return {__html: html}
  }

  const geCss = (css) => {
    if(css){
      const vv = JSON.parse(css)
      // eslint-disable-next-line no-console
      console.log(vv)
      return vv
    }
  }

  return vendorData && (
    <div className="full-size-container flex-center flex-column">
      <Helmet>
        <title>{vendorData?.vendorConfig?.companyDisplayName}</title>
      </Helmet>
      <div className="company">
        <img className="company-logo" src={vendorData?.logoImage} alt="" />
      </div>
      <div
        className="title"
        style={geCss(vendorData?.vendorConfig?.css)}
        dangerouslySetInnerHTML={getInnerHtml(vendorData?.vendorConfig?.title)}
      />
      <div>
        <a href="#" onClick={confirmUnsubscribe.bind(this)}>Click here</a>
        {' '}
to unsubscribe from our email list.
      </div>
        {
          isDisplaySuccessMessage && (
          <div className="success-message">
            {vendorData?.vendorConfig?.unsub_message}
          </div>
          )
        }

      <Modal
        show={authStore.isModalOpen}
        header="Confirm Unsubscribe"
        submitLabel="Unsubscribe"
        onSubmit={() => unsubscribeClientEmail()}
        onDismiss={() => authStore.setModalState(false)}
        isLoading={false}
        width="60%"
      >
        <h1>Are you sure you'd like to stop receiving email messages from us?</h1>
      </Modal>
    </div>
  )
}

UnsubscribeEmail.propTypes = {
  authStore: PropTypes.shape({
    isModalOpen: PropTypes.bool,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      key: PropTypes.string,
    }),
  }).isRequired,
}

export default inject(constants.authStore)(observer(UnsubscribeEmail))
