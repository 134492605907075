import _ from 'lodash'

export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

export function validate({ value, label, validators }) {
  return validators.map((validator) => validator({ value, label })).filter(Boolean)
}

export function minLength(length) {
  return function minLengthValidator({ value, label }) {
    return value.length >= length ? '' : `${_.capitalize(label)} must be at least ${length} characters long.`
  }
}

export function regex(expression) {
  return function regexValidator({ value, label }) {
    return expression.test(value) ? '' : `Invalid ${_.lowerCase(label)}`
  }
}

export function required({ value, label }) {
  return value.length ? '' : `${_.capitalize(label)} is required.`
}

export const email = regex(EMAIL_REGEX)
