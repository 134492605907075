import queryString from 'query-string'
import api from './api'

export async function getDivisions(query) {
  return api.get(`/api/v1/divisions?${queryString.stringify(query)}`)
}

export async function getDivisionById(id) {
  return api.get(`/api/v1/divisions/${id}`)
}

export async function deleteDivision(divisionId) {
  return api.delete(`/api/v1/divisions/${divisionId}`)
}

export async function createDivision(divisionData) {
  return api.post('/api/v1/divisions', divisionData)
}

export async function updateDivision(division) {
  const { id, ...divisionData } = division
  return api.patch(`/api/v1/divisions/${id}`, divisionData)
}
