import React, { memo } from 'react'
import {
  faAngleDoubleLeft, faAngleLeft, faAngleDoubleRight, faAngleRight,
} from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'
import Button from '../Button/Button'
import './paginator.scss'

const PaginatorComp = ({ page, onPageChange }) => {
  const { limit = 20, offset = 0, total = 20 } = page

  const totalPages = Math.ceil(total / limit)
  const currentPage = (offset / limit) + 1
  let pagesToDisplay

  if (totalPages > 1) {
    pagesToDisplay = [...Array(totalPages).keys()]
    if (totalPages > 7) {
      const leftBoundary = currentPage - Math.max(7 - (totalPages - currentPage), 4)
      const rightBoundary = currentPage + Math.max((7 - currentPage), 3)

      pagesToDisplay = pagesToDisplay.slice(Math.max(leftBoundary, 0), rightBoundary)
    }
  }

  const changePage = (pageNumber) => {
    onPageChange({ limit, offset: (pageNumber - 1) * limit })
  }

  return (
    <>
      {pagesToDisplay
        ? (
          <div className="paginator flex-center">
            <Button
              icon={faAngleDoubleLeft}
              disabled={currentPage === 1}
              onClick={() => changePage(1)}
            />
            <Button
              icon={faAngleLeft}
              disabled={currentPage === 1}
              onClick={() => changePage(currentPage - 1)}
            />
            {pagesToDisplay[0] !== 0 && (<span className="placeholder">...</span>)}
            {pagesToDisplay.map((n) => (
              <Button
                key={n}
                label={n + 1}
                variant={currentPage === (n + 1) ? 'primary' : 'white'}
                onClick={() => changePage(n + 1)}
              />
            ))}
            {pagesToDisplay[pagesToDisplay.length - 1] !== totalPages - 1 && (<span className="placeholder">...</span>)}
            <Button
              icon={faAngleRight}
              disabled={currentPage === totalPages}
              onClick={() => changePage(currentPage + 1)}
            />
            <Button
              icon={faAngleDoubleRight}
              disabled={currentPage === totalPages}
              onClick={() => changePage(totalPages)}
            />
          </div>
        )
        : ''}
    </>
  )
}

PaginatorComp.propTypes = {
  page: PropTypes.shape({
    limit: PropTypes.number,
    offset: PropTypes.number,
    total: PropTypes.number,
    data: PropTypes.arrayOf(PropTypes.object),
  }),
  onPageChange: PropTypes.func,
}

PaginatorComp.defaultProps = {
  page: {},
  onPageChange: null,
}

export default memo(PaginatorComp)
