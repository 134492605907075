import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import './notification.scss'

const NotificationComp = ({ notifications }) => (
  <div className="notifications-container">
    {notifications.map((notification, n) => (
      <div
        key={notification.id}
        style={{ top: `${60 * (notifications.length - 1 - n)}px` }}
        className={`notification ${notification.variant} ${notification.fade ? 'fade' : ''}`}
      >
        <div className="message">
          {notification.message}
        </div>
        <div className="notification-dismiss" onClick={notification.dismiss}>
          <FontAwesomeIcon icon={faTimes} color="white" />
        </div>
      </div>
    ))}
  </div>
)

NotificationComp.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.shape({
    variant: PropTypes.oneOf(['info', 'error', 'success']),
    message: PropTypes.string,
    id: PropTypes.number,
    fade: PropTypes.bool,
    dismiss: PropTypes.func,
  })),
}

NotificationComp.defaultProps = {
  notifications: [],
}

export default NotificationComp
