import React, { memo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import './textArea.scss'

const TextAreaComponent = ({
  label, type, name, onChange, required, disabled, value, errors, ignoreErrors, className, unit, rows, ...rest
}) => {
  const formGroupClass = classNames(className, 'form-group')

  return (
    <div className={formGroupClass}>
      {label && (
        <label className="form-label" htmlFor={name}>
          {`${label} ${required ? '*' : ''}`}
        </label>
      )}
      <div className="input">
        <textarea
          {...rest}
          className={(errors && errors.length > 0) ? 'invalid' : ''}
          value={value}
          type={type}
          name={name}
          onChange={onChange}
          required={required}
          disabled={disabled}
          rows={rows}
          style={{ minWidth: '100%' }}
        />
        {unit && <span className="unit">{unit}</span>}
      </div>
      {!ignoreErrors
        ? (
          <div className="text-small text-error errors">
            {errors && errors.map((e, idx) => <span key={idx} className="error">{e}</span>)}
          </div>
        )
        : ''}
    </div>
  )
}

TextAreaComponent.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  ignoreErrors: PropTypes.bool,
  className: PropTypes.string,
  unit: PropTypes.string,
  rows: PropTypes.string,
}

TextAreaComponent.defaultProps = {
  type: 'text',
  label: '',
  required: false,
  errors: [],
  ignoreErrors: false,
  className: '',
  unit: '',
  rows: 5,
}

export default memo(TextAreaComponent)
