import { decorate, observable, runInAction, action } from "mobx"  
import * as masterTemplateApi from "../api/masterTemplatesApi"

export default class MasterTemplatesStore {
  constructor(rootStore) {
    this.rootStore = rootStore

    runInAction(() => {
      this.inProgress = false
      this.masterTemplates = {}
      this.currentTemplateScope = {}
    })
  }

  clear() {
    this.masterTemplates = {}
  }

  setCurrentTemplate(template) {
    this.currentTemplateScope = template
  }

  async getCurrentTemplate(templateId) {
    if (this.currentTemplateScope.id !== parseInt(templateId, 10)) {
      await this.getTemplateById(templateId)
    }

    return this.currentTemplateScope
  }

  async getMasterTemplates(query = {}) {
    this.inProgress = true
    try {
      const res = await masterTemplateApi.getMasterTemplates(query)
      runInAction(() => {
        this.masterTemplates = res.data
      })
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async updateTemplate(templateData) {
    this.inProgress = true
    try {
      await masterTemplateApi.updateMasterTemplate(templateData)
      this.rootStore.notificationStore.success({ message: "Template updated" })
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async lockUnlockTemplate(templateData) {
    this.inProgress = true
    const { status } = templateData
    try {
      await masterTemplateApi.lockUnlockMasterTemplate(templateData)
      this.rootStore.notificationStore.success({
        message: `Master Template is ${status == 'LOCKED' ? "locked" : "unlocked"}`,
      })
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }
}

decorate(MasterTemplatesStore, {
  inProgress: observable,
  masterTemplates: observable,
  currentTemplateScope: observable,
  updateTemplate: action,
  createTemplate: action,
  deleteTemplate: action,
  getMasterTemplates: action,
  getCurrentTemplate: action,
  getTemplateById: action,
  setCurrentTemplate: action,
  lockUnlockTemplate: action,
  clear: action,
})
