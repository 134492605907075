import React, { useState } from 'react'
import { FileDropBox } from '../../../../components/File/File'
import Button from '../../../../components/Button/Button'
import {Buffer} from 'buffer'
window.Buffer = window.Buffer || Buffer

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 200,
  height: 200,
  padding: 4,
  boxSizing: 'border-box',
}

const img = {
  display: 'block',
  borderRadius: 8,
  maxWidth: '100%',
  height: 'auto',
}

const noteText = {
  fontSize: 14,
  color: 'orange',
}

export default function UnsubscribeAssets({ presentImage, onFile, caption }) {
  const [logoFile, setLogoFile] = useState(presentImage)
  const remove = () => {
    onFile('')
    setLogoFile('')
  }

  const uploadFile = async (file) => {
    const reader = new FileReader()
    reader.onload = async () => {
      const binaryStr = reader.result
      const base64string = await Buffer.from(binaryStr).toString('base64')
      const imageUri = `data:${file.type};base64,${base64string}`
      onFile(imageUri)
      await setLogoFile(imageUri)
    }
    reader.readAsArrayBuffer(file)
    // }
  }

  return (
    <div>
      {logoFile ? (
        <div>
          <h3>{ caption }</h3>
          <div style={thumb}>
            <img src={logoFile} alt="logoImage" style={img} />
          </div>
          <Button variant="primary" onClick={remove} label="Remove" />
        </div>
      ) : (
        <div>
          <h2>{`Upload ${caption}: (optional)`}</h2>
          <p style={noteText}><i>(Please provide square logo image for best experience)</i></p>
          <FileDropBox onDrop={uploadFile} fileSize={1048576} />
        </div>
      )}
    </div>
  )
}

UnsubscribeAssets.defaultProps = {
  presentImage: '',
  caption: '',
}
