import {
  decorate, observable, runInAction, action,
} from 'mobx'
import * as campaignsApi from '../api/campaignsApi'

export default class CampaignsStore {
  constructor(rootStore) {
    this.rootStore = rootStore

    runInAction(() => {
      this.inProgress = false
      this.campaignPage = {}
      this.currentCampaignScope = {}
    })
  }

  clear() {
    this.campaignPage = {}
  }

  setCurrentCampaign(campaign) {
    this.currentCampaignScope = campaign
  }

  async getCurrentCampaign(campaignId) {
    if (this.currentCampaignScope.id !== parseInt(campaignId, 10)) {
      await this.getCampaignById(campaignId)
    }

    return this.currentCampaignScope
  }

  async getCampaigns(query = {}) {
    this.inProgress = true
    try {
      const res = await campaignsApi.getCampaigns(query)
      runInAction(() => {
        this.campaignPage = res.data.data
      })
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  // async getCampaignById(campaignId) {
  //   this.inProgress = true
  //   try {
  //     const res = await campaignsApi.getCampaignById(campaignId)
  //     runInAction(() => {
  //       this.currentCampaignScope = res.data
  //     })
  //   } catch (ex) {
  //     this.rootStore.errorStore.handleError(ex)
  //   }

  //   runInAction(() => {
  //     this.inProgress = false
  //   })
  // }

  async deleteCampaign(campaignData) {
    this.inProgress = true
    try {
      await campaignsApi.deleteCampaign(campaignData)
      this.rootStore.notificationStore.success({ message: 'Campaign deleted' })
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async createCampaign(campaignData) {
    this.inProgress = true
    try {
      await campaignsApi.createCampaign(campaignData)
      this.rootStore.notificationStore.success({ message: 'Campaign created' })
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async updateCampaign(campaignData) {
    this.inProgress = true
    try {
      await campaignsApi.updateCampaign(campaignData)
      this.rootStore.notificationStore.success({ message: 'Campaign updated' })
    } catch (ex) {
      this.rootStore.errorStore.handleError(ex)
    }

    runInAction(() => {
      this.inProgress = false
    })
  }
}

decorate(CampaignsStore, {
  inProgress: observable,
  campaignPage: observable,
  currentCampaignScope: observable,
  updateCampaign: action,
  createCampaign: action,
  deleteCampaign: action,
  getCampaigns: action,
  getCurrentCampaign: action,
  getCampaignById: action,
  setCurrentCampaign: action,
  clear: action,
})
