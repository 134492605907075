import React from 'react'
import Select from 'react-select'
import classNames from 'classnames'
import './formInput.scss'

export const DropdownWithSearch = ({
    value,
    onChange,
    label,
    options,
    required,
    onInputChange,
    isDisabled,
    errorMessage,
    isLoading,
    className
}) => {
    const formGroupClass = classNames(className, 'form-group')

    return (
        <div className={formGroupClass}>

            {label && (
                <label className="form-label" >
                    {`${label} ${required ? '*' : ''}`}
                </label>
            )}
            <Select
                className="basic-single"
                classNamePrefix="select"
                defaultValue={options.find(option => option.value === value)}
                value={options.find(option => option.value === value)}
                isDisabled={isDisabled}
                isLoading={isLoading}
                onChange={(selectedValue) => onChange(selectedValue?.value || null)}
                isClearable={true}
                isSearchable={true}
                name={label}
                options={options}
                onInputChange={(eeve) => onInputChange(eeve)}
            />
            <div className="text-small text-error errors">
                {(required && !value) ? errorMessage : ''}
            </div>
        </div>
    )
}
