import {
  decorate, observable, action, runInAction,
} from 'mobx'

export default class NotificationStore {
  constructor() {
    runInAction(() => {
      this.notifications = []
      this.timers = {}
    })
  }

  startTimer(timestamp, duration = 5000) {
    this.timers[timestamp] = {
      fade: setTimeout(action(() => {
        this.notifications = this.notifications.map((n) => {
          if (n.id === timestamp) {
            // eslint-disable-next-line no-param-reassign
            n.fade = true
          }

          return n
        })
      }), duration - 500),
      destroy: setTimeout(action(() => {
        this.notifications = this.notifications.filter((n) => n.id !== timestamp)
      }), duration),
    }
  }

  dismissFn(timestamp) {
    return () => {
      if (this.timers[timestamp]) {
        clearTimeout(this.timers[timestamp].fade)
        clearTimeout(this.timers[timestamp].destroy)
      }

      this.startTimer(timestamp, 500)
    }
  }

  show({
    variant, message, duration, sticky,
  }) {
    const timestamp = Date.now()
    this.notifications = [
      ...this.notifications,
      {
        variant,
        message,
        id: timestamp,
        fade: false,
        dismiss: this.dismissFn(timestamp),
      },
    ]

    if (!sticky) {
      this.startTimer(timestamp, duration)
    }
  }

  success(options) {
    this.show({ variant: 'success', ...options })
  }

  error(options) {
    this.show({ variant: 'error', ...options })
  }

  info(options) {
    this.show({ variant: 'info', ...options })
  }
}

decorate(NotificationStore, {
  notifications: observable,
  timers: observable,
  show: action,
  info: action,
  error: action,
  startTimer: action,
})
