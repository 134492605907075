import React, { memo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { JsonEditor as Editor } from 'jsoneditor-react'
import Ajv from 'ajv'
import FormGroup from './FormGroup'
import 'jsoneditor-react/es/editor.min.css'
import './jsonEditor.scss'

export const jsonValidator = Ajv({ allErrors: true, coerceTypes: true, verbose: true })

const JsonEditorComponent = ({
  label, onChange, value = {}, className, ...rest
}) => {
  const ref = React.createRef()

  useEffect(() => {
    ref.current.jsonEditor.expandAll()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    ref.current.jsonEditor.update(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <FormGroup label={label} className={className}>
      <Editor
        {...rest}
        ref={ref}
        ajv={jsonValidator}
        value={value}
        onChange={onChange}
      />
    </FormGroup>
  )
}

JsonEditorComponent.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  className: PropTypes.string,
}

JsonEditorComponent.defaultProps = {
  className: '',
}

export default memo(JsonEditorComponent)
