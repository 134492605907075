import React from 'react'
import {
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import PropTypes from 'prop-types'
import { inject } from 'mobx-react'
import Users from './Users/Users'
import { constants } from '../../store/constants'
import Sidemenu from '../../components/Sidemenu/Sidemenu'
import Companies from './Companies/Companies'
import Divisions from './Divisions/Divisions'
import Applications from './Applications/Applications'
import Vendors from './Vendors/Vendors'
import Projects from './Projects/Projects'
import Roles from './Roles/Roles'
import ProjectDetails from './ProjectDetails/ProjectDetails'
import VoiceServers from './VoiceServers/VoiceServers'
import Home from './Home/Home'
import AssetDetails from './AssetDetails/AssetDetails'
import VersioningTag from '../../components/VersioningTag/VersioningTag'

const PrivateRoutes = ({ authStore }) => (
  <div className="full-size-container flex">
    <Sidemenu logoutFn={() => { authStore.logout() }} />
    <div className="flex-1 flex flex-column" style={{ minWidth: 970 }}>
      <Switch>
        <Route path="/users" component={Users} />
        <Route
          path="/companies/:companyId/divisions/:divisionId/projects/:projectId/paId/:paId/assets"
          component={AssetDetails}
        />
        <Route
          path="/companies/:companyId/divisions/:divisionId/projects/:projectId/details"
          component={ProjectDetails}
        />
        <Route path="/companies/:companyId/divisions/:divisionId/projects" component={Projects} />
        <Route path="/companies/:companyId/divisions" component={Divisions} />
        <Route path="/companies" component={Companies} />
        <Route path="/applications" component={Applications} />
        <Route path="/roles" component={Roles} />
        <Route path="/vendors" component={Vendors} />
        <Route path="/voice_servers" component={VoiceServers} />
        <Route path="/home" component={Users} />
        <Redirect from="*" to="/companies" />
      </Switch>
    </div>
    <VersioningTag />
  </div>
)

PrivateRoutes.propTypes = {
  authStore: PropTypes.shape({
    logout: PropTypes.func,
  }).isRequired,
}

export default inject(constants.authStore)(PrivateRoutes)
