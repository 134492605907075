import React, { memo } from 'react'
import PropTypes from 'prop-types'
import './panel.scss'

const PanelComp = ({
  header, children, width, height,
}) => (
  <div className="panel" style={{ width, height }}>
    <div className="panel-header">
      {header}
    </div>
    <div className="panel-body flex flex-column">
      {children}
    </div>
  </div>
)

PanelComp.propTypes = {
  header: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

PanelComp.defaultProps = {
  width: '550px',
  height: 'auto',
}

export default memo(PanelComp)
