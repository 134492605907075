import api from './api'

export async function createProjectVoice(projectVoiceData) {
  return api.post('/api/v1/project-voices', projectVoiceData)
}

export async function updateProjectVoice(projectVoice) {
  const { id, ...projectVoiceData } = projectVoice
  return api.patch(`/api/v1/project-voices/${id}`, projectVoiceData)
}

export async function replaceProjectVoices(projectVoices) {
  const { id, projectVoicesData } = projectVoices
  return api.put(`/api/v1/project-voices/projectApp/${id}`, projectVoicesData)
}

export async function findProjectVoiceById(id) {
  return api.get(`/api/v1/project-voices/projectApp/${id}`)
}
