import React, { useMemo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { inject, observer } from 'mobx-react'
import _ from 'lodash'
import ReactTooltip from 'react-tooltip'
import PageTitle from '../../../components/PageTitle/PageTitle'
import Modal from '../../../components/Modal/Modal'
import Form from '../../../components/Form/Form'
import Checkbox from '../../../components/Form/Checkbox'
import FormInput from '../../../components/Form/FormInput'
import Table from '../../../components/Table/Table'
import FormDropdown from '../../../components/Form/FormDropdown'
import FloatingButton from '../../../components/FloatingButton/FloatingButton'
import Button from '../../../components/Button/Button'
import { constants } from '../../../store/constants'
import './roles.scss'
import * as validators from '../../../validators'

const OPERATIONS = ['list', 'get', 'create', 'update', 'delete', 'download']
// const FEATURES = ['eons.template.create.customerdata', 'eons.recipientlist.create.map',
//   'eons.recipientlist.create.masterdata']
const OPS_NO_DOWNLOAD = ['list', 'get', 'create', 'update', 'delete']

const RESOURCES = [
  { name: 'applications', operations: OPS_NO_DOWNLOAD },
  { name: 'billing', operations: OPS_NO_DOWNLOAD },
  { name: 'users', operations: OPS_NO_DOWNLOAD },
  { name: 'companies', operations: OPS_NO_DOWNLOAD },
  { name: 'divisions', operations: OPS_NO_DOWNLOAD },
  { name: 'projects', operations: OPS_NO_DOWNLOAD },
  { name: 'vendors', operations: OPS_NO_DOWNLOAD },
  { name: 'tokens', operations: OPS_NO_DOWNLOAD },
  { name: 'projectVendors', operations: OPS_NO_DOWNLOAD },
  { name: 'projectApplications', operations: OPS_NO_DOWNLOAD },
  { name: 'roles', operations: OPS_NO_DOWNLOAD },
  { name: 'voiceServer', operations: OPS_NO_DOWNLOAD },
  { name: 'eons.approver', operations: OPS_NO_DOWNLOAD },
  { name: 'eons.seed', operations: OPS_NO_DOWNLOAD },
  { name: 'eons.template', operations: OPS_NO_DOWNLOAD },
  { name: 'eons.recipientlist', operations: OPERATIONS },
  { name: 'eons.report', operations: ['list', 'get', 'download'] },
  { name: 'eons.calendar', operations: ['list'] },
  { name: 'eons.map', operations: ['list', 'create', 'delete'] },
  { name: 'eons.campaign', operations: ['list', 'get'] },
  { name: 'eons.suppression', operations: ['list', 'create', 'delete'] },
  { name: 'eons.inbound', operations: ['list', 'create'] },
  { name: 'eons.keyword', operations: OPS_NO_DOWNLOAD },
  { name: 'eons.admin', operations: OPS_NO_DOWNLOAD },
]

const Roles = ({ rolesStore }) => {
  useEffect(() => {
    rolesStore.getRoles()

    return function cleanup() {
      rolesStore.clear()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const defaultStateValues = {
    createRoleModalVisible: false,
    newRole: '',
    formErrors: {},
    selectedRole: {
      id: 0,
      permissions: [],
    },
  }
  const [state, setState] = useState(defaultStateValues)

  const validate = ({ newRole }) => ({
    newRole: validators.validate({
      value: newRole,
      label: 'role',
      validators: [
        validators.minLength(4),
      ],
    }),
  })

  const handleModalInputChange = (event) => {
    const newRole = event.target.value
    setState({
      ...state,
      newRole,
      formErrors: validate({ newRole }),
    })
  }

  const handleRoleChange = (event) => {
    const role = (rolesStore.roles.filter((r) => r.id.toString() === event.target.value))[0]
    setState({
      ...state,
      selectedRole: role ? _.cloneDeep(role) : {
        id: undefined,
      },
    })
  }

  const handleChange = (permission) => (ev) => {
    const role = _.cloneDeep(state.selectedRole)

    if (ev.target.value) {
      role.permissions.push(permission)
    } else {
      role.permissions = role.permissions.filter((p) => p !== permission)
    }

    setState({ ...state, selectedRole: role })
  }

  const permissions = useMemo(() => _.flatten(
    RESOURCES.map((resource) => {
      const { name: resourceName, operations: resourceOperations } = resource
      return OPERATIONS.reduce((result, operation) => {
        if (resourceOperations.includes(operation)) {
          // eslint-disable-next-line no-param-reassign
          result[operation] = `${resourceName}.${operation}`
        }
        return result
      }, { label: _.startCase(resourceName), resourceName })
    }),
  ), [])

  const tableHeaders = useMemo(() => {
    const th = OPERATIONS.map((o) => ({
      key: o,
      label: _.capitalize(o),
      render: (item) => Object.keys(item).includes(o) && (
        <Checkbox
          removeSpacing
          name={item[o]}
          label=""
          value={state.selectedRole.permissions.indexOf(item[o]) > -1}
          onChange={handleChange(item[o])}
        />
      ),
      styles: {
        flex: 1,
      },
    }))

    th.unshift({
      label: 'Resource',
      key: 'resourceName',
      render: (item) => _.startCase(item.label),
      styles: {
        flex: 1,
      },
    })

    return th
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedRole])

  const openCreateRoleModal = () => {
    setState({
      ...state,
      createRoleModalVisible: true,
      newRole: '',
      formErrors: {},
    })
  }

  const dismissCreateRoleModal = () => {
    if (state.newRole) {
      // eslint-disable-next-line
      if (confirm('All unsaved changes will be discarded')) {
        setState({ ...state, createRoleModalVisible: false })
      }
    } else {
      setState({ ...state, createRoleModalVisible: false })
    }
  }

  const updateRole = async () => {
    await rolesStore.updateRole(state.selectedRole)
    await rolesStore.getRoles()
  }

  const submitRole = async () => {
    await rolesStore.createRole({ name: state.newRole })
    await rolesStore.getRoles()

    setState({
      ...state,
      createRoleModalVisible: false,
      newRole: '',
    })
  }

  return (
    <>
      <PageTitle title="Roles" />
      <div className="page-content roles-page">
        <Form>
          <FormDropdown
            name="role"
            label="Role"
            options={
              rolesStore.roles.length
                ? rolesStore.roles.map((role) => ({ value: role.id, label: role.name }))
                : []
            }
            value={state.selectedRole.id ? state.selectedRole.id : ''}
            onChange={handleRoleChange}
            disabled={rolesStore.inProgress}
          />
        </Form>

        {state.selectedRole.id ? (
          <>
            <Table
              hidePaginator
              headers={tableHeaders}
              page={{ data: permissions }}
            />
            {/* <h1 style={{ fontSize: 30 }}>Features</h1>
            <div style={{ marginTop: 15, marginBottom: 15 }}>
              {FEATURES.map((feature) => (
                <Checkbox
                  key={feature}
                  name={feature}
                  label={_.capitalize(feature.replace(/\./g, ' '))}
                  value={state.selectedRole.permissions.indexOf(feature) > -1}
                  onChange={handleChange(feature)}
                />
              ))}
            </div> */}
            <Button disabled={rolesStore.inProgress} label="Save changes" onClick={updateRole} variant="primary" />
          </>
        ) : ''}
      </div>
      <FloatingButton icon={faPlus} onClick={openCreateRoleModal} dataTip="Add Role" />

      <Modal
        show={state.createRoleModalVisible}
        header="Add role"
        submitLabel="Create"
        onSubmit={submitRole}
        onDismiss={dismissCreateRoleModal}
        isDisabled={Object.keys(state.formErrors).some((k) => state.formErrors[k].length)}
        width="60%"
      >
        <Form>
          <FormInput
            name="name"
            label="Name"
            value={state.newRole}
            onChange={handleModalInputChange}
            errors={state.formErrors.newRole}
            required
          />
        </Form>
      </Modal>
      <ReactTooltip />
    </>
  )
}

Roles.propTypes = {
  rolesStore: PropTypes.shape({
    roles: PropTypes.arrayOf(PropTypes.shape({})),
    inProgress: PropTypes.bool,
    getRoles: PropTypes.func,
    deleteRole: PropTypes.func,
    createRole: PropTypes.func,
    updateRole: PropTypes.func,
    clear: PropTypes.func,
  }).isRequired,
}

export default inject(constants.rolesStore)(observer(Roles))
