import {
  decorate,
  observable,
  runInAction,
  action,
} from 'mobx'
import * as voicesApi from '../api/voicesApi'

export default class VoicesStore {
  constructor(rootStore) {
    this.rootStore = rootStore

    runInAction(() => {
      this.inProgress = false
      this.voicesPage = {}
    })
  }

  clear() {
    this.voicesPage = {}
  }

  async getVoices(query = {}) {
    this.inProgress = true
    try {
      const res = await voicesApi.getVoices(query)
      runInAction(() => {
        this.voicesPage = res.data
      })
    } catch (ex) {
      this.rootStore.notificationStore.error({
        message: ex.message,
      })
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async deleteVoice({ id, address }) {
    runInAction(() => {
      this.inProgress = true
    })
    try {
      await voicesApi.deleteVoiceCache(address)
      await voicesApi.deleteVoice(id)
      this.rootStore.notificationStore.success({ message: 'Voice deleted' })
    } catch (ex) {
      this.rootStore.notificationStore.error({
        message: ex.message,
      })
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async createVoice(voiceData) {
    runInAction(() => {
      this.inProgress = true
    })
    try {
      const id = this.voicesPage.data.slice(-1)[0].id + 1 // Create Voice Service does not return new Id

      await voicesApi.createVoice(voiceData)
      await voicesApi.createVoiceCache({ ...voiceData, id })
      this.rootStore.notificationStore.success({ message: 'Voice created' })
    } catch (ex) {
      this.rootStore.notificationStore.error({
        message: ex.message,
      })
    }

    runInAction(() => {
      this.inProgress = false
    })
  }

  async updateVoice(voiceData) {
    runInAction(() => {
      this.inProgress = true
    })
    try {
      await voicesApi.updateVoiceCache(voiceData)
      await voicesApi.updateVoice(voiceData)
      this.rootStore.notificationStore.success({ message: 'Voice updated' })
    } catch (ex) {
      this.rootStore.notificationStore.error({
        message: ex.message,
      })
    }

    runInAction(() => {
      this.inProgress = false
    })
  }
}

decorate(VoicesStore, {
  inProgress: observable,
  voicesPage: observable,
  updateVoice: action,
  createvoice: action,
  deletevoice: action,
  getVoices: action,
  clear: action,
})
