import queryString from 'query-string'
import api from './api'

/**
 * Send GET request to voice asset server to get the list of files
 * under the specified directory
 * @param { batchId, dse} param0
 */
export const getLegacyAssets = async ({ batchId, dse }) => api.get(
  `/api/v1/voice_service/assets/legacy/asset/${batchId}/${dse}`,
)

/**
 * Send a POST request to voice asset server to upload a file
 * @param {batchId, dse, di, file} param0
 */
export const createLegacyAsset = async ({
  batchId, dse, di, file,
}) => {
  const formData = new FormData()
  formData.append('wav', file, file.name)
  const reqConfig = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  }
  return api.post(`/api/v1/voice_service/assets/legacy/asset/${batchId}/${dse}/${di}`, formData, reqConfig)
}

/**
 * Send a GET request to voice asset server to processe the uploaded files
 * @param {batchId, dse} param0
 */
export const uploadAssets = async ({ batchId, dse }) => api.get(
  `/api/v1/voice_service/assets/legacy/upload/${batchId}/${dse}`,
)

/**
 * Send a DELETE requet to voice asset server to delete file (processed and non processed)
 * @param {batchId, dse, di} param0
 */
export const deleteAsset = async ({ batchId, dse, di }) => api.delete(
  `/api/v1/voice_service/assets/legacy/asset/${batchId}/${dse}/${di}`,
)

/**
 * Send a request to Voice service to generate a new batchID
 */
export const generateBatchId = async ({ companyId, description }) => api.post(
  'api/v1/voice_service/callcontrol/legacy/batchId', { companyId, description },
)

/**
 * Send a POST request to voice asset server to upload a file
 * @param {batchId, dse, di, file} param0
 */
export const createAsset = async ({ query, file }) => {
  const formData = new FormData()
  formData.append('wav', file, file.name)
  const reqConfig = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  }
  return api.post(`/api/v1/voice_service/assets?${queryString.stringify(query)}`, formData, reqConfig)
}

/**
 * Send GET request to voice asset server to get the list of files
 * under the specified directory
 * @param { companyId, campaignId} query
 */
export const getAssets = async ({ companyId, campaignId }) => api.get(
  `/api/v1/voice_service/assets/${companyId}/${campaignId}`,
)

/**
 * Send a DELETE requet to voice asset server to delete file (processed and non processed)
 * @param {companyId, campaignId, filename} query
 */
export const deleteFreeSwitchAsset = async ({ query }) => api.delete(
  `/api/v1/voice_service/assets?${queryString.stringify(query)}`,
)
/**
 * Send a GET request to voice asset server to processe the uploaded files
 * @param { companyId, campaignId} query
 */
export const uploadFreeSwitchAssets = async ({ companyId, campaignId }) => api.get(
  `/api/v1/voice_service/assets/upload/${companyId}/${campaignId}`,
)

export default api
