import queryString from 'query-string'
import api from './api'

export async function getTemplates(query) {
  return api.get(`/api/v1/templates?${queryString.stringify(query)}`)
}

// export async function createTemplate(TemplateData) {
//   return api.post('/api/v1/Template', TemplateData)
// }

export async function updateTemplate(Template) {
  const { _id } = Template
  return api.patch(`/api/v1/templates/${_id}`, Template)
}

export async function lockUnlockTemplate(Template) {
  const { id } = Template
  return api.patch(`/api/v1/templates/lockUnlock/${id}`, Template)
}

// export async function deleteTemplate(Template) {
//   const { _id } = Template
//   return api.delete(`/api/v1/Template/${_id}`, Template)
// }
