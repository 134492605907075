import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { inject, observer } from 'mobx-react'
import { constants } from '../../../store/constants'
import Form from '../../../components/Form/Form'
import FormInput from '../../../components/Form/FormInput'
import Panel from '../../../components/Panel/Panel'
import Button from '../../../components/Button/Button'

const PasswordReset = ({ authStore, location, history }) => {
  const defaultStateValues = {
    password: '',
    passwordConfirm: '',
    id: null,
    token: null,
    formErrors: {
      disableOnLoad: [1],
    },
  }

  const [state, setState] = useState(defaultStateValues)

  useEffect(() => {
    async function checkToken() {
      const params = queryString.parse(location.search)
      const { token } = params

      if (!token) {
        history.push('/login')
      } else {
        try {
          const { data } = await authStore.validateToken(token)
          setState({ ...state, id: data.id, token })
        } catch (ex) {
          history.push('/login')
        }
      }
    }
    checkToken()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  // Minimum 10 characters, at least one uppercase letter, one lowercase letter, one number and one special character
  const passwordRegex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/)

  const validatePassword = (password) => {
    const errors = []

    if (!password.length) {
      errors.push('Password is required')
    } else if (!passwordRegex.test(password)) {
      // eslint-disable-next-line max-len
      errors.push('Min. 10 characters, one uppercase and lowercase letter, a number, and a special character is required')
    }

    return errors
  }

  const validatePasswordConfirm = (password, passwordConfirm) => {
    const errors = []

    if (passwordConfirm !== password) {
      errors.push('Passwords must match')
    }

    return errors
  }

  const validate = ({ password, passwordConfirm }) => ({
    password: validatePassword(password),
    passwordConfirm: validatePasswordConfirm(password, passwordConfirm),
  })

  const handleChange = (event) => {
    const { name, value } = event.target
    const updatedState = {
      ...state,
      [name]: value,
    }
    updatedState.formErrors = validate({
      password: updatedState.password,
      passwordConfirm: updatedState.passwordConfirm,
    })
    setState(updatedState)
  }

  const onSubmit = async () => {
    const { password, passwordConfirm } = state
    if (password !== passwordConfirm) {
      setState({ ...state, notMatchingPasswords: true })
    } else {
      try {
        await authStore.resetPassword(password, state.token, state.id)
      } catch (err) {
        setState({ ...state, isDisabled: false })
      }
    }
  }

  return (
    <div className="full-size-container flex-center flex-column">
      <Panel header="Change your password">
        <Form>
          <FormInput
            name="password"
            type="password"
            label="New password"
            onChange={handleChange}
            errors={state.formErrors.password}
            value={state.password}
          />
          <FormInput
            name="passwordConfirm"
            type="password"
            label="Confirm your new password"
            onChange={handleChange}
            errors={state.formErrors.passwordConfirm}
            value={state.passwordConfirm}
          />
          <Button
            label="Reset"
            onClick={onSubmit}
            variant="primary"
            classNames={['full-width']}
            disabled={Object.keys(state.formErrors).some((k) => state.formErrors[k].length)}
            isLoading={authStore.inProgress}
          />
        </Form>
      </Panel>
      <img style={{ width: '300px', marginTop: '2rem' }} alt="logo" src="/assets/logo.png" />
    </div>
  )
}

PasswordReset.propTypes = {
  authStore: PropTypes.shape({
    inProgress: PropTypes.bool,
    resetPassword: PropTypes.func,
    validateToken: PropTypes.func,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.func,
  }).isRequired,
}

export default inject(constants.authStore)(observer(PasswordReset))
